import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { PolizaService } from 'src/app/services/poliza.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'polizas-intermediario-app',
  templateUrl: 'polizas.component.html',
  styleUrls: ['polizas.component.scss'],
})
export class PolizasComponent implements OnInit {
  polizas: any[];
  pendientes = 0;
  aprobadas = 0;
  rechazadas = 0;
  mora = 0;
  datosCargados:boolean;
  cuotasPagas = 0;

  a = {
    a: false
  }
  notificaciones: any = []
  hayNotificacionesSinLeer
  modalNotificacion

  constructor(
    private mainService: MainService,
    private authService: AuthService,
    private polizaService: PolizaService,
    private router: Router
  ) {}
  seleccion:boolean;

  settings = {
    pager: {
      display: true,
      perPage: 10,
    },
    noDataMessage: 'No hay pólizas',
    hideSubHeader: false,
    actions: {
      columnWidth: '20px',
      columnTitle: 'Acciones',
      add: false,
      filter: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'Ver',
          title:
            '<i class="fa fa-pencil" style="text-align: center; font-size: 19px;" title="Ver detalle"></i>',
        },
        {
          name: 'Copy',
          title: '<i class="fa fa-clone" style="text-align: center; font-size: 16px;" title="Duplicar póliza"></i>'
        }
      ],
      position: 'left',
    },
    class: 'celda',
    columns: {
      numeroPagare: {
        title: 'Número de pagaré',
        editable: true,
        filter: true,
      },
      fechaFinalizacion: {
        title: 'Fecha de finalización',
        editable: true,
        filter: true,
      },
      numeroPoliza: {
        title: 'Número de póliza',
        editable: true,
        filter: true,
      },
      estado: {
        title: 'Estado',
        editable: true,
        filter: true,
      },
      nombreAseguradora: {
        title: 'Nombre de la aseguradora',
        editable: true,
        filter: true,
      },
      nombreCliente: {
        title: 'Nombre del cliente',
        editable: true,
        filter: true,
      },
      apellidoCliente: {
        title: 'Apellido del cliente',
        editable: true,
        filter: true,
      },
      fechaCreacion: {
        title: 'Fecha de creación',
        editable: true,
        filter: true,
      },
      // valorPago: {
      //   title: 'Valor de pago',
      //   editable: true,
      //   filter: true,
      // },
      numeroCuotas: {
        title: 'Número de cuotas',
        editable: true,
        filter: true,
      },
    },
  };

  polizasActivas: any[] = [];
  polizasHistorial: any[] = [];

  cambiarOpcion() {
    this.seleccion = !this.seleccion;
  }

  ngOnInit() {
    const idUsuarioActual = this.authService.obtenerUsuarioActual().id
    this.mainService
      .get(`poliza/intermediario/${idUsuarioActual}`)
      .subscribe((polizasIntermediario: any) => {
        this.polizas = polizasIntermediario;
        this.cargarDatos();
        this.seleccion= true
        this.obtenerEstadisticas();
      });

    this.cargarNotificaciones()
  }

  cargarDatos() {
    this.polizas.forEach((poliza: any) => {
      if(poliza.planDePagos.length >0 ){
        let entrada = {
          fechaFinalizacion: moment(
            poliza.planDePagos[poliza.planDePagos.length - 1].fecha
          ).format('DD/MM/YYYY'),
          id: poliza._id,
          numeroPagare:poliza.numeroPagare,
          numeroPoliza: poliza.numeroPoliza,
          nombreAsegura: poliza.empresaAseguradora,
          nombreCliente: poliza.usuario?.nombre,
          apellidoCliente: poliza.usuario?.apellido,
          fechaCreacion: moment(poliza.createdAt).format('DD/MM/YYYY'),
          // valorPago: Math.round(this.calcularPago(poliza)),
          numeroCuotas: poliza.numeroCuotas,
          nombreAseguradora: poliza.empresaAseguradora,
          estado: poliza.estado,
          fechaLimite: poliza.fechaFinVigencia
        };

        if (poliza.estado === 'Vencida') {
          entrada.fechaFinalizacion = moment(poliza.fechaFinVigencia).format('DD/MM/YYYY')
        }

        if (
          poliza.planDePagos[poliza.planDePagos.length - 1].pagado == 'si'
          || poliza.estado == 'Vencida'
          || poliza.estado == 'Cancelación voluntaria'
          || poliza.estado == 'Revocado por mora'
        ) {
          entrada.estado = this.polizaService.calcularEstado(poliza)
          this.polizasHistorial.push(entrada);
        } else {
          entrada.estado = this.polizaService.calcularEstado(poliza)
          this.polizasActivas.push(entrada);
        }
      }

    });
    this.datosCargados = true;
  }

  verDetallePoliza(evento) {
    if (evento.action === 'Copy' && (
      evento.data.estado === 'Financiado'
      || evento.data.estado === 'Vencida'
      || evento.data.estado === 'Al día'
      || evento.data.estado === 'Revocado por mora'
      || evento.data.estado === 'Cancelación voluntaria del cliente'
      || evento.data.estado === 'Paz y Salvo'
      || evento.data.estado === 'En mora'
    )) {
      return this.duplicatePoliza(evento.data.id)
    } else if(evento.action === 'Copy') {
      return Swal.fire({
        title: '¡Error!',
        text: `No puedes copiar una póliza en estado: ${evento.data.estado}.`,
        icon: 'error',
      })
    }

    if (evento.action == 'Ver' && evento.data.estado !== 'Pendiente Usuario') {
      return Swal.fire({
        title: '¡Error!',
        text: `No puedes editar una póliza en estado: ${evento.data.estado}.`,
        icon: 'error',
      })
    }else if (!moment(evento.data.fechaLimite).isAfter(moment()) ){
      return Swal.fire({
        title: '¡Error!',
        text: `No editar un póliza después de su fecha límite.`,
        icon: 'error',
      })
    }else{
      this.router.navigate(['intermediario', 'poliza', `${evento.data.id}`]);
    }

  }

  duplicatePoliza(idPolizaBase){
    this.mainService.get(`poliza/${idPolizaBase}`).subscribe(poliza => {
      this.polizaService.savePolizaDuplicate(poliza)
      this.router.navigate(['crear-poliza']);
    })

  }

  // calcularPago(poliza) {
  //   let monto = 0;
  //   if (poliza.fechaPagoDeCuotaInicial) {
  //     monto += poliza.valorSeguro;
  //     poliza.planDePagos.forEach((cuota) => {
  //       if (cuota.pagado == 'si') {
  //         monto += cuota.abonoCapital;
  //       }
  //     });
  //   }
  //   return monto;
  // }

  obtenerEstadisticas() {
    this.polizas.forEach((poliza) => {
      if (this.estaPendiente(poliza)) {
        this.pendientes++;
      }
      if (this.estaAprobada(poliza)) {
        this.aprobadas++;
      }
      if (this.estaRechazada(poliza)) {
        this.rechazadas++;
      }
      if (this.estaEnMora(poliza)) {
        this.mora++;
        console.log('Entre a aumentar mora');
      }

      this.contarCuotasPagas(poliza);
    });
  }

  contarCuotasPagas(poliza) {
    poliza.planDePagos.forEach((cuota) => {
      if (cuota.pagado == 'si') {
        this.cuotasPagas++;
      }
    });
  }

  estaEnMora(poliza) {
    let estaEnMora = false;
    let fechaActual = moment();
    let fechaPoliza = moment(poliza.createdAt);

    if (poliza.estado != 'Vencida') {
      if (
        fechaActual.diff(fechaPoliza, 'days') > 5 &&
        !poliza.fechaPagoDeCuotaInicial
      ) {
        return true;
      } else {
        let testigo = false;
        poliza.planDePagos.forEach((cuota) => {
          let fechaCuota = moment(cuota.fecha);
          if (
            fechaCuota.diff(fechaActual, 'days') < 0 &&
            cuota.pagado == 'no'
          ) {
            testigo = true;
          }
        });

        if (testigo) {
          return testigo;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  estaRechazada(poliza) {
    return (
      poliza.estado.toLowerCase().includes('rechazado') ||
      poliza.estado.toLowerCase().includes('rechazada')
    );
  }

  estaAprobada(poliza) {
    return (
      poliza.estado.toLowerCase().includes('aprobado') ||
      poliza.estado.toLowerCase().includes('financiado')
    );
  }


  estaPendiente(poliza) {
    return (
      poliza.estado.toLowerCase().includes('pendiente') ||
      poliza.estado.toLowerCase().includes('revisión')
    );
  }

  cargarNotificaciones() {
    const idUsuarioActual = this.authService.obtenerUsuarioActual().id

    this.mainService
      .get(`notificationByIntermediario/${idUsuarioActual}`)
      .subscribe(notificaciones => {
        this.notificaciones = notificaciones
        this.hayNuevasNotificaciones()
      })
  }

  hayNuevasNotificaciones() {
    this.notificaciones.some(notification => {
      if (notification.leida) return false
      this.hayNotificacionesSinLeer = true
      return true
    })
  }

  openNotifications() {
    if(this.notificaciones.length === 0) return Swal.fire("¡Error!", 'No tienes notificaciones', "error");
    this.modalNotificacion = true
  }

  closeNotifications() {
    if (!this.modalNotificacion) return
    this.modalNotificacion = false
  }


  marcarLeidaNotificacion(idNotification: string){
    this.mainService
      .put(`readNotification/${idNotification}`, {})
      .then(() => this.cargarNotificaciones())
  }

  marcarNotificaciones() {
    const idUsuarioActual = this.authService.obtenerUsuarioActual().id

    this.mainService
      .put(`readNotifications/${idUsuarioActual}`, {})
      .then(() => this.cargarNotificaciones())
  }
}
