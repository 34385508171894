import { Injectable } from '@angular/core';
import { tokenNotExpired } from 'angular2-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import {Usuario} from "../interfaces/usuario";
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  agregados = new BehaviorSubject([]);

  nombre: any = [];
  apellido: any = [];
  direccion: any = [];
  ciudad: any = [];

  public userTypes = {
    intermediario: 'Intermediario',
    user: 'Usuario',
  };

  private fuenteNombre = new BehaviorSubject(this.nombre);
  private fuenteApellido = new BehaviorSubject(this.apellido);

  tipoUsuario: any = [];

  private fuenteTipoUsuario = new BehaviorSubject(this.tipoUsuario);

  tipoUsuarioAct = this.fuenteTipoUsuario.asObservable();
  nombreAct = this.fuenteNombre.asObservable();
  apellidoAct = this.fuenteApellido.asObservable();

  cammbiarTipoUsuario(tipo: string) {
    this.fuenteTipoUsuario.next(tipo);
  }

  cambiarNombreAct(nombre: String) {
    this.fuenteNombre.next(nombre);
  }

  cambiarApellidoAct(apellido: String) {
    this.fuenteApellido.next(apellido);
  }

  public authToken: any;
  public user: any = undefined;
  public settings = {
    loggIn: false,
    userType: '',
  };
  public headers: HttpHeaders;
  // public baseUrl: String = 'https://finsu.herokuapp.com/';
  // public baseUrl: String = 'https://finsu.imagineapps.co/';
  public baseUrl: String = 'https://finsu.imagineapps.co/';

  constructor(public http: HttpClient, private router: Router) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || undefined;
    if (this.user) {
      this.settings.userType = this.user.tipo.toLowerCase();
      this.settings.loggIn = true;
    }
  }
  /**
   * API Generico GET
   * @param {string} api route: api/componente
   * @return {Observable<any>} respuesta asincrónica
   */
  delete({ api }: { api: String }) {
    let newHeaders = this.setHeaders();
    return this.http.delete(`${this.baseUrl}${api}`, { headers: newHeaders });
  }

  get({ api }: { api: String }) {
    let newHeaders = this.setHeaders();
    return this.http.get(`${this.baseUrl}${api}`, { headers: newHeaders });
  }

  post({ api }: { api: String }) {
    let newHeaders = this.setHeaders();

    return this.http.post(`${this.baseUrl}${api}`, { headers: newHeaders });
  }

  public authenticateUser(user) {
    try {
      let headers = new HttpHeaders().set('Content-Type', 'application/json');
      this.settings.loggIn = true;
      return this.http.post(this.baseUrl + 'users/authenticate', user, {
        headers: headers,
      });
    } catch (err) {}
  }

  public register(user) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.baseUrl + 'users/register', user, {
      headers: headers,
    });
  }

  public forgot(email) {
    const obj = { email };
    return this.http.post(this.baseUrl + 'users/forgot', JSON.stringify(obj), {
      headers: this.headers,
    });
  }

  /**
   * Hace una petición al back para cambiar la contraseña del usuario actual
   * @param email email del usuario actual
   * @param contrasena nueva contrasena del  usuario
   * @returns  un observable
   */
  public cambiarContrasena(email, contrasena) {
    const datos = { email, password: contrasena };
    return this.http.post(this.baseUrl + 'users/reset', JSON.stringify(datos), {
      headers: this.headers,
    });
  }

  /**
   * Hace una petición al back para saber si la contraseña ingresada es la actual del usuario
   * @param email correo del usuario que se utilizara para buscarlo
   * @param contrasena contrasena actual del usuario
   * @returns respuesta del backend
   */
  public verificarContraseña(email, contrasena) {
    const datos = { email, password: contrasena };
    return this.http.post(
      this.baseUrl + 'users/checkpassword',
      JSON.stringify(datos),
      {
        headers: this.headers,
      }
    );
  }

  /**
   * Obtiene el usuario que se encuentra loggeado actualmente
   * @returns El objeto del usuario actual
   */
  public obtenerUsuarioActual() {
    this.settings.userType = this.user.tipo.toLowerCase();
    return this.user;
  }

  public reset(email, newPassword) {
    let newHeaders = this.setHeaders();
    const obj = { email: email, password: newPassword };
    return this.http.post(this.baseUrl + 'users/reset', JSON.stringify(obj), {
      headers: newHeaders,
    });
  }
  public change(email, password, newPassword) {
    let newHeaders = this.setHeaders();
    const obj = {
      email: email,
      password: password,
      passwordNueva: newPassword,
    };
    return this.http.post(
      this.baseUrl + 'users/change_pass',
      JSON.stringify(obj),
      {
        headers: newHeaders,
      }
    );
  }

  public storeUserData(token, user) {
    localStorage.setItem('id_token', token);
    localStorage.setItem('usuario', JSON.stringify(user));
    this.authToken = token;
    this.user = user;
    this.cambiarNombreAct(user.nombre);
    this.cambiarApellidoAct(user.apellido);
    this.cammbiarTipoUsuario(user.tipo.toLowerCase());
    let item = [{ login: true }];
    this.setAgregados(item);
    return true;
  }

  public loadToken() {
    const token = localStorage.getItem('id_token');
    this.authToken = token;
  }

  public logout() {
    this.authToken = null;
    this.user = {};
    this.settings.loggIn = false;

    localStorage.removeItem('usuario');
    localStorage.removeItem('id_token');
    localStorage.clear();
    let item = [];
    this.setAgregados(item);
  }
  public getToken() {
    this.loadToken();
    return this.authToken;
  }
  public loggedIn() {
    return tokenNotExpired('id_token');
  }
  public setAgregados(cur: any[]) {
    this.agregados.next(cur);
    this.loggedIn();
  }
  /**
   * Se crean los headers con el token de el auth service
   */
  public setHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.getToken(),
    });
  }

  public verifyIfUserLogged() {
    const currentUser = JSON.parse(localStorage.getItem('usuario'));
    if (currentUser) return;
    this.router.navigate(['iniciar-sesion']);
  }
}
