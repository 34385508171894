<div class="fondo">
    <div class="container">
        <div class="padding-simulador">
            <p class="text-center title" routerLink="/simulador"><em class="fa fa-angle-left"></em> &nbsp;Documentación</p>
            <div class="row">
                <div class="tituloPagareC">
                    <strong class="titProFin">Proceso de financiamiento</strong> <br> Para iniciar el proceso de financiamiento debes firmar un pagaré en blanco para que FINSU se encargue de conseguir un inversionista.
                </div>
                <div class="contenedor-docs">
                    <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-6">
                            <h5> Firma del pagaré en blanco </h5>
                            <p>
                                <strong>Debemos confirmar tu cédula y número celular para firmar el documento. </strong> Puedes ver el documento a firmar en este link: <a href="https://www.finsu.co/assets/docs/PagareFinsu.pdf" target="_blank" rel="noopener">Pagaré en Blanco</a>
                            </p>
                            <p *ngIf="validacionCedulaResultado">
                                <i style="color: green; margin-right: 10px;" class="fa fa-check" aria-hidden="true"></i> Identidad verificada con número de cédula
                            </p>
                            <!-- PASO 1: BLOQUE DE VERIFICACIÓN DE CÉDULA -->
                            <div *ngIf="paso == 1 && preguntasRecorrer.length === 0">
                                <div class="form-block">
                                    <label for="">Confirma tu número de cédula</label>
                                    <input type="text" [(ngModel)]="user.numeroDocumento" class="inputFinsu selectPorcentaje" placeholder="Ej: 12345678">
                                </div>
                                <p *ngIf="otpEnviada && !validacionCedulaResultado">
                                    <i style="color: red; margin-right: 10px;" class="fa fa-times" aria-hidden="true"></i> La cédula no es válida{{validacionCedulaMensaje}}
                                </p>
                                <!--Boton para activar validación de documento-->
                                <!-- <button *ngIf="!validacionCedulaResultado" (click)="verificarUsuario()"
                                    class="botonEnviarPagare">Verificar Identidad</button> -->

                                <!--Boton para activar validación por preguntas-->
                                <!-- <button *ngIf="!validacionCedulaResultado" (click)="psdValidarIdentidad()"
                                  class="botonEnviarPagare">Verificar Identidad</button> -->

                            </div>
                            <div *ngIf="paso == 1 && preguntasRecorrer.length > 0">
                                <div *ngIf="preguntas.success">
                                    <div *ngFor="let p of preguntasRecorrer; let i = index">
                                        <hr>
                                        <label>{{p["b:TextoPregunta"]}}</label>
                                        <div>
                                            <!-- <p>{{r["b:TextoRespuesta"]}}</p> -->
                                            <div *ngFor="let r of p['b:OpcionesRespuesta']['b:OpcionRespuesta']; let j = index">
                                                <input id="{{p['b:IdPregunta']}}" type="radio" value="{{r['b:IdRespuesta']}}" name="{{p['b:IdPregunta']}}" (change)="changeRadio(r['b:IdRespuesta'], i, j)">
                                                <label style="margin-left: 10px;" for="{{p['b:IdPregunta']}}">{{r["b:TextoRespuesta"]}}</label> <br>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="text-center">
                                    <!-- <button (click)="chequearRespuestas()" class="botonAcerptarTerminos">Enviar respuestas</button> -->
                                    <!-- <button class="btn" (click)="enviarValidacion()"> Enviar</button> -->
                                </div>
                            </div>
                            <!-- FIN BLOQUE DE VERIFICACIÓN DE CÉDULA -->
                            <!-- PASO 2: BLOQUE DE ENVIAR MENSAJE DE TEXTO -->
                            <div *ngIf="paso == 2 || paso == 3" class="margin-bottom-20">
                                <div class="form-block">
                                    <label for="">Ingresa tu celular sin espacios. Ej: 3121112233</label>
                                    <input type="text" [(ngModel)]="celularVerificacion" class="inputFinsu selectPorcentaje" placeholder="Ej: 3121112233">
                                </div>
                                <button (click)="enviarOtp()" class="botonEnviarPagare">Enviar Código de Verificación</button>
                            </div>
                            <!-- FIN DE BLOQUE DE ENVIAR MENSAJE DE TEXTO -->
                            <!-- PASO 3: BLOQUE DE ESCRIBIR OTP -->
                            <div *ngIf="paso == 3">
                                <div class="form-block">
                                    <label for="">Ingresa el código que enviamos a tu celular y email</label>
                                    <input type="text" [(ngModel)]="codigoOtp" class="inputFinsu selectPorcentaje" placeholder="Ej: 1234">
                                </div>
                                <button (click)="firmarDocumento()" class="botonEnviarPagare">Firmar Pagaré</button>
                            </div>
                            <div *ngIf="paso == 4">
                                <div class="wrapper">
                                    <h1 class="titProceso">Póliza</h1>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h5>Carga tu póliza
                                                <i *ngIf="cargadoPoliza" style="color: green" class="fa fa-check" aria-hidden="true"></i>
                                                <i *ngIf="!cargadoPoliza" style="color: red" class="fa fa-times" aria-hidden="true"></i>
                                            </h5>
                                            <div class="form-group">
                                                <label for="">Carga el documento aquí:</label>
                                                <input type="file" ng2FileSelect [uploader]="uploaderPoliza" accept="image/*;capture=camera" (change)="uploadPoliza()">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="cargadoPoliza">
                                        <div class="col-sm-12">
                                            <button (click)="onGoToPago()" class="botonEnviarPagare">Continuar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- FIN DE BLOQUE DE ESCRIBIR OTP -->
                            <!-- <button *ngIf="otpEnviada && !pagareFirmado" (click)="onEnviarCorreo()" class="botonEnviarPagare">Enviar OTP de nuevo</button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- <button class="btn btn-cotizar" (click)='siguiente()'>Continuar</button> -->

            </div>
        </div>

    </div>
</div>
