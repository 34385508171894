import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paso-a-paso',
  templateUrl: './paso-a-paso.component.html',
  styleUrls: ['./paso-a-paso.component.scss']
})
export class PasoAPasoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
