<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Cargando ...</p>
</ngx-spinner>

<div *ngIf="detalle !== true">
  <p class="titulo cursor" (click)="ondetalle()">
    <i class="fa fa-chevron-left"></i> Mi Financiación
  </p>
  <p class="titulo-proceso">Proceso</p>
  <app-detalle-financiaciones
    [polizaSeleccionada]="polizaSeleccionada"
  ></app-detalle-financiaciones>
  <button
    *ngIf="polizaSeleccionada.estado !== 'Cuota inicial pago rechazado'"
    class="btn btn-cotizar"
    (click)="openModal(content)"
  >
    Pagar cuota
  </button>
  <!-- *ngIf="totalPagos - totalPagadoFull > 0" -->
</div>

<ng-template #content let-modal>
  <div class="modal-body">
    <ngx-loading
      [show]="loading"
      [config]="{ backdropBorderRadius: '3px' }"
      [template]="customLoadingTemplate"
    >
    </ngx-loading>
    <div class="row" style="padding: 25px; text-align: center">
      <div class="col-sm-12">
        <h1 class="popSemibold size20">
          Selecciona un número de cuotas a pagar
        </h1>
        <select
          name=""
          id=""
          class="form-control"
          style="width: 50%; margin: 0 auto; display: block"
          (change)="cambiarCuotas($event)"
          [(ngModel)]="cuotasPagar"
        >
          <option value="">Selecciona un valor</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3"></div>
      <div
        class="col-sm-6"
        style="text-align: center; border-bottom: 1px solid black"
      >
        Valor a pagar: <br />
        {{ valorPagar | currency : "COP" : "symbol" : "1.0-0" }}
      </div>
    </div>
    <div *ngIf="!agregarMetodo && !pse">
      <div class="row" style="text-align: center">
        <div class="col-sm-12">
          <h1 class="popSemibold size20">Métodos de pago</h1>
          <div class="row">
            <div class="col-sm-12">
              <button id="botonPagar" (click)="pse = true" class="btnFinsuBlue">
                Pagar cuota con PSE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      *ngIf="agregarMetodo || pse"
      style="margin-top: 20px; margin-bottom: -10px"
    >
      <div class="col-sm-12">
        <h1 class="popSemibold size20" style="text-align: center" *ngIf="!pse">
          Agrega un método de pago
        </h1>
        <h1 class="popSemibold size20" style="text-align: center" *ngIf="pse">
          Pagar con PSE
        </h1>
      </div>
    </div>
    <div id="grid1-modal" class="celda" *ngIf="pse">
      <app-pago-pse
        [poliza]="polizaNew"
        [cuotasPagar]="cuotasPagar"
        [saldo]="saldo"
        [valorPagar]="valorPagar"
        [user]="user"
        (volver)="volverPse($event)"
      ></app-pago-pse>
    </div>
    <div id="grid1-modal" class="celda" *ngIf="agregarMetodo">
      <div id="grid2-modal" class="celda">
        <div class="divi gcol3">
          <label for="" class="labelFinsu">Nombre completo</label>
          <input
            placeholder="Nombre completo"
            type="text"
            [(ngModel)]="usuario.name"
            class="inputModal inputFinsu"
          />
        </div>
        <div class="divi gcol3">
          <label for="" class="labelFinsu">Email</label>
          <input
            placeholder="Email"
            type="text"
            [(ngModel)]="usuario.email"
            class="inputModal inputFinsu"
          />
        </div>
      </div>
      <hr class="lineModal" />
      <div id="grid3-modal" class="celda">
        <div class="labelFacturacion divi popRegular size16">
          Información de la tarjeta
        </div>
        <div class="divi"></div>
        <div class="divi">
          <label for="" class="labelFinsu">Número de la tarjeta</label>
          <input
            placeholder="Número de la tarjeta"
            type="text"
            [(ngModel)]="card.number"
            class="inputModal inputFinsu"
          />
        </div>
        <div class="divi"></div>
        <div class="divi">
          <div class="row">
            <div class="col-6">
              <label for="" class="labelFinsu">Mes vencimiento</label>
              <div class="selectContainer relative selectPorcentaje">
                <mat-icon class="selectIcon">keyboard_arrow_down</mat-icon>
                <select
                  class="inputFinsu inputModal"
                  [(ngModel)]="card.exp_month"
                >
                  <option value="">Selecciona</option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <label for="" class="labelFinsu">Año vencimiento</label>
              <div class="selectContainer relative selectPorcentaje">
                <mat-icon class="selectIcon">keyboard_arrow_down</mat-icon>
                <select
                  class="inputFinsu inputModal"
                  [(ngModel)]="card.exp_year"
                >
                  <option value="">Selecciona</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="divi">
          <label for="" class="labelFinsu">CVV</label>
          <input
            placeholder="CVV"
            type="text"
            [(ngModel)]="card.cvc"
            class="inputModal inputFinsu"
          />
        </div>
      </div>
      <hr class="lineModal" />
      <div id="grid4-modal" class="celda">
        <div class="labelFacturacion divi popRegular size16">
          Información de facturación
        </div>
        <div class="labelFacturacion divi popRegular size16">Dirección</div>
        <div class="divi">
          <input
            placeholder="Dirección"
            type="text"
            [(ngModel)]="usuario.address"
            class="inputModal inputFinsu"
          />
        </div>
        <div class="divi">
          <input
            placeholder="Ciudad"
            type="text"
            [(ngModel)]="usuario.city"
            class="inputModal inputFinsu"
          />
        </div>
        <div class="divi gcol2">
          <button
            id="botonPagar"
            (click)="onGuardarYPagar()"
            class="btnFinsuBlue"
          >
            Guardar tarjeta y pagar
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button
          id="botonPagar"
          (click)="modal.dismiss('Cross click'); pse = false"
          class="btnFinsuBlack"
        >
          Cerrar
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #psecuotaInicial let-modal>
  <div class="text-right" style="padding: 10px">
    <span
      style="padding: 10px; font-size: 20px; font-weight: bold; cursor: pointer"
      (click)="modal.dismiss('Cross click')"
      >X</span
    >
  </div>
  <div class="text-center">
    <h3>Pago con PSE</h3>
  </div>
  <div>
    <app-pago-pse [poliza]="poliza" [user]="user"></app-pago-pse>
  </div>
</ng-template>

<div *ngIf="detalle !== false">
  <p class="titulo cursor">Mis Financiaciones</p>
</div>

<div class="row" [hidden]="polizas.length !== 0">
  <div class="col-4 logo-finsu">
    <img
      src="assets/recursos/logos-iconos/logo-finsu.png"
      class="logo"
      alt="Logo Finsu"
    />
    <p>No se ha encontrado ningun financiamiento.</p>
  </div>
</div>

<!-- Seccion Mis financiaciones, menu donde muestra todas polizas -->
<div class="row" *ngIf="detalle !== false" [hidden]="polizas.length === 0">
  <div
    class="col-md-4 responsive-card"
    *ngFor="let pol of polizas; let i = index"
  >
    <div class="card-blanca">
      <div
        class="card-header"
        *ngIf="
          pol.estado === 'Vencida' ||
          pol.estado === 'Cancelación voluntaria' ||
          pol.estado === 'Revocado por mora'
        "
      >
        <p class="text-gris text-center">{{ pol.estado }}</p>
      </div>

      <div
        class="card-azul"
        *ngIf="
          pol.estado !== 'Vencida' &&
          pol.estado != 'Cancelación voluntaria' &&
          pol.estado != 'Revocado por mora'
        "
      >
        <p class="text-gris text-center">{{ pol.estado }}</p>
      </div>

      <div class="card-body text-center">
        <p class="monto-gris text-center">{{ pol.numeroPoliza }}</p>
        <p class="text-gris text-center">
          {{ pol.createdAt | date : "shortDate" }}
        </p>
        <button
          class="btn btn-cotizar alt-body-btn text-center"
          (click)="ondetalle(i)"
          *ngIf="
            pol.estado !== 'Vencida' &&
            pol.estado !== 'Cancelación voluntaria' &&
            pol.estado !== 'Revocado por mora' &&
            pol.estado !== 'Pendiente' &&
            pol.estado !== 'Pendiente Usuario' &&
            pol.estado !== 'Cuota inicial pago rechazado'
          "
        >
          Ver más
        </button>
        <button
          class="btn btn-pago alt-body-btn text-center"
          (click)="goToInitialPayment(pol)"
          *ngIf="pol.estado === 'Cuota inicial pago rechazado'"
        >
          Pagar cuota inicial
        </button>
        <button
          class="btn btn-pago alt-body-btn text-center"
          (click)="goToSignPagare(pol)"
          *ngIf="pol.estado === 'Pendiente Usuario'"
        >
          Firmar Pagaré
        </button>
        <button
          class="btn btn-eliminar alt-body-btn text-center"
          (click)="eliminar(pol['_id'], i)"
          *ngIf="!pol.cuotaInicialPagada && pol.estado === 'Vencida'"
        >
          Eliminar
        </button>
      </div>
    </div>
  </div>
</div>
