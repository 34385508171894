import {
  Component,
  OnInit,
  Input,
  Injectable,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import { PolizaService } from '../../../services/poliza.service';
@Component({
  selector: 'app-plan-de-pagos',
  templateUrl: './plan-de-pagos.component.html',
  styleUrls: ['./plan-de-pagos.component.scss'],
})
export class PlanDePagosComponent implements OnInit {
  @Input() porcentajeCuotaInicial: number;
  @Input() numeroCuotas: number;
  @Input() valorTotal: number;
  @Input() metodovolver: boolean;

  // @Input() tasaMensual: number;
  tasaMensual: number;
  tasaMensualPorcentaje: string;
  @Output() public planDePagos = new EventEmitter<any>();
  public config: any = {};
  public simulacion: any = [];
  plan: any = [];
  fecha: any;

  constructor(private polizaService: PolizaService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.numeroCuotas) {
      this.numeroCuotas = parseInt(changes.numeroCuotas.currentValue);
      this.generarPlanDePagos();
    }
    if (changes.porcentajeCuotaInicial) {
      this.porcentajeCuotaInicial = parseFloat(
        changes.porcentajeCuotaInicial.currentValue
      );
      this.generarPlanDePagos();
    }
    if (changes.valorTotal) {
      this.valorTotal = parseInt(changes.valorTotal.currentValue);
      this.generarPlanDePagos();
    }
  }

  async ngOnInit(): Promise<void> {
    this.tasaMensual = await this.polizaService.getTasaMensual();
    this.tasaMensualPorcentaje = (this.tasaMensual * 100).toFixed(2);

    this.generarPlanDePagos();
    this.polizaService.setPlanPagos(this.plan);
    this.polizaService.savePolizaLocal();
  }

  generarPlanDePagos() {
    this.simulacion = [];
    for (let i = 1; i < this.numeroCuotas + 1; i++) {
      let x;
      if (i == 1) {
        x = {
          fecha: moment().add(i, 'M').format('YYYY-MM-DD'),
          saldoCapital: this.valorTotal * (1 - this.porcentajeCuotaInicial),
          valor: this.PMT(
            this.tasaMensual, //, //* (1 + this.config.iva),,
            this.numeroCuotas,
            this.valorTotal * (1 - this.porcentajeCuotaInicial) * -1,
            0,
            0
          ),
          abonoCapital:
            this.PMT(
              this.tasaMensual, //, //* (1 + this.config.iva),,
              this.numeroCuotas,
              this.valorTotal * (1 - this.porcentajeCuotaInicial) * -1,
              0,
              0
            ) -
            this.valorTotal *
              (1 - this.porcentajeCuotaInicial) *
              this.tasaMensual, //, //* (1 + this.config.iva),,
          intereses:
            this.valorTotal *
            (1 - this.porcentajeCuotaInicial) *
            this.tasaMensual,
          /* IVA:
                this.valorTotal *
                (1 - this.porcentajeCuotaInicial) *
                this.tasaMensual * this.config.iva, */
        };
      } else {
        x = {
          fecha: moment().add(i, 'M').format('YYYY-MM-DD'),
          saldoCapital:
            this.simulacion[i - 2].saldoCapital -
            this.simulacion[i - 2].abonoCapital,
          valor: this.PMT(
            this.tasaMensual, //, //* (1 + this.config.iva),,
            this.numeroCuotas,
            this.valorTotal * (1 - this.porcentajeCuotaInicial) * -1,
            0,
            0
          ),
          abonoCapital:
            this.PMT(
              this.tasaMensual, //, //* (1 + this.config.iva),,
              this.numeroCuotas,
              this.valorTotal * (1 - this.porcentajeCuotaInicial) * -1,
              0,
              0
            ) -
            (this.simulacion[i - 2].saldoCapital -
              this.simulacion[i - 2].abonoCapital) *
              this.tasaMensual, //*(1 + this.config.iva),
          intereses:
            (this.simulacion[i - 2].saldoCapital -
              this.simulacion[i - 2].abonoCapital) *
            this.tasaMensual,
          /* IVA:
                (this.simulacion[i - 2].saldoCapital -
                  this.simulacion[i - 2].abonoCapital) *
                this.tasaMensual, // *    this.config.iva, */
        };
      }
      this.simulacion.push(x);
      this.plan = this.simulacion;
      // this.enviarPlan();
    }
  }

  public PMT(ir, np, pv, fv, type) {
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0) return -(pv + fv) / np;

    pvif = Math.pow(1 + ir, np);
    pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

    if (type === 1) pmt /= 1 + ir;

    return pmt;
  }
}
