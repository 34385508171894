import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs-compat/Observable';
import { Subject } from 'rxjs/internal/Subject';
@Component({
  selector: 'app-simulador',
  templateUrl: './simulador.component.html',
  styleUrls: ['./simulador.component.scss'],
})
export class SimuladorComponent implements OnInit {
  /**Variable para almacenar incio de vigencia */
  fechaInicioVigencia: NgbDateStruct;
  /**Variable para almacenar cuotas sin beneficiario */
  public cuotasSinBeneficiario: number = 0;
  /**Variable para almacenar cuotas con beneficiario */
  public cuotasConBeneficiario: number = 0;
  /**Variable para almacenar porcentaje de cuota inicial */
  porcentajeCuotaInicialParam: number = 0.15;
  /**VARIABLE PARA ALMACENAR VALOR DEL SEGURO */
  insuranceValue: number = 2_000_000;
  /** Máximo valor permitido del seguro */
  maxValueAllowed: number = 20_000_000;
  /** Mínimo valor permitido del seguro */
  minValueAllowed: number = 500_000;
  /**vARIABLE PARA ALMACENAR BENEFICIARIO ONOROSO */
  beneficiarioOneroso: any = '';
  /**Variable para almacenar número de cuotas */
  numeroCuotasParam: number = 9;
  /** Contenedor de cambios reactivos */
  insuranceChanged = new Subject<number>();
  constructor() {}

  ngOnInit(): void {
    this.fechaInicioVigencia = {
      year: parseInt(moment().format('YYYY')),
      month: parseInt(moment().format('MM')),
      day: parseInt(moment().format('DD')),
    };
    this.insuranceChanged
      .pipe(debounceTime(2000))
      .subscribe(() => this.checkValueAllowed());
  }

  /**
   * Método para verificar fecha y formato de fecha
   */
  onBlurMethod() {
    if (this.fechaInicioVigencia !== undefined) {
      let fecha =
        this.fechaInicioVigencia.year +
        '/' +
        this.fechaInicioVigencia.month +
        '/' +
        this.fechaInicioVigencia.day;
      fecha = moment(fecha).format('YYYY/MM/DD');
      if (!moment(fecha).isValid()) {
        this.fechaInicioVigencia = undefined;
        Swal.fire(
          'Formato de fecha no valida, por favor indique un formato válido para poder continuar.'
        );
      }
    }
  }

  checkValueAllowed() {
    if (this.insuranceValue > this.maxValueAllowed) {
      this.insuranceValue = this.maxValueAllowed;
    } else if (this.insuranceValue < this.minValueAllowed) {
      this.insuranceValue = this.minValueAllowed;
    }
  }

  /**
   * Método para indicar los porcentajes de cuotas incial permitidas
   */
  public cuotaInicialPermitidas() {
    let cuotasIniciales = [];
    if (this.fechaInicioVigencia) {
      let fecha = '';
      if (typeof this.fechaInicioVigencia == 'object') {
        fecha =
          this.fechaInicioVigencia.year +
          '/' +
          this.fechaInicioVigencia.month +
          '/' +
          this.fechaInicioVigencia.day;
      } else {
        fecha = this.fechaInicioVigencia;
      }
      fecha = moment(fecha).format('YYYY/MM/DD');
      let diasDiferenciaVigencia = moment(fecha).diff(moment(), 'days');
      // Diferencia en días desde el inicio de la vigencia y el día actual. Negativo son días en el pasado         "YYYY-MM-DD"

      if (diasDiferenciaVigencia <= -91) {
      } else if (diasDiferenciaVigencia <= -61) {
        cuotasIniciales = [0.4];
        this.cuotasSinBeneficiario = 7;
        this.cuotasConBeneficiario = 6;
      } else if (
        diasDiferenciaVigencia <= -30 &&
        diasDiferenciaVigencia >= -60
      ) {
        cuotasIniciales = [0.3, 0.4];
        this.cuotasSinBeneficiario = 7;
        this.cuotasConBeneficiario = 6;
      } else if (
        diasDiferenciaVigencia <= -16 &&
        diasDiferenciaVigencia > -30
      ) {
        cuotasIniciales = [0.3, 0.4];
        this.cuotasSinBeneficiario = 8;
        this.cuotasConBeneficiario = 7;
      } else if (diasDiferenciaVigencia <= -1 && diasDiferenciaVigencia > -15) {
        cuotasIniciales = [0.2, 0.3, 0.4];
        this.cuotasSinBeneficiario = 9;
        this.cuotasConBeneficiario = 8;
      } else {
        cuotasIniciales = [0.15, 0.2, 0.3, 0.4];
        this.cuotasSinBeneficiario = 9;
        this.cuotasConBeneficiario = 8;
      }
      return cuotasIniciales;
    } else {
      return [''];
    }
  }

  public limpiarPlan() {
    this.insuranceValue = null;
    this.porcentajeCuotaInicialParam = null;
    this.numeroCuotasParam = null;
    this.beneficiarioOneroso = null;
  }
  /**
   * Método para indicar el número de cuotas permitidas
   */
  public cuotasPermitidas() {
    let cuotas = [];
    if (this.insuranceValue && this.insuranceValue > 0) {
      if (this.insuranceValue >= 500000 && this.insuranceValue < 600000) {
        cuotas = [5];
      } else if (
        this.insuranceValue >= 600000 &&
        this.insuranceValue < 700000
      ) {
        cuotas = [5, 6];
      } else if (
        this.insuranceValue >= 700000 &&
        this.insuranceValue < 800000
      ) {
        cuotas = [5, 6, 7];
      } else if (
        this.insuranceValue >= 800000 &&
        this.insuranceValue < 900000
      ) {
        cuotas = [5, 6, 7, 8];
      } else if (
        this.insuranceValue >= 900000 &&
        this.insuranceValue < 1000000
      ) {
        cuotas = [5, 6, 7, 8, 9];
      } else {
        cuotas = [5, 6, 7, 8, 9];
      }
      let i = cuotas.length;
      while (i--) {
        if (
          this.beneficiarioOneroso &&
          cuotas[i] > this.cuotasConBeneficiario
        ) {
          cuotas.splice(i, 1);
        }
        if (
          !this.beneficiarioOneroso &&
          cuotas[i] > this.cuotasSinBeneficiario
        ) {
          cuotas.splice(i, 1);
        }
      }
      return cuotas;
    } else {
      return [''];
    }
  }

  /**
   * Método para imprimir plan de pagos
   * @param X
   */
  imprSelec(X) {
    var ficha = document.getElementById(X);
    window.document.write(ficha.innerHTML);
    window.print();
  }
}
