import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';

import { UtilidadesService } from '../../../services/utilidades.service';
import { AuthService } from '../../../services/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cambiar-contrasena',
  templateUrl: './cambiar-contrasena.component.html',
  styleUrls: ['./cambiar-contrasena.component.scss']
})
export class CambiarContrasenaComponent implements OnInit, AfterViewInit {
  registerForm: FormGroup;
  user

  userEmail: string = ''

  public muestra: "password" | "text" = "password"
  public muestraCo: "password" | "text" = "password"

  constructor(
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private customValidator: UtilidadesService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.registerForm = this.fb.group(
      {
        password: [
          '',
          Validators.compose([
            Validators.required,
            this.customValidator.patternValidator(),
          ]),
        ],
        coPassword: ['', Validators.compose([Validators.required])],
      }
    );

    this.user = this.authService.user
  }

  ngAfterViewInit(): void {
    if (!this.user.email) this.router.navigate(['home'])
  }

  get registerFormControl() {
    return this.registerForm.controls;
  }

  onSubmit() {
    if (!this.registerForm.valid) return;
    if (this.registerForm.value.password !== this.registerForm.value.coPassword) return

    let obj = this;
    this.spinner.show();
    this.authService.cambiarContrasena(this.user.email, this.registerForm.value.password)
      .subscribe((data: any) => {
        this.spinner.hide();

        if (!data.success) {
          return Swal.fire(
            '¡Error!',
          `${data.msg}.`,
            'warning'
          );
        }

        this.user.passwordTemp = false
        localStorage.setItem("usuario", JSON.stringify(this.user));

        if (this.user.tipo == 'Inversionista' || this.user.tipo == 'Usuario') {
          Swal.fire('¡Éxito!', 'Se ha iniciado sesión con éxito.', 'success');
          obj.router.navigate(['usuario']);
        } else if (this.user.tipo == 'Intermediario') {
          Swal.fire('¡Éxito!', 'Se ha iniciado sesión con éxito.', 'success');
          obj.router.navigate(['intermediario']);
        } else {
          Swal.fire(
            '¡Error!',
            'El usuario no es de tipo comprador ni inversionista.',
            'warning'
          );
        }
      },(err) => {
        Swal.fire(
          '¡Error!',
          'No se ha podido cambiar la contraseña.',
          'warning'
        );
      })
  }

  togglePass(whichPass = "muestra") {
    if (this[whichPass] === "password") return this[whichPass] = "text"
    this[whichPass] = "password"
  }
}
