import { Component } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppToastService } from 'src/app/services/toast.service';
@Component({
  selector: 'app-pqr',
  templateUrl: './pqr.component.html',
  styleUrls: ['./pqr.component.scss'],
})
export class PqrComponent {
  motivo: string = 'Selecciona';
  mensaje: string = null;
  constructor(
    private mainService: MainService,
    private authService: AuthService,
    private toastService: AppToastService
  ) {}

  /**
   * Maneja el envio del formulario. Muestra el toast correspondiente a éxito o error
   * verifica si el formulario es valido y crea uno nuevo cuando la solicitud es enviada
   */
  async manejarEnvio() {
    let userID = this.authService.obtenerUsuarioActual().id;
    if (this.esValido()) {
      let respuesta = await this.mainService.post('quejas', {
        motivo: this.motivo,
        mensaje: this.mensaje,
        usuario: userID,
      });
      if (respuesta) {
        this.toastService.mostrar('Éxito', 'Te contactaremos pronto.', 'exito');
        this.nuevoFormulario();
      }
    } else {
      this.toastService.mostrar(
        'Cuidado',
        'Hay campos vacios o inválidos.',
        'advertencia'
      );
    }
  }

  /**
   * Crea un nuevo formulario
   */
  nuevoFormulario() {
    this.motivo = 'Selecciona';
    this.mensaje = null;
  }

  /**
   * Valida el formulario
   * @returns un booleano indicando si el formulario es valido o no
   */
  esValido() {
    let valido = true;
    if (this.motivo === 'Selecciona') {
      valido = false;
    }
    if (!this.mensaje || /^\s*$/.test(this.mensaje)) {
      valido = false;
    }

    return valido;
  }
}
