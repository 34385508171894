import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor() {}
  mostrarModal = 'none';
  ngOnInit(): void {}

  abrirModal() {
    this.mostrarModal = 'flex';
  }

  copiarCorreo(correo) {
    navigator.clipboard.writeText(correo);
  }

  cerrarModal() {
    this.mostrarModal = 'none';
  }
}
