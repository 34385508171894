import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  settingsAuth = this.authService.settings;
  usuarioActivo: any;
  public isMenuCollapsed = true;
  /**Variable para almacenar el menu activo */
  menu: string = 'resumen';
  constructor(
    private menuService: MenuService,
    private authService: AuthService
  ) {}

  rutaActual: string;

  rutaActiva() {
    return '/home';
  }

  ngOnInit(): void {
    this.authService.tipoUsuarioAct.subscribe((res) => {
      this.rutaActual = res;
    });

    this.usuarioActivo = this.authService.obtenerUsuarioActual();
    // this.settingsAuth.loggIn = this.authService.settings.loggIn;
    // this.isLogged = this.authService.loggedIn();
    // this.authService.agregados.subscribe((updatedTitle) => {
    //   if (updatedTitle.length > 0) {
    //     this.isLogged = true;
    //   } else {
    //     this.isLogged = false;
    //   }
    // });
  }

  manejarCierreSesion() {
    this.isMenuCollapsed = true;
    this.authService.logout();
  }
  /**
   * Método para seleccionar ménu en el panel
   * @param {string} menu
   */
  seleccionarMenu(menu) {
    this.menuService.setMenu(menu);
    this.menu = menu;
    localStorage.setItem('menu', menu);
  }
}
