<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Cargando ...</p>
</ngx-spinner>
<div class="row margin-row div-no-responsive">
  <div class="col-md-12">
    <div class="connected-steps pad-0">
      <div
        *ngFor="let pago of polizaSeleccionada.planDePagos; let i = index"
        hidden
      >
        <div
          [ngClass]="{
            step: pagado == 'si',
            'step-gray': pagado == 'no'
          }"
        >
          <i class="fa fa-check"></i>
        </div>

        <div
          [ngClass]="{
            connector: pagado == 'si',
            'connector-gray': pagado == 'no'
          }"
        ></div>
      </div>
      <div class="stepsContainer">
        <ol class="ProgressBar">
          <li
            [ngClass]="{
              'ProgressBar-step': pago.pagado == 'si',
              'ProgressBar-step-last': pago.pagado == 'no'
            }"
            *ngFor="let pago of polizaSeleccionada.planDePagos"
          >
            <i class="fa fa-check check" *ngIf="pago.pagado == 'si'"></i>
            <i class="fa fa-trash no-check" *ngIf="pago.pagado == 'no'"></i>
            <span class="ProgressBar-stepLabel"
              >{{ pago.fecha | date : "MMM d, yyyy" : "es" }}<br />
              <div class="precio">
                <b>{{
                  pago.valor + pago.valorMora
                    | currency : "COP " : "symbol" : "1.0-0"
                }}</b>
              </div>
            </span>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="row margin-row div-responsive">
  <div
    class="col-md-12 div-monto"
    *ngFor="let cuota of polizaSeleccionada.planDePagos; let i = index"
  >
    <div class="row h-100">
      <div class="col-3 my-auto">
        <div
          [ngClass]="{
            'step-gray': cuota.pagado === 'si',
            step: cuota.pagado === 'no'
          }"
        >
          <i class="fa fa-check"></i>
        </div>
      </div>
      <div class="col-9 my-auto">
        <p class="text-gris">{{ cuota.fecha | date : "MMM d, yyyy" : "es" }}</p>
        <p class="text-gris">Pago número: {{ i + 1 }}</p>
        <p class="text-monto">
          {{
            cuota.valor + cuota.valorMora
              | currency : "COP " : "symbol" : "1.0-0"
          }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="row margin-row top-2rem">
  <div class="col-12 col-md-12 col-lg-8">
    <div class="row card-blanca">
      <div class="columns">
        <div class="column">
          <div>
            <p class="text-gris">Saldo a capital pendiente por pagar</p>
            <p class="monto-gris">
              <ng-container
                *ngIf="
                  polizaSeleccionada.estado !==
                    'Cuota inicial pago rechazado' &&
                    polizaSeleccionada.estado !== 'Pendiente';
                  else cuotaInicialTemplate
                "
              >
                {{ monto_por_pagar | currency : "COP" : "symbol" : "1.0-0" }}
              </ng-container>
              <ng-template #cuotaInicialTemplate>
                {{
                  polizaSeleccionada.valorSeguro
                    | currency : "COP" : "symbol" : "1.0-0"
                }}
              </ng-template>
            </p>
          </div>
          <div>
            <p class="text-gris">Fecha límite de pagos</p>
            <p class="text-gris">
              {{
                polizaSeleccionada.planDePagos[pos_cuotas_pagas + 1].fecha
                  | date
              }}
            </p>
          </div>
        </div>
        <div class="column">
          <div>
            <p class="text-gris">Placas</p>
            <p class="text-gris">
              {{ polizaSeleccionada.placas?.join(", ") || "No definido" }}
            </p>
          </div>
          <div>
            <p class="text-gris">Número pagaré</p>
            <p class="text-gris">
              {{ polizaSeleccionada.numeroPagare || "No definido" }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12">
        <p class="text-gris">Siguiente cuota</p>
        <div class="row card-carne">
          <div class="col-12 col-md-6">
            <p>Fecha límite de pago</p>
            <p>
              {{
                polizaSeleccionada.planDePagos[pos_cuotas_pagas + 1].fecha
                  | date
              }}
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p class="monto-negro">
              {{
                polizaSeleccionada.planDePagos[pos_cuotas_pagas + 1].valor +
                  polizaSeleccionada.planDePagos[pos_cuotas_pagas + 1].valorMora
                  | currency : "COP" : "symbol" : "1.0-0"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12">
      <button
        class="btn btn-cotizar alt-body-btn"
        *ngIf="
          polizaSeleccionada.estado === 'Al dia' ||
          polizaSeleccionada.estado === 'Mora' ||
          polizaSeleccionada.estado === 'Financiado As400'
        "
        (click)="openModal(content)"
      >
        Pagar cuota
      </button>
    </div>
    <div class="col-4"></div>
  </div>
</div>
