import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aliados',
  templateUrl: './aliados.component.html',
  styleUrls: ['./aliados.component.scss']
})
export class AliadosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
