<p class="titulo">Mis datos</p>
<div class="row margin-row">
    <div class="col-12 col-md-12 col-lg-8">
        <div class="row">
            <div class="col-md-6  margin-col">
                <p>Nombre</p>
                <p>{{ user.nombre }}</p>
            </div>
            <div class="col-md-6  margin-col">
                <p>Apellido</p>
                <p>{{ user.apellido }}</p>
            </div>
            <br>
            <div class="col-md-10  margin-col">
                <p>Correo electrónico</p>
                <p>{{ user.email }}</p>
            </div>
            <div class="col-md-6  margin-col">
                <p>Celular</p>
                <div>
                    <input [(ngModel)]="user.telefono" type="tel" placeholder="Escribe aquí" class="input" />
                </div>
            </div>
            <div class="col-md-6  margin-col">
                <p>Dirección</p>
                <p>{{ user.direccion }}</p>
            </div>
            <div class="col-md-6  margin-col">
                <p>Ciudad</p>
                <p>{{ user.ciudad }}</p>
            </div>
            <div class="col-md-6  margin-col">
                <p>Tipo de persona</p>
                <p>{{ user.tipoPersona }}</p>
            </div>
            <div class="col-md-6  margin-col">
                <p>Tipo de documento</p>
                <p>{{ user.tipoDocumento }}</p>
            </div>
            <div class="col-md-6  margin-col">
                <p>Número de documento</p>
                <p>{{ user.numeroDocumento }}</p>
            </div>
            <div class="col-md-6 margin-col">
                <p class="cambiar" (click)="openVerticallyCentered(content)">
                    Cambiar contraseña
                </p>
                <button (click)="manejarCambiosFormulario()" class="btn btn-actualizar">
                  Actualizar datos
              </button>
            </div>
        </div>
    </div>
    <div class="col-4"></div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Cambiar contraseña</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;
                <label class="cerrar">Cerrar</label>
        </span>
    </button>
    </div>
    <div class="modal-body">
        <div class="margin-col">
            <p>Contraseña anterior</p>
            <div>
                <img (click)="cambiarEstadoNueva()" class="icon" src="assets/recursos/logos-iconos/ver.png" alt="Icono ver, para hacer visible contraseña nueva" />
                <input [(ngModel)]="contrasenaAntigua" class="input" [attr.type]="tipoNueva" name="tipod" placeholder="Escribe aquí" />
            </div>
        </div>
        <div class="margin-col">
            <p>Contraseña nueva</p>
            <div>
                <img (click)="cambiarEstadoConfirmacion()" class="icon2" src="assets/recursos/logos-iconos/ver.png" alt="Icono ver, para hacer visible la confirmacion de contraseña nueva" />
                <input [(ngModel)]="contrasenaNueva" class="input" [attr.type]="tipoConfirmacion" name="tipod" placeholder="Escribe aquí" />
            </div>
        </div>
        <div class="margin-col">
            <button (click)="manejarCambioContrasena()" class="btn btn-actualizar">
                Cambiar
            </button>
        </div>
        <p [style.display]="hayError" class="error">
            La contraseña {{ contrasenaError }} no es alfanumérica o no tiene 8 o más caracteres
        </p>
        <p [style.display]="esIncorrecta" class="error">
            La contraseña anterior es incorrecta
        </p>
    </div>
</ng-template>
