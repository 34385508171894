<div class="wrapper-center">
  <div class="preguntas">
    <div class="preguntas-title">
      <h1>Preguntas frecuentes</h1>
    </div>

    <div class="preguntas-lista">
      <div class="lista-indice">
        <ol class="indice-pregunta" start="1">
          <li><strong> ¿Quién es FINSU?</strong></li>
        </ol>
        <p class="indice-respuesta">
          FINSU financia tu seguro, es una plataforma digital para la
          financiación de pólizas revocables por medio de un proceso fácil y
          rápido. Cuenta con el respaldo y experiencia de más de 30 años de
          SERVIEFECTIVO S.A.S en la gestión de cartera.
        </p>
      </div>
      <div class="lista-indice">
        <ol class="indice-pregunta" start="2">
          <li>
            <strong> ¿Con qué aseguradoras trabajan? </strong>
          </li>
        </ol>
        <p class="indice-respuesta">
          Actualmente tenemos convenio con dos (2) aseguradoras: <br />
          <strong> Seguros Mundial:</strong> A partir del 01 de marzo de 2021
          <br />
          <strong> Berkley Seguros:</strong> A partir del 27 de mayo de 2021
          <br />
        </p>
      </div>
      <div class="lista-indice">
        <ol class="indice-pregunta" start="3">
          <li>
            <strong> ¿Qué ramos puedo financiar a través de Finsu? </strong>
          </li>
        </ol>
        <p class="indice-respuesta">UNICAMENTE ramos revocables.</p>
      </div>
      <div class="lista-indice">
        <ol class="indice-pregunta" start="4">
          <li><strong> ¿Qué montos puedo financiar? </strong></li>
        </ol>
        <p class="indice-respuesta">
          Financiamos pólizas cuyo valor total (incluido IVA) sea mínimo de $500.000 y máximo de $20.000.000.
        </p>
      </div>
      <div class="lista-indice">
        <ol class="indice-pregunta" start="5">
          <li><strong> ¿Qué documentos me piden para financiar? </strong></li>
        </ol>
        <p class="indice-respuesta">
          La caratula legible de la póliza a financiar.
        </p>
      </div>

      <div class="lista-indice">
        <ol class="indice-pregunta" start="6">
          <li>
            <strong>
              ¿En un solo pagare puedo financiar varias pólizas?
            </strong>
          </li>
        </ol>
        <p class="indice-respuesta">
          Únicamente si son pólizas colectivas. Para el caso de pólizas individuales es condicional que todas las
          pólizas deben tener el mismo tomador y/o oneroso y la misma fecha de inicio de vigencia (sujeto a aprobación).
        </p>
      </div>
      <div class="lista-indice">
        <ol class="indice-pregunta" start="7">
          <li>
            <strong>
              ¿Cuántos días de vigencia máximo puede tener mi póliza para ser
              financiada?
            </strong>
          </li>
        </ol>
        <p class="indice-respuesta">Máximo 90 días de vigencia</p>
      </div>
      <div class="lista-indice">
        <ol class="indice-pregunta" start="8">
          <li>
            <strong>
              ¿Qué medios de pago existen para el pago de mis cuotas?
            </strong>
          </li>
        </ol>
        <ul class="indice-respuesta">
          <li>PSE desde el usuario registrado.</li>
          <li>Corresponsales del Banco Av Villas bajo el numero e convenio 14702 - y como referencia Nº de pagaré
            (Efecty)</li>
        </ul>
      </div>
      <div class="lista-indice">
        <ol class="indice-pregunta" start="9">
          <li><strong> ¿Cómo puedo consultar mi plan de pagos? </strong></li>
        </ol>
        <p class="indice-respuesta">
          Ingresando a la plataforma FINSU con tu usuario y contraseña, por el menú FINANCIACIONES puedes consultar tu
          plan de pagos y cancelar mes a mes tus cuotas o solicitándolo al correo mundial-finsu1@serviefectivo.com.co
        </p>
      </div>
      <div class="lista-indice">
        <ol class="indice-pregunta" start="10">
          <li><strong> ¿Como me registro en PSE? </strong></li>
        </ol>
        <p class="indice-respuesta">
          <a href="https://www.youtube.com/watch?v=rrZMTE3P344">https://www.youtube.com/watch?v=rrZMTE3P344</a>
        </p>
      </div>

      <div class="lista-indice">
        <ol class="indice-pregunta" start="11">
          <li><strong>¿Como actualizar mis datos de contacto?</strong></li>
        </ol>
        <p class="indice-respuesta">
          Escríbenos a:
          <a href="mailto:mundial-finsu1@serviefectivo.com.co">mundial-finsu1@serviefectivo.com.co</a>,
          <a href="mailto:Viviana.ramirez@serviefectivo.com.co">Viviana.ramirez@serviefectivo.com.co</a> o a <a
            href="mailto:comercial@finsu.co">comercial@finsu.co</a>
        </p>
      </div>
    </div>
  </div>
</div>