<div class="row margin-row">
  <div class="col-3 d-none d-md-none d-lg-block fondo-menu">
    <div class="iniciales">
      <p class="letras">{{ nombre[0] }} {{ apellido[0] }}</p>
    </div>
    <div class="nombre">{{ nombre }} {{ apellido }}</div>
    <div class="padding-div">
      <div
        class="text-no-activo"
        (click)="seleccionarMenu('polizas')"
        [ngClass]="{ 'text-activo': menu === 'polizas' }"
      >
        <span>Pólizas</span>
        <img
          class="flecha"
          [hidden]="menu !== 'polizas'"
          src="assets/recursos/logos-iconos/right-arrow (1).png"
          alt="Imagen flecha"
        />
      </div>
      <div
        class="text-no-activo"
        (click)="seleccionarMenu('cartera')"
        [ngClass]="{ 'text-activo': menu === 'cartera' }"
      >
        Cartera
        <img
          class="flecha"
          [hidden]="menu !== 'cartera'"
          src="assets/recursos/logos-iconos/right-arrow (1).png"
          alt="Imagen flecha"
        />
      </div>
      <div
        class="text-no-activo"
        (click)="seleccionarMenu('clientes')"
        [ngClass]="{ 'text-activo': menu === 'clientes' }"
      >
        Clientes
        <img
          class="flecha"
          [hidden]="menu !== 'clientes'"
          src="assets/recursos/logos-iconos/right-arrow (1).png"
          alt="Imagen flecha"
        />
      </div>
      <div
        class="text-no-activo"
        (click)="seleccionarMenu('perfil')"
        [ngClass]="{ 'text-activo': menu === 'perfil' }"
      >
        Perfil
        <img
          class="flecha"
          [hidden]="menu !== 'perfil'"
          src="assets/recursos/logos-iconos/right-arrow (1).png"
          alt="Imagen flecha"
        />
      </div>

      <div class="text-no-activo" (click)="cerrar()">Cerrar sesión</div>
    </div>
  </div>

  <div class="col-12 col-md-12 col-lg-9 padding-div2">
    <div *ngIf="menu === 'polizas'">
      <polizas-intermediario-app></polizas-intermediario-app>
    </div>
    <div *ngIf="menu === 'cartera'">
      <app-cartera></app-cartera>
    </div>
    <div *ngIf="menu === 'clientes'">
      <app-clientes-intermediario></app-clientes-intermediario>
    </div>
    <div *ngIf="menu === 'perfil'">
      <app-perfil-intermediario></app-perfil-intermediario>
    </div>
  </div>
</div>
