import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-intermediario',
  templateUrl: './intermediario.component.html',
  styleUrls: ['./intermediario.component.scss'],
})
export class IntermediarioComponent implements OnInit {
  menu: string = 'polizas';

  public user: any;

  nombre: String;
  apellido: String;

  constructor(
    private menuService: MenuService,
    private authService: AuthService,
    private router: Router,
    private mainService: MainService
  ) {}

  ngOnInit(): void {
    this.authService.verifyIfUserLogged();

    this.authService.nombreAct.subscribe((nombre) => {
      this.nombre = nombre;
    });

    this.authService.apellidoAct.subscribe((apellido) => {
      this.apellido = apellido;
    });

    let datos = JSON.parse(localStorage.getItem('usuario'));

    if (datos.passwordTemp) {
      this.router.navigate(['cambiar-contrasena']);
      return;
    }

    this.mainService.get(`user/${datos.id}`).subscribe((result: any) => {
      this.user = result;
      this.authService.cambiarNombreAct(result.nombre);
      this.authService.cambiarApellidoAct(result.apellido);
    });
    this.menuService.menu.subscribe((menu) => {
      if (menu && menu !== '') {
        this.seleccionarMenu(menu);
      }
    });
  }

  /**
   * Método para seleccionar menú
   * @param {string} r
   */
  seleccionarMenu(r) {
    this.menu = r;
  }
  cerrar() {
    this.authService.logout();
    this.router.navigate(['']);
  }
}
