<div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Cargando ...</p>
  </ngx-spinner>
  <div class="fondo">
    <div class="contenedor-formulario">
      <h1 class="formulario-titulo">Iniciar Sesión</h1>
      <form
        class="formulario"
        [formGroup]="registerForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="formulario-informacion">
          <label class="label" for="email">Correo electrónico</label>
          <input
            class="input"
            id="email"
            type="text"
            name="email"
            placeholder="Escribe aquí"
            formControlName="email"
          />
          <span
            class="texto-error"
            *ngIf="
              (registerFormControl.email.touched || submitted) &&
              registerFormControl.email.errors?.required
            "
          >
            Correo electrónico requerido
          </span>
          <span
            class="texto-error"
            *ngIf="
              registerFormControl.email.touched &&
              registerFormControl.email.errors?.email
            "
          >
            Correo electrónico inválido
          </span>

          <label class="label" for="contraseña"> Contraseña</label>
          <div class="contrasena">
            <input
              id="contraseña"
              class="input"
              [type]="muestra"
              placeholder="Escribe aquí"
              formControlName="password"
            />

            <span (click)="togglePass()" class="visualizar">
              <img
                [style.display]="
                  muestra == 'password' ? 'inline-block' : 'none'
                "
                class="ojo"
                src="assets/recursos/logos-iconos/ojo-off.png"
                alt="ojo-off contraseña"
              />
              <img
                [style.display]="
                  muestra == 'password' ? 'none' : 'inline-block'
                "
                class="ojo"
                src="assets/recursos/logos-iconos/ojo-on.png"
                alt="ojo-off contraseña"
              />
            </span>
          </div>
          <span
            class="texto-error"
            *ngIf="
              (registerFormControl.password.touched || submitted) &&
              registerFormControl.password.errors?.required
            "
          >
            Contraseña es requerida
          </span>
          <span
            class="texto-error"
            *ngIf="
              registerFormControl.password.touched &&
              registerFormControl.password.errors?.invalidPassword
            "
          >
            La Contraseña debe tener un mínimo de 8 caracteres con números y
            letras.
          </span>

          <p class="label-link label morado" routerLink="/olvide-mi-contraseña">
            Olvidé mi contraseña
          </p>
        </div>

        <button class="boton" type="submit">Iniciar sesión</button>
        <div class="registro">
          <p class="label">¿Aún no tienes cuenta?</p>
          <p class="label-link label morado" routerLink="/crear-cuenta">
            Regístrate
          </p>
        </div>
      </form>
    </div>
  </div>
  <!-- FORMULARIO OLVIDÉ MI CONTRASEÑA -->
</div>
