<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.tipo"
  [autohide]="true"
  [delay]="5000"
  (hide)="toastService.remove(toast)"
>
  <h3>{{ toast.titulo }}</h3>
  {{ toast.cuerpo }}</ngb-toast
>
