import { Component, OnInit } from '@angular/core';

import { MainService } from '../../../services/main.service';
import { AuthService } from '../../../services/auth.service';
import { PolizaService } from '../../../services/poliza.service';
import { Router } from '@angular/router';

import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-financiaciones',
  templateUrl: './financiaciones.component.html',
  styleUrls: ['./financiaciones.component.scss']
})
export class FinanciacionesComponent implements OnInit {
  detalle: boolean = true;
  public isCollapsed: any;
  public user: any;
  public polizas: any = [];
  polizaSeleccionada: any;
  public valorPagar: number = 0;
  public poliza: any;
  public cuotasPagar: any = "";
  public pse: boolean = false;
  public agregarMetodo: boolean = false;
  customLoadingTemplate;
  public polizaNew: any;
  cuotaInicial: any;

  constructor(
    private modalService: NgbModal,
    public mainService: MainService,
    public authService: AuthService,
    private spinner: NgxSpinnerService,
    private polizaService: PolizaService,
    private router: Router
  ) {
    this.user = JSON.parse(localStorage.getItem('usuario'));
  }

  ngOnInit(): void {
    this.traerPolizas();

  }
  ondetalle(id){
    this.polizaSeleccionada=this.polizas[id]
    this.polizaNew= this.polizaSeleccionada
    this.detalle= !this.detalle
  }

  eliminar(id, index){
    Swal.fire({
      title: '',
      text: "¿Está seguro de eliminar la póliza?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          '¡Póliza eliminada!',
          'Tu póliza ha sido eliminada.',
          'success'
        )

        this.authService.delete({ api: "api/poliza/"+ id}).subscribe(result => {
          this.spinner.hide();
          this.polizas.splice(index, 1);
        }, err => {
          this.spinner.hide();
        });
      }
    })
  }

  public traerPolizas() {
    this.spinner.show();
    this.authService.get({ api: "api/poliza/usuario/"+ this.user.id}).subscribe(result => {
      this.spinner.hide();
      this.polizas = result;
    }, err => {
      this.spinner.hide();
    });
  }

  public openModal(content, pago) {
    if (pago == "inicial") {
      this.modalService.open(content, { centered: true });
    } else {
      this.modalService.open(content, { size: "lg", centered: true });
    }
  }

  public cambiarCuotas(event) {
    console.log("changeCuota")
    let mora = 0;
    console.log("poliza", this.polizaSeleccionada)
    this.valorPagar =
      parseFloat(
        this.polizaSeleccionada.planDePagos[this.polizaSeleccionada.planDePagos.length - 1].valor
      ) * parseFloat(this.cuotasPagar);
    for (let p of this.polizaSeleccionada.planDePagos) {
      if (p.valorMora > 0 && p.pagado === "no") {
        mora += p.valorMora;
      }
    }
    this.valorPagar += mora;
  }

  volverPse(event) {
    if (event) {
      this.pse = false;
    }
  }

  // redirection to the document section for initial payment
  public goToInitialPayment(poliza){
    this.polizaService.savePolizaRemote(poliza)
    sessionStorage.setItem('pasoDocumentos', '5')
    this.router.navigate(['documentos']);
  }

  public goToSignPagare(poliza){
    this.polizaService.savePolizaRemote(poliza)
    sessionStorage.setItem('pasoDocumentos', '2')
    this.router.navigate(['documentos'])
  }
}
