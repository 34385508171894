<section class="container">
    <div class="d-flex">
        <h2 class="mr-3">Clientes finales</h2>
    </div>
    <div class="d-flex">
       
        <button [ngClass]="{'boton': tabActive == 'lista', 'botoninactivo': tabActive == 'invitados'}" (click)="tabActive='lista'">
           Lista de clientes
        </button>        
        <button class="marginLeft" [ngClass]="{'boton': tabActive == 'invitados', 'botoninactivo': tabActive == 'lista'}"  (click)="tabActive='invitados'" >
            Clientes invitados
        </button>
    </div>
    <div class="tabla w-100 mt-4" *ngIf="tabActive == 'lista'">
        <ng2-smart-table *ngIf="datosCargados" [settings]="settings" [source]="info">
        </ng2-smart-table>
    </div>
    <div class="tabla w-100 mt-4"  *ngIf="tabActive != 'lista'">
        <ng2-smart-table *ngIf="infoInvitaciones" [settings]="settingsInvitados" [source]="infoInvitaciones">
        </ng2-smart-table>
    </div>
</section>
