<div class="fondo">
  <div class="container">
    <div class="padding-simulador">
      <p class="text-center title">Simula tu financiación</p>
      <div class="card-simula">
        <div class="row">
          <div class="col-md-6">
            <p class="text-input">Fecha de inicio de vigencia *</p>
            <input (click)="d.toggle()" (blur)="onBlurMethod()" [(ngModel)]="fechaInicioVigencia" class="input"
              placeholder="AAAA/MM/DD" name="dp" ngbDatepicker #d="ngbDatepicker" />
          </div>
          <div class="col-md-6">
            <p class="text-input">Valor del seguro a financiar {{ insuranceValue}} </p>
            <input currencyMask type="string" (keyup)="insuranceChanged.next($event)" [options]="{
                suffix: '',
                thousands: '.',
                decimal: ',',
                precision: 0,
                align: 'left'
              }" [(ngModel)]="insuranceValue" class="input" placeholder="Escribe aquí" />
          </div>
          <div class="col-md-6">
            <p class="text-input">Porcentaje de la cuota inicial</p>
            <select class="input" name="porcentaje" placeholder="Escribe aquí"
              [(ngModel)]="porcentajeCuotaInicialParam">
              <option value="">Seleccione una opción</option>
              <option value="{{ cuota }}" *ngFor="let cuota of cuotaInicialPermitidas()">
                {{ cuota * 100 }}% del valor total ({{
                insuranceValue * cuota | currency: "COP":"symbol":"1.0-0"
                }})
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <p class="text-input">Número de cuotas de tu seguro</p>
            <select class="input" name="cuotas" placeholder="Escribe aquí" [(ngModel)]="numeroCuotasParam">
              <option value="">Seleccione una opción</option>
              <option value="{{ cuotas }}" *ngFor="let cuotas of cuotasPermitidas()">
                {{ cuotas }} cuotas
              </option>
            </select>
          </div>

          <div class="col-md-6">
            <p class="text-input">Beneficiario oneroso (opcional)</p>
            <input class="input" name="cuotas" placeholder="Escribe aquí" [(ngModel)]="beneficiarioOneroso" />
          </div>
        </div>

        <div class="row justify-content-center pt-5">
          <button (click)="limpiarPlan()" class="btn banner-btn backgroundMorado">
            Limpiar
          </button>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="container" id="imprime">
      <app-plan-de-pagos [(numeroCuotas)]="numeroCuotasParam" [(porcentajeCuotaInicial)]="porcentajeCuotaInicialParam"
        [(valorTotal)]="insuranceValue"></app-plan-de-pagos>
      <div class="text-center"></div>
    </div>
  </div>
</div>