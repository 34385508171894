<div class="fondo-modal" [style.display]="mostrarModal">
  <div class="container-modal" [style.display]="mostrarModal">
    <div class="imagen-contenido">
      <span class="cerrar" (click)="cerrarModal()"> &#10799; </span>
      <img
        class="logo-finsu"
        src="assets/recursos/logos-iconos/logo-finsu-azul.png"
        alt="Finsu logo"
      />
      <section class="invitacion">
        <h3 class="invitacion-titulo">¡Queremos prestarte un buen servicio!</h3>
        <p class="invitacion-parrafo">
          Para Finsu tus solicitudes son muy importantes, nuestras líneas de
          atención al cliente están disponibles para ti.
        </p>
      </section>
    </div>

    <div class="informacion-contacto fondo-purpura">
      <div>
        <span class="responsive-cerrar cerrar" (click)="cerrarModal()">
          &#10799;
        </span>
        <h3 class="informacion-titulo">
          Canales de comunicación <br />
          Finsu - Seguros Mundial
        </h3>
      </div>

      <div class="informacion-canales">
        <div class="canal-item">
          <img
            class="item-icon"
            src="assets/recursos/logos-iconos/phone.png"
            alt="telefono"
          />

          <div class="item-info">
            <strong>(601) 4434520</strong><br />Línea PBX
          </div>
        </div>
        <div class="canal-item">
          <img
            class="item-icon"
            src="assets/recursos/logos-iconos/phone.png"
            alt="phone"
          />
          <div class="item-info"><strong>333 6025262</strong><br />Celular</div>
        </div>
        <div class="canal-item">
          <img
            class="item-icon"
            src="assets/recursos/logos-iconos/whatsapp-blanco.png"
            alt="whatsapp"
          />
          <div class="item-info">
            <strong>316 2805153</strong><br />
            Whatsapp
          </div>
        </div>
      </div>

      <div class="informacion-emails">
        <p
          (click)="copiarCorreo('mundial-finsu1@serviefectivo.com.co')"
          ngbPopover="Correo copiado"
          placement="left"
          [closeDelay]="1000"
        >
          mundial-finsu1@serviefectivo.com.co
        </p>
        <p
          (click)="copiarCorreo('Viviana.ramirez@serviefectivo.com.co')"
          ngbPopover="Correo copiado"
          placement="left"
          [closeDelay]="1000"
        >
          Viviana.ramirez@serviefectivo.com.co
        </p>
        <p
          (click)="copiarCorreo('comercial@finsu.co')"
          ngbPopover="Correo copiado"
          [closeDelay]="1000"
          placement="left"
        >
          comercial@finsu.co
        </p>
      </div>
    </div>
  </div>
</div>

<footer class="fondo-morado font-small mdb-color pt-4">
  <div class="container">
    <div class="row padding-row">
      <div class="col-md-7">
        <div>
          <a routerLink="/home" class="logo-menu">
            <img
              src="assets/recursos/logos-iconos/logo1.png"
              class="logo-rockstart"
              alt="Logo Finsu"
            />
          </a>
        </div>
        <p class="color-p">
          <label routerLink="/politica-datos" class="text-label">
            Política de tratamiento de datos personales</label
          >
          <br />
          Dirección: Calle 63 #28A-62
          <br />
          <button class="boton-modal" (click)="abrirModal()">
            Contáctanos
          </button>
        </p>
      </div>
      <div class="col-md-5 align-self-center">
        <div class="row padding-row2 d-flex justify-content-end info-finsu">
          <p class="margin-p text-end">
            Finsu S.A.S. NIT 901.307.632-2 <br />
            todos los derechos reservados
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
