import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';



@Injectable()
export class LoggedInGuard implements CanActivate {
    
    constructor(public authService: AuthService, public router: Router) {
    }

    canActivate() {
        if(this.authService.loggedIn()) {
            let user = JSON.parse(localStorage.getItem('usuario'));

            console.log("user tipo",  user)
            if(user.tipo == 'Intermediario') {
                this.router.navigate(['intermediario']);
            } else {
                this.router.navigate(['usuario']);
            }
            return false;
        } else {
            return true;
        }
    }
}