<section class="polizas-container">
  <section class="polizas-header">
    <section class="header-opciones">
      <h2>Pólizas</h2>
      <button routerLink="/crear-poliza" class="boton create-poliza">
        + Crear Pólizas
      </button>

      <button
        class="boton invitar-cliente"
        routerLink="/intermediario/invitar-cliente"
      >
        Invitar cliente
      </button>
    </section>

    <div class="header-notificacion">
      <div (click)="openNotifications()">
        <img
          *ngIf="!hayNotificacionesSinLeer"
          class="icon-notificacion"
          src="assets/recursos/logos-iconos/notificaciones-off.png"
          alt="notificacion"
        />
        <img
          *ngIf="hayNotificacionesSinLeer"
          class="icon-notificacion"
          src="assets/recursos/logos-iconos/notificaciones-on.png"
          alt="notificacion"
        />
      </div>

      <div *ngIf="modalNotificacion" class="modal-notificacion">
        <div *ngIf="notificaciones.length !== 0">
          <div class="notificacion-poliza-numero">
            <p (click)="marcarNotificaciones()">Marcar todos como leídos</p>
            <p (click)="closeNotifications()">Close</p>
          </div>
          <div class="items-notificacion">
            <div
              *ngFor="let notificacion of notificaciones"
              class="item-notificacion"
            >
              <div>
                <img
                  *ngIf="notificacion.leida"
                  class="item-notificacion-icon"
                  src="assets/recursos/logos-iconos/notificaciones-read.png"
                  alt="notificacion"
                />
                <img
                  (click)="marcarLeidaNotificacion(notificacion._id)"
                  *ngIf="!notificacion.leida"
                  class="item-notificacion-icon pointer"
                  src="assets/recursos/logos-iconos/notificaciones-no-read.png"
                  alt="notificacion"
                />
              </div>
              <div class="notification-container-info">
                <p class="notificacion-poliza-numero">
                  <span> Póliza: {{ notificacion.poliza.numeroPoliza }} </span>
                  <span> Fecha: {{ notificacion.fecha }} </span>
                </p>
                <p class="descripcion-card">
                  {{ notificacion.mensaje.content }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="polizas-datos">
    <div class="card-poliza">
      <img
        class="card-imagen"
        src="assets/recursos/logos-iconos/polizas-pendientes.png"
        alt="poliza-en-mora"
      />
      <div class="datos-card">
        <p class="numero-card">{{ pendientes }}</p>
        <p class="descripcion-card">
          Pólizas <br />
          pendientes
        </p>
      </div>
    </div>
    <div class="card-poliza">
      <img
        class="card-imagen"
        src="assets/recursos/logos-iconos/polizas-aprobadas .png"
        alt="poliza-en-mora"
      />
      <div class="datos-card">
        <p class="numero-card">{{ aprobadas }}</p>
        <p class="descripcion-card">
          Pólizas <br />
          aprobadas
        </p>
      </div>
    </div>
    <div class="card-poliza">
      <img
        class="card-imagen"
        src="assets/recursos/logos-iconos/polizas-rechazadas.png"
        alt="poliza-en-mora"
      />
      <div class="datos-card">
        <p class="numero-card">{{ rechazadas }}</p>
        <p class="descripcion-card">
          Pólizas <br />
          rechazadas
        </p>
      </div>
    </div>

    <div class="card-poliza">
      <img
        class="card-imagen"
        src="assets/recursos/logos-iconos/poliza-en-mora.png"
        alt="poliza-en-mora"
      />
      <div class="datos-card">
        <p class="numero-card">{{ mora }}</p>
        <p class="descripcion-card">
          Pólizas <br />
          en mora
        </p>
      </div>
    </div>
  </section>

  <section class="tablas-opciones">
    <p
      (click)="cambiarOpcion()"
      [ngClass]="{ 'opcion-seleccionada': seleccion, 'tabla-opcion': true }"
    >
      Pólizas activas
    </p>
    <p
      (click)="cambiarOpcion()"
      [ngClass]="{ 'opcion-seleccionada': !seleccion, 'tabla-opcion': true }"
    >
      Historial pólizas
    </p>
  </section>

  <section class="tabla" *ngIf="seleccion && datosCargados">
    <ng2-smart-table
      [settings]="settings"
      [source]="polizasActivas"
      (custom)="verDetallePoliza($event)"
    >
    </ng2-smart-table>
  </section>

  <section class="tabla" *ngIf="!seleccion && datosCargados">
    <ng2-smart-table
      (custom)="verDetallePoliza($event)"
      [settings]="settings"
      [source]="polizasHistorial"
    >
    </ng2-smart-table>
  </section>
</section>
