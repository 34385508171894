<div class="div-fondo">
    <div class="container">
        <div class="row padding-row">
            <div class="col-md-6 d-none d-md-block padding-col">
                <img width="80%" src="assets/recursos/financiar/ilustracion-financia.png" class="banner" alt="Imagen banner" />
            </div>
            <div class="col-md-6">
                <h1 class="banner-titulo">Financiar</h1>
                <p class="banner-descripcion">
                    Paga tu seguro a cuotas desde tu computador o celular. Luego de pagar la cuota inicial a la aseguradora, Finsu le avisará a un inversionista para que financie el saldo correspondiente.
                </p>
                <button routerLink="/crear-cuenta" class="btn banner-btn backgroundMorado">
          Quiero financiar
        </button>
            </div>
            <div class="col-md-6 d-block d-md-none padding-col">
                <img width="80%" src="assets/recursos/financiar/ilustracion-financia.png" class="banner" alt="Imagen banner" />
            </div>
        </div>
    </div>
</div>
<div class="fondo-morado2 paso-a-paso">
    <div class="container">
        <app-paso-a-paso></app-paso-a-paso>
    </div>
</div>
<div>
    <app-simulador></app-simulador>
    <div class="text-center">
        <input class="btn-imprimir backgroundMorado" type="button" name="imprimir" value="Imprimir" (click)="imprimir()" />
    </div>
</div>

<app-footer class="footer"></app-footer>