import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import swal from "sweetalert2";
import { MainService } from "../../services/main.service";
// import { userInfo } from "os";

@Component({
  selector: 'app-pseRespuesta',
  templateUrl: './pse-respuesta.component.html',
  styleUrls: ['./pse-respuesta.component.scss'],
})


export class PSERespuestaComponent implements OnInit {
  usuario: any = {};
  pse: any = {};
  estadoPago: any = {};
  /** Loading por favor espere */
  cargandoMulti = false;
  /**Tipo de modal */
  textoEstado: string = "";
  idTransaccion: string = "";
  idPoliza: string = "";
  constructor(public router: Router, private mainService: MainService) {}

  ngOnInit() {
    if (localStorage.user) {
      this.usuario = JSON.parse(localStorage.user);
    }
    if (localStorage.idPSE) {
      this.pse = localStorage.idPSE;
      this.idTransaccion = JSON.parse(localStorage.idTransaccion);
      if (localStorage.idPoliza) {
        this.idPoliza = JSON.parse(localStorage.idPoliza);
      }
      this.traerPagoPse();
    }else{
      this.alertPago("Error al consultar la transacción.", "warning");
      this.router.navigate(['usuario']);
    }
  }

  async traerPagoPse() {
    this.cargandoMulti = true;
    let env = {
      autorizacion: JSON.parse(this.pse),
    };
    let result: any = await this.mainService.postPago('pagos/traerTransaccionPSE/', env);
    if (result) {
      this.estadoPago = result;
      if (this.estadoPago && this.estadoPago.body) {
        // this.enviar.epayco = this.estadoPago.body.data;
        if (this.estadoPago.body) {
          if (this.estadoPago.body.x_transaction_state == "Aceptada") {
            this.alertPago("Pago con PSE realizado exitosamente.", "success");
            this.actualizarTransaccion(
              this.idTransaccion,
              this.estadoPago.body,
              "Aceptada epayco",
              "Aceptada",
              ""
            );
            if (this.idPoliza && this.idPoliza !== "") {
              this.actualizarEstadoPoliza(
                this.idPoliza,
                "Revisión documentos"
              );
            }
          }
          if (this.estadoPago.body.x_transaction_state == "Fallida") {
            this.alertPago("Pago con PSE fallido.", "warning");
            this.actualizarTransaccion(
              this.idTransaccion,
              this.estadoPago.body,
              "Fallida epayco",
              "Fallida",
              ""
            );
            if (this.idPoliza && this.idPoliza !== "") {
              this.actualizarEstadoPoliza(
                this.idPoliza,
                "Cuota inicial pago rechazado"
              );
              this.router.navigate(['usuario']);
            }
          }
          if (this.estadoPago.body.x_transaction_state == "Rechazada") {
            this.alertPago("Pago con PSE rechazado.", "error");
            this.actualizarTransaccion(
              this.idTransaccion,
              this.estadoPago.body,
              "Rechazada epayco",
              "Rechazada",
              ""
            );
            if (this.idPoliza && this.idPoliza !== "") {
              this.actualizarEstadoPoliza(
                this.idPoliza,
                "Cuota inicial pago rechazado"
              );
            }
          }
          if (this.estadoPago.body.x_transaction_state == "Pendiente") {
            this.alertPago(
              "La transacción está en proceso de verificación. El estado de la transacción se actualizará en unos momentos. Recibirás un correo indicando el estado.",
              "info"
            );
            this.actualizarTransaccion(
              this.idTransaccion,
              this.estadoPago.body,
              "Pendiente epayco",
              "Pendiente",
              this.pse
            );
          }
          localStorage.removeItem("idPSE");
          localStorage.removeItem("idTransaccion");
          localStorage.removeItem("idPoliza");
        } else {
          this.alertPago("Error al obtener el pago.", "warning");
          this.router.navigate(['usuario']);
        }
      } else {
        this.alertPago("Error al obtener el pago.", "warning");
        this.router.navigate(['usuario']);
      }
    } else {
        this.alertPago("Error al obtener el pago.", "warning");
        this.router.navigate(['usuario']);
      }
  }

  alertPago(text, estado) {
    swal.fire({
      title: 'FINSU',
      icon: estado,
      text,
      confirmButtonText: 'Entiendo',
    })

    this.router.navigate(["usuario"]);

    // swal.fire("FINSU", text, estado).then((result) => {
    //   if (result.value) {
    //     this.router.navigate(["usuario"]);
    //   }
    // });
  }

  public async actualizarTransaccion(
    idTransaccion,
    result,
    estado,
    estadoEpayco,
    idPSE
  ) {
    let transaccion = {
      estado: estado,
      referenciaEpayco: result.ref_payco,
      estadoEpayco: estadoEpayco,
      autorizacionPseEpayco: result.x_approval_code,
    };
    let respuesta: any = await this.mainService.put(
      'transaccion/' + idTransaccion, transaccion
    );
    if (respuesta){
      console.log("existe transaccion");

    } else {
      console.log("error else");

    }
  }

  public async actualizarEstadoPoliza(id, estado) {
    let respuesta: any = await this.mainService.put(
      'poliza/' + id,  { estado: estado }
    );
    if (respuesta){
      console.log("existe transaccion");

    } else {
      console.log("error else");

    }
  }
}
