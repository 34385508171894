import { Component, OnInit, OnDestroy } from '@angular/core';
import { Options } from 'ng5-slider';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { PolizaService } from 'src/app/services/poliza.service';
import { FormatterDataService } from 'src/app/services/formatter-data.service';
import { AppToastService } from 'src/app/services/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'crear-poliza-intermediario-html',
  templateUrl: 'crear-poliza-intermediario.component.html',
  styleUrls: ['crear-poliza-intermediario.component.scss'],
  providers: [AuthService],
})
export class CrearPolizaIntermediarioComponent implements OnInit, OnDestroy {
  public paso2: boolean = false;
  public metodovolver: boolean = false;
  public selecSucursales = [
    'AG.VILLAVICENCIO',
    'AGENCIA BOYACA CASANARE',
    'AGENCIA BUCARAMANGA',
    'AGENCIA EJE CAFETERO',
    'AGENCIA GIRARDOT',
    'AGENCIA IBAGUE',
    'AGENCIA IBAGUE',
    'AVIACION BOGOTA',
    'BARRANQUILLA',
    'BOGOTA',
    'CALI',
    'CARTAGENA',
    'CEN ARMENIA',
    'CEN BOGOTA - CALLE 93',
    'CEN BOGOTA – CHICO',
    'CEN BOGOTA ANDES',
    'CEN BOGOTA CHAPINERO',
    'CEN BOGOTA OCCIDENTE',
    'CEN CALI SUR',
    'CEN CUCUTA',
    'CEN MONTERIA',
    'CEN PASTO',
    'CEN REGION CARIBE',
    'CEN SABANA CENTRO',
    'CEN TULUA',
    'CEN URABA',
    'CEN VALLE DE ABURRA SUR',
    'MEDELLIN',
    'NEIVA',
    'PAV – LLANOCENTRO VILLAVICENCIO',
    'PAV 118',
    'PAV BUCARAMANGA CABECERA',
    'PAV CALI PALMETTO',
    'PAV CALLE 33',
    'PAV CEDRITOS',
    'PAV CHIA',
    'PAV DORADO PLAZA',
    'PAV KENNEDY BOGOTA',
    'PAV VILLA DEL_RIO',
    'PRINCIPAL',
    'PVM ACACIAS TRANSITO -  META',
    'PVM ALSACIA',
    'PVM AVENIDA CHILE',
    'PVM AVENTURA – MEDELLIN',
    'PVM BOSA MICENTRO',
    'PVM BOSA TREBOLIS',
    'PVM BUCARAMANGA FLORIDA',
    'PVM BULEVAR',
    'PVM CALIMA',
    'PVM CALIMA CALI',
    'PVM CENTRO SUBA',
    'PVM CHIA',
    'PVM DUITAMA',
    'PVM FACATATIVA PORTICO',
    'PVM FLORESTA',
    'PVM FONTIBON FIESTA',
    'PVM FUSAGASUGA',
    'PVM GRAN ESTACION',
    'PVM GRAN PLAZA BOSA',
    'PVM GRAN PLAZA ENSUEÑO',
    'PVM HAYUELOS',
    'PVM IBAGUE MULTICENTRO',
    'PVM LA CENTRAL – MEDELLIN',
    'PVM LA ESTACIÓN - IBAGUE',
    'PVM MADRID CASABLANCA',
    'PVM MASTER CENTER',
    'PVM MAYORCA',
    'PVM MILENIO PLAZA – BOGOTA',
    'PVM MOLINOS MEDELLIN',
    'PVM MONTERREY',
    'PVM MOSQUERA',
    'PVM MULTIPLAZA',
    'PVM OVIEDO',
    'PVM PASEO SAN RAFAEL',
    'PVM PIEDECUESTA – SANTANDER',
    'PVM PLAZA CENTRAL',
    'PVM PLAZA DE LAS AMÉRICAS',
    'PVM PORTAL 80',
    'PVM PORTOALEGRE',
    'PVM PREMIUM PLAZA - MEDELLIN',
    'PVM PUERTA DEL NORTE – ANTIOQUIA',
    'PVM SALITRE PLAZA',
    'PVM SAN NICOLAS',
    'PVM SOACHA',
    'PVM SOACHA VENTURA',
    'PVM SOGAMOSO',
    'PVM TINTAL PLAZA',
    'PVM TITAN PLAZA BOGOTA',
    'PVM TUNAL',
    'PVM TUNJA UNICENTRO',
    'PVM UNICENTRO MEDELLIN',
    'PVM VIVA ENVIGADO',
    'PVM VIVA LA CEJA',
    'PVM VIVA TUNJA',
    'PVM VIVA VILLAVICENCIO',
    'PVMS FONTIBON VIVA',
  ];
  private widget: any = null;
  nombreaArchivo = 'Seleccionar archivo';
  cargadoPoliza: boolean = false;
  loading: boolean = false;
  public docPoliza: string = '';
  archivo: any;
  /** La nueva placa a agregar */
  public nuevaPlaca: string = '';
  /** La lista de placas agregadas */
  public placas: string[] = [];

  labelValue: string;
  public cuotasSinBeneficiario: number = 0;
  /**Variable para almacenar cuotas con beneficiario */
  public cuotasConBeneficiario: number = 0;
  ramoOpciones: any = [];
  tasaMensual: number = 0.016708963873128146;
  public simulacion: any = [];
  plan: any = [];
  usuario: string;
  diasValidos = false;
  estado: string;
  nombre: string;
  correoUsuario: string;
  correoValidacion: boolean = false;
  beneficiarioOneroso: string;
  valorSeguro: number = 0;
  porcentajeCuotaInicial: number = null;
  cuotaInicial: number;
  numeroCuotas: string = '';
  polizaDoc: string;
  // numeroCuotasParam: number;
  empresaAseguradora: string = null;
  sucursal: string;
  ramo: string = null;
  numeroPoliza: string;
  numeroCertificado: string = '';
  fechaInicioVigencia: NgbDateStruct;
  fechaInicio = moment();
  fechaFin = moment().add(5, 'days');

  insuranceValue: number = 0;
  /** Máximo valor permitido por la plataforma */
  readonly maxValueAllowed = 20_000_000;
  /** Mínimo valor permitido por la plataforma */
  readonly minValueAllowed = 500_000;

  insuranceChanged = new Subject<number>();

  options: Options = {
    //floor: 500000,
    //TODO: Cambiar esto cuando las pruebas dejen de hacerse
    floor: 500000,
    ceil: 10000000,
    step: 1000,
    // step: 500000,
    enforceStep: false,
    enforceRange: false,
    translate: (value: number): string => {
      if (value == 0) {
        return '$' + value;
      } else if (Number.isInteger(value / 1000000)) {
        return '$' + value / 1000000 + '.000.000';
      } else if (Number.isInteger(value / 100000)) {
        let x = value + '';

        if (x.length == 7) {
          return (
            '$' +
            x.substring(0, 1) +
            '.' +
            x.substring(1, 4) +
            '.' +
            x.substring(4, 7)
          );
        } else if (x.length == 6) {
          return '$' + x.substring(0, 3) + '.' + x.substring(3, 6);
        }
      } else {
        let x = value + '';

        if (x.length == 7) {
          return (
            '$' +
            x.substring(0, 1) +
            '.' +
            x.substring(1, 4) +
            '.' +
            x.substring(4, 7)
          );
        } else if (x.length == 6) {
          return '$' + x.substring(0, 3) + '.' + x.substring(3, 6);
        } else return '$';
      }
    },
    getSelectionBarColor: (value: number): string => {
      return '#FC9A0D';
    },
    getPointerColor: (value: number): string => {
      return '#FC9A0D';
    },
  };

  validatedData = {};

  toDuplicatePoliza = [
    'valorSeguro',
    'empresaAseguradora',
    'beneficiarioOneroso',
    'porcentajeCuotaInicial',
    'sucursal',
    'ramo',
    'numeroCuotas',
    'numeroPoliza',
    'numeroCertificado',
    'nombre',
    'email'
  ]

  constructor(
    private mainService: MainService,
    private authService: AuthService,
    private polizaService: PolizaService,
    private formatterDataService: FormatterDataService,
    private spinner: NgxSpinnerService
  ) {
    this.widget = (window as any).cloudinary.createUploadWidget(
      {
        cloudName: 'hh6guh7qh',
        uploadPreset: 'wncq8grq',
      },
      (error, result) => {
        if (
          result.info &&
          result.info.files &&
          result.info.files[0].uploadInfo &&
          result.info.files[0].uploadInfo.url
        ) {
          console.log('Voy a imprimir nombres');
          this.nombreaArchivo = result.info.files[0].name;
          this.archivo = result.info.files[0].uploadInfo.url;
          this.docPoliza = result.info.files[0].uploadInfo.url;
          console.log(this.nombreaArchivo, this.docPoliza);
          this.loading = false;
          localStorage.setItem('docPoliza', this.docPoliza);
          Swal.fire({
            title: '¡Éxito!',
            text: 'Se ha cargado la poliza exitosamente.',
            icon: 'success',
          });
          this.cargadoPoliza = true;
        }
      }
    );
  }

  ngOnInit(): void {
    this.insuranceChanged.pipe(debounceTime(2000)).subscribe(() => {
      this.cuotasPermitidas();
    });

    this.fechaInicioVigencia = {
      year: parseInt(moment().format('YYYY')),
      month: parseInt(moment().format('MM')),
      day: parseInt(moment().format('DD')),
    };

    if(this.polizaService.getPolizaDuplicate()) this.fillFormDuplicatedInsurance()
  }

  ngOnDestroy(): void {
    this.polizaService.removePolizaDuplicate()
  }

  // Revisa si se sobrepasan los valores máximos o mínimos
  exceededValues() {
    return (
      this.insuranceValue < this.minValueAllowed ||
      this.insuranceValue > this.maxValueAllowed
    );
  }

  fillFormDuplicatedInsurance(){
    let duplicatedPoliza = this.polizaService.getPolizaDuplicate()
    duplicatedPoliza = { ...duplicatedPoliza, ...duplicatedPoliza.usuario }

    this.toDuplicatePoliza.forEach(itemPoliza => {
      let auxiliarItem = itemPoliza

      if (!duplicatedPoliza[itemPoliza]) return
      if (itemPoliza === 'email') auxiliarItem = 'correoUsuario'
      if(itemPoliza === 'empresaAseguradora') this.llenarOpcionesRamo(duplicatedPoliza[itemPoliza])

      this[auxiliarItem] = duplicatedPoliza[itemPoliza]
    })

    this.insuranceValue = duplicatedPoliza.valorSeguro
  }

  // Para gestionar validaciones y seguir al paso 2
  cambiarPaso2() {
    this.paso2 = true;
    // this.validateForm();
  }

  /**
   * Agrega una placa a la lista si cumple con los requisitos
   * @param {FormControl} placaValidacion El control que nos ofrece las validaciones
   */
  agregarPlaca(placaValidacion: FormControl): void {
    if (placaValidacion.invalid) {
      Swal.fire({
        title: '¡Error!',
        text: 'La placa no cuenta con el formato adecuado.',
        icon: 'error',
      });
      return;
    }
    if (this.placas.length >= 10) {
      Swal.fire({
        title: '¡Error!',
        text: 'Se ha alcanzado el número límite de placas.',
        icon: 'error',
      });
      return;
    }
    if (
      this.placas.some(
        (placa) =>
          placa.toLowerCase().trim() === this.nuevaPlaca.toLowerCase().trim()
      )
    ) {
      Swal.fire({
        title: '¡Error!',
        text: 'La placa ya ha sido agregada.',
        icon: 'error',
      });
      return;
    }
    this.placas.push(this.nuevaPlaca.toUpperCase());
    this.nuevaPlaca = '';
    Swal.fire({
      title: '¡Éxito!',
      text: 'Placa agregada.',
      icon: 'success',
    });
  }

  /**
   * Elimina la placa que se cliquea de la lista
   * @param {number} index El indice de la lista
   */
  eliminarPlaca(index: number): void {
    this.placas.splice(index, 1);
  }

  uploadPoliza() {
    this.spinner.show;
    this.widget.open();
  }

  llenarOpcionesRamo(event) {
    switch (event) {
      case 'Mundial Seguros':
        this.ramoOpciones = [
          '3 VEHÍCULOS PESADOS DE CARGA',
          '4 AVIACIÓN',
          '20 CORRIENTE DEBIL',
          //"24 RESPONSABILIDAD CIVIL EXTRACONTRACTUAL",
          '40 INFIDELIDAD Y RIESGOS FINANCIEROS',
          '44 CREDITO A LA EXPORTACIÓN',
          '46 CRÉDITO COMERCIAL PARA EMISORES',
          '52 TODO RIESGO CONSTRUCCIÓN Y MONTAJE',
          '60 ARRENDAMIENTO',
          '64 RESPONSABILIDAD CIVIL EXTRACONTRACTUAL - AUTOMÓVILES',
          '70 GRUPO VIDA',
          '74 RESPONSABILIDAD CIVIL EXTRACONTRACTUAL GENERAL',
          '75 ACCIDENTES PERSONALES',
          '76 RESPONSABILIDAD CIVIL DIRECTORES Y ADMIN',
          '77 RESPONSABILIDAD CIVIL PROFESIONAL',
          '78 RC GARANTÍA EXTENDIDA',
          '79 RC FAMILIAR',
          '90 TODO RIESGO EQUIPO Y MAQUINARIA',
        ];
        break;
      case 'SBS Seguros':
        this.ramoOpciones = [
          'RCE: Responsabilidad Civil Extracontractual',
          'D&O: Directors and officers',
          'IRF: Infidelidad y Riesgos financieros',
          'RCP: Ambiental',
          'FILMACIONES',
          'CYBER',
          'ENSAYOS CLINICOS',
          'Todo Riesgo Construcción',
          'Todo Riesgo Montaje',
          'Responsabilidad  Civil Ambiental',
          'Transporte',
          'RCE Derivada de Cumplimiento',
          'Cumplimiento entre Particulares',
        ];
        break;
      default:
        this.ramoOpciones = [];
        break;
    }
  }

  onBlurMethod() {
    if (this.fechaInicioVigencia !== undefined) {
      console.log(this.fechaInicioVigencia);
      let fecha =
        this.fechaInicioVigencia.year +
        '/' +
        this.fechaInicioVigencia.month +
        '/' +
        this.fechaInicioVigencia.day;
      fecha = moment(fecha).format('YYYY/MM/DD');
      this.fechaInicio = moment(fecha);
      this.fechaFin = moment(this.fechaInicio).add(5, 'days');
      if (!moment(fecha).isValid()) {
        this.fechaInicioVigencia = undefined;
        Swal.fire(
          'Formato de fecha no valida, por favor indique un formato válido para poder continuar.'
        );
      }
    }
  }

  public cuotasPermitidas() {
    let cuotas = [];
    if (this.insuranceValue && this.insuranceValue > 0) {
      if (
        this.insuranceValue >= this.minValueAllowed &&
        this.insuranceValue < 600000
      ) {
        cuotas = [5];
      } else if (
        this.insuranceValue >= 600000 &&
        this.insuranceValue < 700000
      ) {
        cuotas = [5, 6];
      } else if (
        this.insuranceValue >= 700000 &&
        this.insuranceValue < 800000
      ) {
        cuotas = [5, 6, 7];
      } else if (
        this.insuranceValue >= 800000 &&
        this.insuranceValue < 900000
      ) {
        cuotas = [5, 6, 7, 8];
      } else if (
        this.insuranceValue >= 900000 &&
        this.insuranceValue < 1000000
      ) {
        cuotas = [5, 6, 7, 8, 9];
      } else {
        cuotas = [5, 6, 7, 8, 9];
      }
      let i = cuotas.length;
      while (i--) {
        if (
          this.beneficiarioOneroso &&
          cuotas[i] > this.cuotasConBeneficiario
        ) {
          cuotas.splice(i, 1);
        }
        if (
          !this.beneficiarioOneroso &&
          cuotas[i] > this.cuotasSinBeneficiario
        ) {
          cuotas.splice(i, 1);
        }
      }
      return cuotas;
    } else {
      return [''];
    }
  }

  public cuotaInicialPermitidas() {
    let cuotasIniciales = [];
    if (this.fechaInicioVigencia) {
      let fecha = '';
      if (typeof this.fechaInicioVigencia == 'object') {
        fecha =
          this.fechaInicioVigencia.year +
          '/' +
          this.fechaInicioVigencia.month +
          '/' +
          this.fechaInicioVigencia.day;
      } else {
        fecha = this.fechaInicioVigencia;
      }
      fecha = moment(fecha).format('YYYY/MM/DD');
      let diasDiferenciaVigencia = moment(fecha).diff(moment(), 'days');
      // Diferencia en días desde el inicio de la vigencia y el día actual. Negativo son días en el pasado         "YYYY-MM-DD"
      if (diasDiferenciaVigencia <= -90) {
        this.diasValidos = true;
        return;
      }
      if (diasDiferenciaVigencia <= -61) {
        cuotasIniciales = [0.4];
        this.cuotasSinBeneficiario = 7;
        this.cuotasConBeneficiario = 6;
        this.diasValidos = false;
        return cuotasIniciales;
      }
      if (diasDiferenciaVigencia <= -30 && diasDiferenciaVigencia >= -60) {
        cuotasIniciales = [0.3, 0.4];
        this.cuotasSinBeneficiario = 7;
        this.cuotasConBeneficiario = 6;
        this.diasValidos = false;
        return cuotasIniciales;
      }
      if (diasDiferenciaVigencia <= -16 && diasDiferenciaVigencia > -30) {
        cuotasIniciales = [0.3, 0.4];
        this.cuotasSinBeneficiario = 8;
        this.cuotasConBeneficiario = 7;
        this.diasValidos = false;
        return cuotasIniciales;
      }
      if (diasDiferenciaVigencia <= -1 && diasDiferenciaVigencia > -16) {
        cuotasIniciales = [0.2, 0.3, 0.4];
        this.cuotasSinBeneficiario = 9;
        this.cuotasConBeneficiario = 8;
        this.diasValidos = false;
        return cuotasIniciales;
      } else if (diasDiferenciaVigencia >= 0) {
        cuotasIniciales = [0.15, 0.2, 0.3, 0.4];
        this.cuotasSinBeneficiario = 9;
        this.cuotasConBeneficiario = 8;
      }
      return cuotasIniciales;
    } else {
      return [''];
    }
  }

  hayInputsVacios() {
    return (
      !this.nombre ||
      this.nombre.length == 0 ||
      !this.correoUsuario ||
      this.correoUsuario.length == 0 ||
      this.fechaInicioVigencia == null ||
      this.valorSeguro == 0 ||
      this.porcentajeCuotaInicial == null ||
      this.numeroCuotas == null ||
      this.empresaAseguradora == null ||
      this.ramo == null ||
      !this.numeroPoliza ||
      this.numeroPoliza.toString().length == 0 ||
      !this.numeroCertificado ||
      this.numeroCertificado.toString().length == 0
    );
  }

  generarPlanDePagos() {
    this.simulacion = [];
    for (let i = 1; i < parseInt(this.numeroCuotas) + 1; i++) {
      let x;
      if (i == 1) {
        x = {
          fecha: moment().add(i, 'M').format('YYYY-MM-DD'),
          saldoCapital: this.valorSeguro * (1 - this.porcentajeCuotaInicial),
          valor: this.PMT(
            this.tasaMensual, //, //* (1 + this.config.iva),,
            this.numeroCuotas,
            this.valorSeguro * (1 - this.porcentajeCuotaInicial) * -1,
            0,
            0
          ),
          abonoCapital:
            this.PMT(
              this.tasaMensual, //, //* (1 + this.config.iva),,
              this.numeroCuotas,
              this.valorSeguro * (1 - this.porcentajeCuotaInicial) * -1,
              0,
              0
            ) -
            this.valorSeguro *
              (1 - this.porcentajeCuotaInicial) *
              this.tasaMensual, //, //* (1 + this.config.iva),,
          intereses:
            this.valorSeguro *
            (1 - this.porcentajeCuotaInicial) *
            this.tasaMensual,
          /* IVA:
                this.valorTotal *
                (1 - this.porcentajeCuotaInicial) *
                this.tasaMensual * this.config.iva, */
        };
      } else {
        x = {
          fecha: moment().add(i, 'M').format('YYYY-MM-DD'),
          saldoCapital:
            this.simulacion[i - 2].saldoCapital -
            this.simulacion[i - 2].abonoCapital,
          valor: this.PMT(
            this.tasaMensual, //, //* (1 + this.config.iva),,
            this.numeroCuotas,
            this.valorSeguro * (1 - this.porcentajeCuotaInicial) * -1,
            0,
            0
          ),
          abonoCapital:
            this.PMT(
              this.tasaMensual, //, //* (1 + this.config.iva),,
              this.numeroCuotas,
              this.valorSeguro * (1 - this.porcentajeCuotaInicial) * -1,
              0,
              0
            ) -
            (this.simulacion[i - 2].saldoCapital -
              this.simulacion[i - 2].abonoCapital) *
              this.tasaMensual, //*(1 + this.config.iva),
          intereses:
            (this.simulacion[i - 2].saldoCapital -
              this.simulacion[i - 2].abonoCapital) *
            this.tasaMensual,
          /* IVA:
                (this.simulacion[i - 2].saldoCapital -
                  this.simulacion[i - 2].abonoCapital) *
                this.tasaMensual, // *    this.config.iva, */
        };
      }
      this.simulacion.push(x);
      this.plan = this.simulacion;
      // this.enviarPlan();
    }
  }

  onChangeValidarCorreo() {
    this.esCorreoValido(this.correoUsuario);
  }

  esCorreoValido(email: string) {
    if (this.mainService.validarEmail(email)) {
      this.correoValidacion = false;
      return true;
    }

    this.correoValidacion = true;
    return false;
  }

  actualizarLabel() {
    if (this.insuranceValue < this.minValueAllowed) {
    }
    this.labelValue = 'Precio $' + this.insuranceValue;
  }

  manejarEnvioFormulario() {
    this.ejecutaCrearPoliza();
  }

  public validateForm() {
    if (
      !this.numeroCertificado &&
      !this.numeroPoliza &&
      !this.ramo &&
      !this.sucursal &&
      !this.numeroCuotas &&
      !this.porcentajeCuotaInicial &&
      !this.insuranceValue
    ) {
      return Swal.fire({
        title: '¡Error!',
        text: 'Los campos no se han completado.',
        icon: 'error',
      });
    }

    if (this.diasValidos === true) {
      return Swal.fire(
        '',
        'La fecha inició de la póliza, no debe superar 90 días de vigencia.',
        'warning'
      );
    }

    if (this.hayInputsVacios()) {
      return Swal.fire('', 'Hay campos vacíos o incompletos.', 'warning');
    }

    if (this.valorSeguro < this.minValueAllowed) {
      return Swal.fire(
        '',
        `El valor del seguro a financiar debe ser mayor o igual a $500.000.`,
        'warning'
      );
    }

    if (this.exceededValues()) {
      return Swal.fire({
        title: '¡Error!',
        text: 'El valor del seguro debe estar entre 500.000 y 20.000.000 (COP).',
        icon: 'error',
      });
    }

    if (this.valorSeguro > this.maxValueAllowed) {
      return Swal.fire(
        '',
        `El valor del seguro a financiar debe ser menor o igual a $20.000.000.`,
        'warning'
      );
    }

    if (!this.esCorreoValido(this.correoUsuario)) {
      return Swal.fire('', 'El correo es inválido.', 'warning');
    }

    // if (!this.samePassword()) {
    //   return Swal.fire('', 'Las contraseñas no son iguales.', 'warning');
    // }

    this.paso2 = true;
  }

  public ejecutaCrearPoliza() {
    // policy process
    this.mainService
      .get(`user/email/info/${this.correoUsuario}`)
      .subscribe((result: any) => {
        if (result.success) {
          this.generarPlanDePagos();
          let planPagos = this.generarPlanDePagos();
          let polizaData = {
            usuario: result.user._id,
            dataNotificacion: {
              idUser: result.user._id,
              email: result.user.email,
              nombres: result.user.nombre + ' ' + result.user.apellido,
            },
            estado: 'Pendiente Usuario',
            cuotaInicial: this.valorSeguro * this.porcentajeCuotaInicial,
            valorSeguro: this.valorSeguro,
            porcentajeCuotaInicial: this.porcentajeCuotaInicial,
            fechaInicioVigencia: this.fechaInicio.format('YYYY-MM-DD'),
            fechaFinVigencia: this.fechaFin.format('YYYY-MM-DD'),
            numeroCertificado: this.numeroCertificado,
            numeroPoliza: this.numeroPoliza,
            docPoliza: localStorage.getItem('docPoliza') || '',
            numeroCuotas: this.numeroCuotas,
            usuarioIntermediario: this.authService.obtenerUsuarioActual().id,
            sucursal: this.sucursal,
            ramo: this.ramo,
            placas: this.placas,
            empresaAseguradora: this.empresaAseguradora,
            planDePagos: this.plan,
          };
          if (this.beneficiarioOneroso != null) {
            polizaData['beneficiarioOneroso'] = this.beneficiarioOneroso;
          }
          this.mainService
            .post('poliza', polizaData)
            .then((polizaRespuesta: any) => {
              if (polizaRespuesta.msj) {
                Swal.fire(polizaRespuesta.msj, '', 'warning');
                return false;
              }
              if (polizaRespuesta._id) {
                Swal.fire({
                  title: '<strong>La póliza fue iniciada exitosamente</strong>',
                  html: 'Se ha enviado al cliente un correo electronico para la verificacion y firma de la misma.',
                  showCloseButton: true,
                  focusConfirm: false,
                  confirmButtonText: 'Entendido',
                  confirmButtonColor: '#415ce5',
                  confirmButtonAriaLabel: 'Entendido',
                });
                this.mainService.redirigirA('intermediario');
              }
            })
            .catch((error) => {
              Swal.fire('', 'Error al crear la póliza.', 'warning');
            });
        } else {
          Swal.fire('', 'El correo no existe.', 'warning');
        }
      });
  }

  public PMT(ir, np, pv, fv, type) {
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0) return -(pv + fv) / np;

    pvif = Math.pow(1 + ir, np);
    pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

    if (type === 1) pmt /= 1 + ir;

    return pmt;
  }

  formatterNumber(event) {
    let valueToUpdate = '';
    const formattedNumber = this.formatterDataService.justNumbers(
      event.target.value
    );

    if (formattedNumber) {
      valueToUpdate = this.formatterDataService
        .shortNumber(formattedNumber)
        .toString();
    }

    event.target.value = valueToUpdate;
    this.numeroCertificado = valueToUpdate;
  }
}
