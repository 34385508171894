<div class="degradado-fondo">
  <div class="container">
    <div class="row padding-row">
      <div class="col-md-6 d-none d-md-block">
        <img
          src="assets/recursos/financiar/finsu-landing.png"
          class="banner"
          alt="Imagen banner"
        />
      </div>
      <div class="col-md-6">
        <h1 class="banner-titulo">
          Financia <br />
          tu seguro
        </h1>
        <p class="banner-descripcion">
          Con Finsu puedes pagar tu seguro a cuotas mensuales con una baja tasa
          de interés y hacerlo rápidamente de forma digital. Ofrecemos estos
          pagarés a inversionistas que quieren obtener un mejor retorno en un
          corto plazo.
        </p>
        <button class="btn" routerLink="/financiar">Iniciar</button>
      </div>
      <div class="col-md-6 d-block d-md-none">
        <img
          src="assets/recursos/financiar/finsu-landing.png"
          class="banner"
          alt="Imagen banner"
        />
      </div>
    </div>
      <a
        class="logo-whatsapp"
        href="https://chatwith.io/es/s/finsu"
        target="_blank"
        rel="noopener"
      >
        <img
          src="assets/img/boton-whatstapp.png"
          alt="whatstapp Finsu"
          height="48px "
        />
      </a>
  </div>
</div>
<div class="fondo-caracteristicas">
  <div class="container">
    <app-caracteristicas></app-caracteristicas>
  </div>
</div>
<div class="fondo-morado">
  <div class="container">
    <app-como-lo-hacemos></app-como-lo-hacemos>
  </div>
</div>
<div class="fondo-caracteristicas">
  <div class="container">
    <app-financia-tu-seguro></app-financia-tu-seguro>
  </div>
</div>
<div class="container">
  <app-aliados></app-aliados>
</div>

<app-footer></app-footer>
