<nav
  class="navbar navbar-expand-lg navbar-light bg-light rounded main-menu header-nav"
>
  <a class="navbar-brand" routerLink="/home" class="logo-menu">
    <img
      src="assets/recursos/logos-iconos/logo1.png"
      class="logo-rockstart"
      alt="Logo Finsu"
    />
  </a>
  <button
    class="navbar-toggler"
    type="button"
    (click)="isMenuCollapsed = !isMenuCollapsed"
  >
    <span class="span-nav-color">&#9776;</span>
  </button>
  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
    <ul class="navbar-nav main-menu-principal">
      <li class="nav-item">
        <a
          class="nav-link text-header"
          routerLink="/financiar"
          (click)="isMenuCollapsed = true"
          routerLinkActive="active-link"
          >Financiar</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link text-header"
          routerLink="/preguntas"
          (click)="isMenuCollapsed = true"
          routerLinkActive="active-link"
          >FAQ</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-header"
          routerLink="/contacto"
          (click)="isMenuCollapsed = true"
          routerLinkActive="active-link"
          >Contacto</a
        >
      </li>
      <li class="nav-item d-md-block d-lg-none" *ngIf="settingsAuth.loggIn">
        <a
          class="nav-link text-header"
          routerLink="/usuario"
          (click)="isMenuCollapsed = true; seleccionarMenu('resumen')"
          [class.active-link]="menu == 'resumen'"
          >Resumen</a
        >
      </li>
      <li class="nav-item d-md-block d-lg-none" *ngIf="settingsAuth.loggIn">
        <a
          class="nav-link text-header"
          [routerLink]="rutaActual"
          (click)="isMenuCollapsed = true; seleccionarMenu('perfil')"
          [class.active-link]="menu == 'perfil'"
          >Perfil</a
        >
      </li>
      <li class="nav-item d-md-block d-lg-none" *ngIf="settingsAuth.loggIn">
        <a
          class="nav-link text-header"
          routerLink="/usuario"
          (click)="isMenuCollapsed = true; seleccionarMenu('financiaciones')"
          [class.active-link]="menu == 'financiaciones'"
          >Financiaciones</a
        >
      </li>
      <li class="nav-item d-md-block d-lg-none" *ngIf="settingsAuth.loggIn">
        <a
          class="nav-link text-header"
          routerLink="/usuario"
          (click)="isMenuCollapsed = true; seleccionarMenu('pqr')"
          [class.active-link]="menu == 'pqr'"
          >PQR's</a
        >
      </li>
      <li class="nav-item d-md-block d-lg-none" *ngIf="settingsAuth.loggIn">
        <a
          class="nav-link btn-link text-header btn-Aplica"
          routerLink="/iniciar-sesion"
          (click)="manejarCierreSesion()"
          routerLinkActive="aplica"
          >Cerrar sesión</a
        >
      </li>
      <li class="nav-item d-md-block d-lg-block" *ngIf="!settingsAuth.loggIn">
        <a
          class="nav-link btn-link text-header btn-Aplica profileHightLight"
          routerLink="/iniciar-sesion"
          (click)="isMenuCollapsed = true"
          routerLinkActive="aplica"
          >Iniciar Sesión</a
        >
      </li>
      <li
        class="nav-item d-none d-md-none d-lg-block"
        *ngIf="settingsAuth.loggIn"
      >
        <a
          class="nav-link btn-link text-header btn-Aplica profileHightLight"
          [routerLink]="'/' + settingsAuth.userType"
          (click)="isMenuCollapsed = true"
          routerLinkActive="aplica"
          >Perfil</a
        >
      </li>
    </ul>
  </div>
</nav>
