import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-financiar',
  templateUrl: './financiar.component.html',
  styleUrls: ['./financiar.component.scss']
})
export class FinanciarComponent implements OnInit {

  mostrarImprimir: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  imprimir() {
    window.print();
  }

}
