<section class="section-top">
  <div class="row">
    <div class="col-md-12">
      <h2 class="h2-title">Aliados</h2>
    </div>

    <div class="container-aliados">
      <!-- Logos aliados-->
      <div class="text-center">
        <img
          class="logos"
          src="assets/recursos/logos-iconos/logo_epayco_400px.png"
          alt="epayco"
        />
      </div>
      <div class="text-center">
        <img
          class="logos"
          src="assets/recursos/logos-iconos/logoMUNDIAL.png"
          alt="Mundial"
        />
      </div>
      <div class="text-center">
        <img
          class="logos"
          src="assets/recursos/logos-iconos/Serviefectivo.png"
          alt="Serviefectivo"
        />
      </div>
      <div class="text-center">
        <img
          class="logos"
          src="assets/recursos/logos-iconos/imagine_apps.png"
          alt="Imagina Apps"
        />
      </div>
      <div class="text-center">
        <img
          class="logos"
          src="assets/recursos/logos-iconos/olimpia.png"
          alt="Olimpia"
        />
      </div>
    </div>
  </div>
</section>
