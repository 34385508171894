<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Cargando ...</p>
</ngx-spinner>

<p class="titulo">Resumen</p>
<div class="margin-row">
  <div class="col-12 col-md-12 col-lg-9">
    <div class="row card-blanca" *ngIf="polizaMostrar">
      <div class="columns-start">
        <div class="column">
          <div>
            <p class="text-gris">Saldo a capital pendiente por pagar</p>
            <p class="monto-gris">
              <ng-container
                *ngIf="
                  polizaMostrar.estado !== 'Cuota inicial pago rechazado' &&
                    polizaMostrar.estado !== 'Pendiente';
                  else cuotaInicialTemplate
                "
              >
                {{ capitalPorPagar | currency : "COP" : "symbol" : "1.0-0" }}
              </ng-container>
              <ng-template #cuotaInicialTemplate>
                {{
                  polizaMostrar.valorSeguro
                    | currency : "COP" : "symbol" : "1.0-0"
                }}
              </ng-template>
            </p>
          </div>
          <div>
            <p class="text-gris">Ramo</p>
            <p class="text-gris">{{ polizaMostrar.ramo || "No definido" }}</p>
          </div>
        </div>
        <div class="column">
          <div [class.max-35]="polizaMostrar.docPagare">
            <p class="text-gris">Fecha límite de pago cuota inicial</p>
            <p class="text-gris">
              {{ getMargenFechaLimite(polizaMostrar.fechaInicioVigencia) }}
            </p>
          </div>
          <div>
            <p class="text-gris">Placas</p>
            <p class="text-gris">
              {{ polizaMostrar.placas?.join(", ") || "No definido" }}
            </p>
          </div>
        </div>
        <div class="column">
          <div>
            <p class="text-gris">Número pagaré</p>
            <p class="text-gris">
              {{ polizaMostrar.numeroPagare || "No definido" }}
            </p>
          </div>
          <div
            class="col-6 col-md-6 margin-col max-15"
            *ngIf="polizaMostrar.docPagare"
          >
            <a
              [href]="polizaMostrar.docPagare"
              target="_blank"
              class="text-gris pagare"
            >
              <p>Pagaré</p>
            </a>
          </div>
        </div>
      </div>

      <div class="col-12">
        <p class="text-gris">Siguiente cuota</p>
        <div class="row card-carne">
          <div
            class="col-12 col-md-6"
            *ngIf="
              polizaMostrar.estado !== 'Cuota inicial pago rechazado' &&
              polizaMostrar.estado !== 'Pendiente'
            "
          >
            <p>Fecha límite de pago</p>
            <p>
              {{ polizaMostrar.proximaCuota | date }}
            </p>
          </div>
          <div
            class="col-12 col-md-6"
            *ngIf="
              polizaMostrar.estado === 'Cuota inicial pago rechazado' &&
              polizaMostrar.estado !== 'Pendiente'
            "
          >
            <p>Cuota inicial por pagar</p>
            <p>
              <label
                *ngIf="ultimoPago.estadoEpayco == 'Pendiente'"
                style="color: gray; font-size: 14px"
                >La transacción está en proceso de verificación. <br />
                El estado de la transacción se actualizará en unos
                momentos.</label
              >
              <label
                *ngIf="ultimoPago.estadoEpayco == 'Rechazada'"
                style="color: red; font-size: 14px"
                >El pago de la cuota inicial fue rechazado, intenta de nuevo el
                pago.</label
              >
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p
              class="monto-negro"
              *ngIf="
                polizaMostrar.estado !== 'Cuota inicial pago rechazado' &&
                polizaMostrar.estado !== 'Pendiente'
              "
            >
              {{
                polizaMostrar.planDePagos[0].valor
                  | currency : "COP" : "symbol" : "1.0-0"
              }}
            </p>
            <p
              class="monto-negro"
              *ngIf="
                polizaMostrar.estado === 'Cuota inicial pago rechazado' ||
                polizaMostrar.estado === 'Pendiente'
              "
            >
              {{
                polizaMostrar.cuotaInicial
                  | currency : "COP" : "symbol" : "1.0-0"
              }}
            </p>
          </div>
        </div>
        <button
          class="btn btn-pagar"
          (click)="goToInitialPayment()"
          *ngIf="polizaMostrar.estado === 'Cuota inicial pago rechazado'"
        >
          Pagar cuota inicial
        </button>
      </div>
    </div>
    <div class="row" *ngIf="!polizaMostrar">
      <div class="col-4 logo-finsu">
        <img
          src="assets/recursos/logos-iconos/logo-finsu.png"
          class="logo"
          alt="Logo Finsu"
        />
        <p>No has creado una póliza. Para empezar haz clic en 'Cotizar'.</p>
      </div>
    </div>
    <p class="titulo-busca">Busca financiamiento</p>
    <div>
      <button class="btn btn-cotizar" routerLink="/simulador">Cotizar</button>
    </div>
  </div>
</div>
