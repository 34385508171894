<div class="wrapper-detalle">
  <ngx-loading
  [show]="loadingCarga"
  [config]="{ backdropBorderRadius: '3px' }"
>
</ngx-loading>
  <div class="container card-detalle">
    <div class="card-encabezado mb-4">
      <h2>Número de la póliza</h2>
      <h2 class="numero-poliza">{{ poliza.numeroPoliza }}</h2>
    </div>

    <div class="w-100 container">
      <div class="gap-inputs row justify-content-center">
        <div class="col-4 d-flex flex-column">
          <label class="label-disabled label-poliza" for="nombre"
            >Nombre*</label
          >
          <input
            class="input-poliza"
            id="nombre"
            type="text"
            placeholder="{{ poliza.usuario.nombre }}"
            disabled
          />
        </div>

        <div class="col-4 d-flex flex-column">
          <label class="label-disabled label-poliza" for="email"
            >Apellidos*</label
          >
          <input
            class="input-poliza"
            type="email"
            id="email"
            placeholder="{{ poliza.usuario.apellido }}"
            disabled
          />
        </div>
      </div>

      <div class="gap-inputs mt-4 row justify-content-center">
        <div class="col-4 d-flex flex-column">
          <label class="label-disabled label-poliza" for="nombre"
            >Tipo de documento*</label
          >
          <input
            class="input-poliza"
            id="nombre"
            type="text"
            placeholder="{{ tipoDocumento[poliza.usuario.tipoDocumento] }}"
            disabled
          />
        </div>

        <div class="col-4 d-flex flex-column">
          <label class="label-disabled label-poliza" for="email"
            >Número de documento</label
          >
          <input
            class="input-poliza"
            type="email"
            id="email"
            placeholder="{{ poliza.usuario.numeroDocumento }}"
            disabled
          />
        </div>
      </div>
      <div class="gap-inputs mt-4 row justify-content-center">
        <div class="col-4 d-flex flex-column">
          <label
            class="label-poliza"
            for="nombre"
            [attr.aria-disabled]="!esEditable"
            >Número de la póliza*</label
          >
          <input
            class="input-poliza"
            id="nombre"
            type="text"
            placeholder="Escribe aquí"
            [(ngModel)]="numeroPoliza"
            disabled
          />
        </div>

        <div class="col-4 d-flex flex-column">
          <label
            class="label-poliza"
            for="email"
            [attr.aria-disabled]="!esEditable"
            >Estado</label
          >
          <input
            class="input-poliza"
            type="email"
            id="email"
            placeholder="Pendiente"
            [(ngModel)]="estado"
            disabled
          />
        </div>
      </div>
      <div class="gap-inputs mt-4 row justify-content-center">
        <div class="col-4 d-flex flex-column">
          <label
            class="label-poliza"
            for="nombre"
            [attr.aria-disabled]="!esEditable"
            >Beneficiario Oneroso*</label
          >
          <input
            class="input-poliza"
            id="nombre"
            type="text"
            placeholder="Escribe aquí"
            [(ngModel)]="beneficiarioOneroso"
            disabled
          />
        </div>

        <div class="col-4 d-flex flex-column">
          <label class="label-disabled label-poliza" for="email"
            >Fecha de creación</label
          >
          <input
            class="input-poliza"
            type="email"
            id="email"
            placeholder="{{ fechaCreacion }}"
            disabled
          />
        </div>
      </div>
      <div class="gap-inputs mt-4 row justify-content-center">
        <div class="col-4 d-flex flex-column">
          <label
            class="label-disabled label-poliza"
            for="nombre"
            [attr.aria-disabled]="!esEditable"
            >Fecha límite</label
          >
          <input
            class="input-poliza"
            id="nombre"
            type="text"
            placeholder="{{ fechaLimite }}"
            disabled
          />
        </div>

        <div class="col-4 d-flex flex-column">
          <label
            class="label-poliza"
            for="email"
            [attr.aria-disabled]="!esEditable"
            >Valor de la póliza*</label
          >
          <input
            class="input-poliza"
            type="string"
            id="email"
            placeholder="Escribe aquí"
            currencyMask
            [disabled]="editarPoliza"
            (change)="cuotaInicialPermitidas()"
            [(ngModel)]="valorSeguro"
            [options]="{
              suffix: '',
              thousands: '.',
              decimal: ',',
              precision: 0,
              align: 'left'
            }"
          />
        </div>
      </div>
      <div class="gap-inputs mt-4 row justify-content-center">
        <div class="col-4 d-flex flex-column">
          <label
            class="label-poliza"
            for="nombre"
            aria-disabled="true"
            [attr.aria-disabled]="!esEditable"
            >Número de cuotas</label
          >
          <select
            class="text-indent-select input-poliza"
            id="nombre"
            type="text"
            [disabled]="editarPoliza"
            [(ngModel)]="numeroCuotas"
          >
            <option
              value="{{ cuotas }}"
              *ngFor="let cuotas of cuotasPermitidas()"
            >
              {{ cuotas }} cuotas
            </option>
          </select>
        </div>

        <!--<div class="col-4 d-flex flex-column">
          <label class="label-disabled label-poliza" for="email"
            >Referencia de póliza</label
          >
          <input
            class="input-poliza"
            type="email"
            id="email"
            placeholder="{{ poliza._id }}"
            disabled
          />
        </div>-->
      </div>
      <div class="gap-inputs mt-4 row justify-content-center">
        <div class="col-4 d-flex flex-column">
          <label
            class="label-poliza"
            for="nombre"
            [attr.aria-disabled]="!esEditable"
            >Porcentaje de cuota inicial</label
          >
          <select
            class="text-indent-select input-poliza"
            id="nombre"
            [(ngModel)]="porcentajeCuotaInicial"
            type="text"
            [disabled]="editarPoliza"
          >
            <option
              value="{{ cuota }}"
              *ngFor="let cuota of cuotaInicialPermitidas()"
            >
              {{ cuota * 100 }}% del valor total ({{
                valorSeguro * cuota | currency: "COP":"symbol":"1.0-0"
              }})
            </option>
          </select>
        </div>

        <div class="col-4 d-flex flex-column">
          <label
            class="label-poliza"
            for="email"
            [attr.aria-disabled]="!esEditable"
            >Fecha de inicio de vigencia
          </label>
          <input
            class="input-poliza"
            type="email"
            [disabled]="editarPoliza"
            id="fechaVigencia"
            placeholder="AAAA/MM/DD"
            (click)="d.toggle()"
            (blur)="onBlurMethod()"
            [(ngModel)]="fechaInicioVigencia"
            name="dp"
            ngbDatepicker
            #d="ngbDatepicker"
          />
        </div>
      </div>
    </div>

    <div class="mt-5 botones row justify-content-center">
      <button routerLink="/intermediario" class="volver">Volver</button>

      <button
        *ngIf="!esVencida"
        (click)="manejarEnvioFormulario()"
        [disabled]="!esEditable"
        class="editar"
      >
        Editar
      </button>

      <button *ngIf="esVencida" (click)="crearNuevaPoliza()" class="editar">
        Nueva póliza
      </button>
    </div>
  </div>
</div>

