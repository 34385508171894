<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Por favor espere ...</p>
</ngx-spinner>
<div *ngIf="mostrarLogin">
  <div>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
      <p style="font-size: 20px; color: white">Cargando ...</p>
    </ngx-spinner>
    <div class="fondo">
      <div class="contenedor-formulario">
        <h1 class="formulario-titulo">Debes iniciar sesión para continuar</h1>
        <form class="formulario" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <div class="formulario-informacion">
            <label class="label" for="email">Email</label>
            <input class="input" id="email" type="text" name="email" placeholder="Escribe aquí"
              formControlName="email" />
            <span class="texto-error" *ngIf="
            (registerFormControl.email.touched || submitted) &&
            registerFormControl.email.errors?.required
          ">
              Email es requerido
            </span>
            <span class="texto-error" *ngIf="
            registerFormControl.email.touched &&
            registerFormControl.email.errors?.email
          ">
              Email inválido
            </span>

            <label class="label" for="contraseña"> Contraseña</label>
            <div class="contrasena">
              <input id="contraseña" class="input" [type]="muestra" placeholder="Escribe aquí"
                formControlName="password" />

              <span (click)="togglePass()" class="visualizar">
                <img [style.display]="muestra == 'password' ? 'inline-block' : 'none'" class="ojo"
                  src="assets/recursos/logos-iconos/ojo-off.png" alt="ojo-off contraseña" />
                <img [style.display]="muestra == 'password' ? 'none' : 'inline-block'" class="ojo"
                  src="assets/recursos/logos-iconos/ojo-on.png" alt="ojo-off contraseña" />
              </span>
            </div>
            <span class="texto-error" *ngIf="
            (registerFormControl.password.touched || submitted) &&
            registerFormControl.password.errors?.required
          ">
              Contraseña es requerida
            </span>
            <span class="texto-error"
              *ngIf="registerFormControl.password.touched && registerFormControl.password.errors?.invalidPassword">
              La Contraseña debe tener un mínimo de 8 caracteres con números y letras.
            </span>
          </div>
          <button class="boton" type="submit">Iniciar sesión</button>
        </form>
      </div>
    </div>
    <!-- FORMULARIO OLVIDÉ MI CONTRASEÑA -->
  </div>
</div>
<div class="fondo" *ngIf="!mostrarLogin">
  <div class="container" *ngIf="dataPoliza">
    <div class="padding-simulador">
      <p class="text-center title" routerLink="/">
        <em class="fa fa-angle-left"></em> &nbsp;Documentación
      </p>
      <div class="row">
        <div class="tituloPagareC" *ngIf="paso < 5">
          <strong class="titProFin">Proceso de financiamiento</strong> <br /> Para iniciar el proceso de financiamiento
          debes firmar un pagaré en blanco para que FINSU se encargue de conseguir un inversionista.
        </div>
        <div class="contenedor-docs">
          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
              <h5 *ngIf="paso < 5">Firma del pagaré en blanco</h5>
              <p *ngIf="paso < 5">
                <strong>Debemos confirmar tu cédula y número celular para firmar el documento.
                </strong> Puedes ver el documento a firmar en este link:
                <a href="https://www.finsu.co/assets/docs/PagareFinsu.pdf" target="_blank" rel="noopener">Pagaré en
                  Blanco</a>
              <p *ngIf="validacionCedulaResultado">
                <i style="color: green; margin-right: 10px" class="fa fa-check" aria-hidden="true"></i>
                Identidad verificada con número de cédula
              </p>

              <!-- PASO 1: BLOQUE DE VERIFICACIÓN DE CÉDULA -->
              <div *ngIf="paso == 1 && preguntasRecorrer.length === 0 ">
                <div class="form-block">
                  <label for="">Confirma tu número de cédula &nbsp;</label>
                  <input type="text" [(ngModel)]="user.numeroDocumento" class="inputFinsu selectPorcentaje"
                    placeholder="Ej: 12345678" disabled />
                </div>
                <p *ngIf="otpEnviada && !validacionCedulaResultado">
                  <i style="color: red; margin-right: 10px" class="fa fa-times" aria-hidden="true"></i>
                  La cédula no es válida{{ validacionCedulaMensaje }}
                </p>
                <!--Boton para activar validación por preguntas //-->
                <!--<button *ngIf="!validacionCedulaResultado" (click)="psdValidarIdentidad()"
                  class="botonEnviarPagare">Verificar Identidad
                </button>-->
                <!--Boton para activar validación de documento-->
                <button *ngIf="!validacionCedulaResultado" (click)="verificarUsuario()" class="botonEnviarPagare">
                  Verificar Identidad
                </button>
              </div>

              <div *ngIf="paso == 1 && preguntasRecorrer.length > 0">
                <div *ngIf="preguntas.success">
                  <div *ngFor="let p of preguntasRecorrer; let i = index">
                    <hr />
                    <label>{{ p["b:TextoPregunta"] }}</label>
                    <div>
                      <!-- <p>{{r["b:TextoRespuesta"]}}</p> -->
                      <div *ngFor="
                          let r of p['b:OpcionesRespuesta'][
                            'b:OpcionRespuesta'
                          ];
                          let j = index
                        ">
                        <input id="{{ p['b:IdPregunta'] }}" type="radio" value="{{ r['b:IdRespuesta'] }}"
                          name="{{ p['b:IdPregunta'] }}" (change)="changeRadio(r['b:IdRespuesta'], i, j)" />
                        <label style="margin-left: 10px" for="{{ p['b:IdPregunta'] }}">{{ r["b:TextoRespuesta"]
                          }}</label>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <button (click)="chequearRespuestas()" class="botonEnviarPagare">
                    Enviar respuestas
                  </button>
                  <!-- <button class="btn" (click)="enviarValidacion()"> Enviar</button> -->
                </div>
              </div>

              <!-- FIN BLOQUE DE VERIFICACIÓN DE CÉDULA -->
              <!-- PASO 2: BLOQUE DE ENVIAR MENSAJE DE TEXTO -->
              <div *ngIf="paso == 2 || paso == 3" class="margin-bottom-20">
                <div class="form-block">
                  <label for="">Ingresa tu celular sin espacios. Ej: 3121112233</label>
                  <input type="text" [(ngModel)]="celularVerificacion" class="inputFinsu selectPorcentaje"
                    placeholder="Ej: 3121112233" />
                </div>
                <button (click)="enviarOtp()" class="botonEnviarPagare">
                  Enviar Código de Verificación
                </button>
              </div>

              <!-- FIN DE BLOQUE DE ENVIAR MENSAJE DE TEXTO -->
              <!-- PASO 3: BLOQUE DE ESCRIBIR OTP -->
              <div *ngIf="paso == 3">
                <div class="form-block">
                  <label for="">Ingresa el código que enviamos a tu celular</label>
                  <input type="text" [(ngModel)]="codigoOtp" class="inputFinsu selectPorcentaje"
                    placeholder="Ej: 1234" />
                </div>
                <button (click)="firmarDocumento()" class="botonEnviarPagare">
                  Firmar Pagaré
                </button>
              </div>

              <div *ngIf="paso == 4">
                <div class="wrapper">
                  <h1 class="titProceso">Póliza</h1>
                  <div class="row">
                    <div class="col-sm-12">
                      <h5>
                        Carga tu póliza
                        <i *ngIf="cargadoPoliza" style="color: green" class="fa fa-check" aria-hidden="true"></i>
                        <i *ngIf="!cargadoPoliza" style="color: red" class="fa fa-times" aria-hidden="true"></i>
                      </h5>
                      <div class="form-group">
                        <label for="">Carga el documento aquí:</label>
                        <input type="file" ng2FileSelect [uploader]="uploaderPoliza" accept="image/*;capture=camera"
                          (change)="uploadPoliza()" hidden />
                        <button (click)="uploadPoliza()">
                          {{ nombreaArchivo }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="cargadoPoliza">
                    <div class="col-sm-12">
                      <button (click)="vistaPago()" class="botonEnviarPagare">
                        Continuar
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- FIN DE BLOQUE DE ESCRIBIR OTP -->
              <!-- <button *ngIf="otpEnviada && !pagareFirmado" (click)="onEnviarCorreo()" class="botonEnviarPagare">Enviar OTP de nuevo</button> -->
            </div>
          </div>

          <!-- PASO5: CONFIRMACIÓN PAGAR -->
          <div *ngIf="paso == 5">
            <form (ngSubmit)="pagar()" class="pagar-container">
              <h3 class="pagar-titulo">Realizar pago por PSE</h3>

              <h3 class="pagar-titulo">Pago por la oportunidad</h3>

              <label class="pagar-banco"> Seleccione su banco</label>

              <div class="valores">
                <label class="pagar-label-gris pagar-subtotal"> Subtotal</label>

                <label class="pagar-label-gris pagar-subtotal-valor">
                  ${{ valorPoliza | number }}</label>

                <label class="pagar-label-gris pagar-impuestos">
                  Impuestos</label>

                <label class="pagar-label-gris pagar-impuestos-valor">
                  $0</label>

                <label class="pagar-label-negrita pagar-total"> Total</label>

                <label class="pagar-label-negrita pagar-total-valor">
                  ${{ valorPoliza | number }}</label>
              </div>

              <select name="" id="" class="pagar-seleccionar" [(ngModel)]="banco"
                [ngModelOptions]="{ standalone: true }">
                <option selected disabled hidden value="Seleccione">
                  Seleccione
                </option>
                <option *ngFor="let banco of bancos" value="{{ banco.bankCode }}">
                  {{ banco.bankName }}
                </option>
              </select>

              <button class="pagar-boton" type="submit">Pagar</button>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- <button class="btn btn-cotizar" (click)='siguiente()'>Continuar</button> -->
      </div>
    </div>
  </div>
</div>
