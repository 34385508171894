<div class="row padding-row">
  <div class="col-md-12 text-center">
    <p class="title-principal">Paso a paso</p>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="text-center">
        <img
          class="imagen"
          src="assets/recursos/financiar/carpeta-registro.png"
          alt="Imagen carpeta"
        />
      </div>
      <p class="title">Regístrate</p>
      <br />
      <p class="descripcion">Ingresa tus datos y crea tu usuario</p>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="text-center">
        <img
          class="imagen2"
          src="assets/recursos/financiar/telescopio.svg"
          alt="Imagen carpeta"
        />
      </div>
      <p class="title">Cotiza</p>
      <br />
      <p class="descripcion">
        Con los datos de tu póliza podrás conocer tu plan de pagos
      </p>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="text-center">
        <img
          class="imagen2"
          src="assets/recursos/financiar/lampara.svg"
          alt="Imagen carpeta"
        />
      </div>
      <p class="title">Acepta los términos</p>
      <p class="descripcion">Conoce las condiciones de tu financiación</p>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="text-center">
        <img
          class="imagen2"
          src="assets/recursos/financiar/tarjetas.svg"
          alt="Imagen carpeta"
        />
      </div>
      <p class="title">Comienza La financiación</p>
      <p class="descripcion">
        Pagando tu cuota inicial empiezas tu financiación y disfrutas de tu
        seguro.
      </p>
    </div>
  </div>
</div>
