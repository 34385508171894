import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-politica-datos',
  templateUrl: './politica-datos.component.html',
  styleUrls: ['./politica-datos.component.css'],
})
export class PoliticaDatosComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit() {}
}
