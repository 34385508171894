import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root',
})
export class PolizaService {
  valorSeguro: Number;
  porcentajeCuotaInicial: Number;
  cuotaInicial: Number;
  numeroCuotas: Number;
  empresaAseguradora: String;
  ramo: String;
  placas: any[] = [];
  sucursal: String;
  numeroPoliza: Number;
  numeroCertificado: Number;
  beneficiarioOneroso: String;
  fechaInicioVigencia: String;
  planDePagos: any[];
  tasaMensual: number;

  constructor(private mainService: MainService) {}

  async getTasaMensual() {
    try {
      const config = await this.mainService.get('config').toPromise();
      if (config['length'] > 0) this.tasaMensual = config[0].tasaMensual;
      return this.tasaMensual;
    } catch (err) {
      console.log(err);
    }
  }

  setValorSeguro(valor: any) {
    this.valorSeguro = valor;
  }

  setPorcentajeCuotaInicial(porcentaje: any) {
    this.porcentajeCuotaInicial = porcentaje;
  }

  setCuotaInicial(valor: any) {
    this.cuotaInicial = valor;
  }

  setNumeroCuotas(numero: any) {
    this.numeroCuotas = numero;
  }

  setEmpresaAseguradora(empresa: any) {
    this.empresaAseguradora = empresa;
  }

  setRamo(ramo: any) {
    this.ramo = ramo;
  }

  setSucursal(sucursal: any) {
    this.sucursal = sucursal;
  }

  setNumeroPoliza(numeroPoliza: any) {
    this.numeroPoliza = numeroPoliza;
  }

  setNumeroCertificado(numeroCertificado: any) {
    this.numeroCertificado = numeroCertificado;
  }

  setBeneficiarioOneroso(beneficiario: any) {
    this.beneficiarioOneroso = beneficiario;
  }

  setFechaInicio(fechaInicio: any) {
    let tempFecha =
      fechaInicio.year + '/' + fechaInicio.month + '/' + fechaInicio.day;
    let fecha = moment(tempFecha).format('YYYY/MM/DD');
    this.fechaInicioVigencia = fecha;
  }

  setPlanPagos(plan: any) {
    this.planDePagos = plan;
  }

  setTasaMensual(tasa: any) {
    this.tasaMensual = tasa;
  }

  setPlacas(placas: any[]) {
    this.placas = placas;
  }

  getPolizaObjeto() {
    let poliza = {
      valorSeguro: this.valorSeguro,
      porcentajeCuotaInicial: this.porcentajeCuotaInicial,
      cuotaInicial: this.cuotaInicial,
      numeroCUotas: this.numeroCuotas,
      empresaAseguradora: this.empresaAseguradora,
      ramo: this.ramo,
      sucursal: this.sucursal,
      placas: this.placas,
      numeroPoliza: this.numeroPoliza,
      numeroCertificado: this.numeroCertificado,
      beneficiarioOneroso: this.beneficiarioOneroso,
      fechaInicioVigencia: this.fechaInicioVigencia,
      planDePagos: this.planDePagos,
      tasaFinancio: this.tasaMensual,
    };
    return poliza;
  }

  savePolizaLocal() {
    sessionStorage.setItem(
      'poliza_user',
      JSON.stringify(this.getPolizaObjeto())
    );
  }

  savePolizaRemote(polizaToSave) {
    sessionStorage.setItem('poliza_user', JSON.stringify(polizaToSave));
  }

  getPolizaLocal() {
    return JSON.parse(sessionStorage.getItem('poliza_user'));
  }

  removePolizaLocal() {
    sessionStorage.removeItem('poliza_user');
  }

  getPolizaDuplicate() {
    return JSON.parse(sessionStorage.getItem('poliza_duplicated'));
  }

  savePolizaDuplicate(poliza) {
    sessionStorage.setItem('poliza_duplicated', JSON.stringify(poliza));
  }

  removePolizaDuplicate() {
    return sessionStorage.removeItem('poliza_duplicated');
  }

  /**
   * Se encarga de verificar que no existan campos vacios para todo menos para beneficiario Oneroso
   * plan de pagos y tasa de financio
   * @returns  booleano que representa la validez
   */
  verificarPolizaNoNulos() {
    let esNoNula = true;
    let poliza = this.getPolizaObjeto();
    Object.keys(poliza).forEach((element) => {
      if (
        element != 'beneficiarioOneroso' &&
        element != 'planDePagos' &&
        element != 'tasaFinancio'
      ) {
        if (!poliza[element]) {
          esNoNula = false;
        }
      }
    });

    return esNoNula;
  }

  calcularEstado(poliza) {
    if (poliza.planDePagos[poliza.planDePagos.length - 1].pagado === 'si') {
      return 'Paz y salvo';
    }

    if (poliza.estado == 'Pendiente Usuario') {
      return 'Pendiente usuario';
    }

    if (poliza.estado == 'Pendiente') {
      return 'Pendiente respuesta de pago';
    }

    if (poliza.estado == 'Revocado por mora') {
      return 'Revocado por mora';
    }

    if (poliza.estado == 'Vencida') {
      return 'Vencida';
    }

    if (poliza.estado == 'Cuota inicial pago rechazado') {
      return 'Cuota inicial pago rechazado';
    }

    if (poliza.estado == 'Cancelación voluntaria') {
      return 'Cancelación voluntaria';
    }

    if (this.enMora(poliza) > 0) {
      return 'Mora';
    }

    if (this.enMora(poliza) == 0) {
      return 'Al dia';
    }
  }

  enMora(poliza) {
    if (!poliza.cuotaInicialPagada) return 0;

    for (const cuota of poliza.planDePagos) {
      if (cuota.diasMora > 0 && cuota.pagado === 'no') return cuota.diasMora;
    }

    return 0;
  }
}
