import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { Options } from 'ng5-slider';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { MainService } from '../../../services/main.service';
import { AppToastService } from 'src/app/services/toast.service';
import { PolizaService } from 'src/app/services/poliza.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
//import { CloudinaryOptions, CloudinaryUploader } from "ng2-cloudinary";

@Component({
  selector: 'app-resumen-poliza-intermediario',
  templateUrl: './resumen-poliza-intermediario.component.html',
  styleUrls: ['./resumen-poliza-intermediario.component.scss'],
})
export class ResumenPolizaIntermediarioComponent implements OnInit {
  registerForm: FormGroup;
  public docPoliza: string = '';
  /** Si se cargó o no la póliza */
  public cargadoPoliza: boolean = false;
  /** Boolean que indica si se envió la OTP o no. Cuando es true, es porque hubo un error y se muestra el mensaje de error. */
  /** Celular para enviar la OTP y firmar el documento */
  public celularVerificacion: any;
  public loading: boolean = false;
  /** Resultado que envía el backend sobre la validación de la cédula */
  public validacionCedulaResultado: boolean = false;
  public user: any;
  public valorPoliza: Number;
  nombreaArchivo = 'Seleccionar archivo';
  /** Boolean que indica si se envió la OTP o no. Cuando es true, es porque hubo un error y se muestra el mensaje de error. */
  public otpEnviada: boolean = false;
  /** El mensaje que llega del backend después de validar la cédula */
  public validacionCedulaMensaje: string;
  preguntasRecorrer: any = [];
  preguntas: any = {};
  public paso: number = 1;
  maxValue: any;
  numeroPoliza: any;
  /*uploaderPoliza: CloudinaryUploader = new CloudinaryUploader(
      new CloudinaryOptions({ cloudName: "hh6guh7qh", uploadPreset: "wncq8grq" })
    );*/
  /** Es el código que ingresa el usuario para verificar */
  public codigoOtp: string = '';
  /** Se obtiene cuando se envía la OTP al celular del usuario. Se usa para verificar el código cuando el usuario lo ingrese */
  public identificadorOtp: string = '';
  muestra: string = 'password';

  archivo: any;
  bancos: any[];
  banco = 0;
  private widget: any = null;
  idUser: any;
  dataPoliza: any;
  userConectado: any;
  mostrarLogin: boolean = false;
  submitted: boolean = false;
  constructor(
    private authService: AuthService,
    private mainService: MainService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastService: AppToastService,
    private polizaService: PolizaService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.userConectado = this.authService.obtenerUsuarioActual();
    console.log('this.userConectado', this.userConectado);
    if (this.userConectado._id) {
      this.ejecutaPoliza();
    } else {
      this.mostrarLogin = true;
    }
  }
  ngOnInit(): void {
    this.registerForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        //username: ['', [Validators.required], this.customValidator.userNameValidator.bind(this.customValidator)],
        password: ['', Validators.compose([Validators.required])],
        //confirmPassword: ['', [Validators.required]],
        //password: ['', [Validators.required]],
      } /*,
      {
        validator: this.customValidator.MatchPassword('password', 'confirmPassword'),
      }*/
    );
  }

  togglePass() {
    if (this.muestra === 'password') {
      this.muestra = 'text';
      return true;
    } else {
      this.muestra = 'password';
      return true;
    }
  }
  ejecutaPoliza() {
    this.mostrarLogin = false;
    this.route.paramMap.subscribe(async (params) => {
      console.log(params);
      let poliza;
      poliza = params.get('poliza');
      console.log('poliza', poliza);
      if (poliza) {
        console.log('tengo poliza....');
        this.mainService
          .get(`polizaTempo/${poliza}`)
          .subscribe((result: any) => {
            console.log('result', result);
            if (result) {
              this.user = result[0].usuario;
              this.idUser = result[0].usuario._id;
              this.dataPoliza = result[0];
            } else {
              this.router.navigate(['/']);
            }
          });
      }
    });
  }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.valid) {
      this.spinner.show();

      //alert('Form Submitted succesfully!!!\n Check the values in browser console.');
      console.table(this.registerForm.value);
      let obj = this;
      this.authService.authenticateUser(this.registerForm.value).subscribe(
        (data) => {
          this.spinner.hide();
          if (data['success']) {
            data['user']._id = data['success'].id;
            ['success'];
            this.authService.settings.userType =
              data['user'].tipo.toLowerCase();

            this.authService.storeUserData(data['token'], data['user']);
            if (data['user'].tipo == 'Usuario') {
              this.ejecutaPoliza();
            } else {
              Swal.fire(
                '¡Error!',
                'El usuario no es de tipo comprador ni inversionista.',
                'warning'
              );
            }
            // })
          } else {
            Swal.fire(
              '¡Error!',
              'No se ha podido iniciar sesión. Por favor intente de nuevo.',
              'warning'
            );
          }
        },
        (err) => {
          this.spinner.hide();

          Swal.fire(
            '¡Error!',
            'No se ha podido iniciar sesión. Por favor intente de nuevo.',
            'warning'
          );
        }
      );
    }
  }
  get registerFormControl() {
    return this.registerForm.controls;
  }
  uploadPoliza() {
    this.spinner.show;
    this.widget.open();
  }

  traerBancosPSE() {
    this.valorPoliza = this.dataPoliza.cuotaInicial;
    console.log('valor poliza', this.valorPoliza);
    this.spinner.show();
    this.mainService.get('pagos/bancos', false).subscribe(
      (respuesta: any) => {
        if (respuesta && respuesta.success && respuesta.bancos) {
          this.spinner.hide();
          this.bancos = respuesta.bancos.data;
          this.bancos.shift();
        } else {
          this.spinner.hide();
          this.toastService.mostrar(
            'Error',
            'El pago por PSE no se encuentra disponible',
            'peligro'
          );
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  async pagar() {
    this.spinner.show();
    //let poliza = this.polizaService.getPolizaObjeto();
    let poliza = this.dataPoliza;
    delete poliza._id;
    let payload = {
      ...poliza,
      usuario: this.authService.obtenerUsuarioActual().id,
      estado: 'Pendiente',
      docPoliza: localStorage.getItem('docPoliza') || '',
      docPagare: localStorage.getItem('docPagare') || '',
      docAutorizacion: localStorage.getItem('docAutorizacion') || '',
      docInstrucciones: localStorage.getItem('docInstrucciones') || '',
      fechaPagoDeCuotaInicial: Date.now(),
    };
    console.log('payload', payload);
    if (payload.cuotaInicial) {
      let respuesta: any = await this.mainService.post('poliza/', payload);
      console.log('respuesta', respuesta);
      if (respuesta._id) {
        console.log('Tenemos id respuesta');
        await this.crearPagoPSE(respuesta._id, payload);
      } else {
        this.spinner.hide();
      }
    } else {
      this.spinner.hide();
    }
  }
  async crearPagoPSE(id, poliza) {
    console.log('poliza y id', id, poliza);

    let description =
      'Pago de la cuota inicial de la póliza ' +
      poliza.numeroPoliza +
      'con id FINSU' +
      id;
    const user = this.authService.obtenerUsuarioActual();
    let pse = {
      bank: this.banco,
      invoice: 'FINS-' + id,
      value: Math.round(poliza.cuotaInicial),
      tax: 0,
      description: description,
      tax_base: 0,
      currency: 'COP',
      type_person: '0',
      doc_type: user.tipoDocumento,
      email: user.email,
      doc_number: user.numeroDocumento,
      name: user.nombre,
      last_name: user.apellido,
      cell_phone: user.telefono,
      // ip: JSON.parse(localStorage.dispositivo),
    };
    console.log('pse::::', pse);
    let response: any = await this.mainService.post(
      'pagos/crearTransaccionPSE2',
      pse,
      false
    );
    if (response.success) {
      if (!response.payment.success) {
        this.spinner.hide();
        this.toastService.mostrar(
          'Error',
          'Error al realizar el pago por PSE',
          'peligro'
        );
      } else {
        this.crearTransaccion2(id, pse, response.payment.data, user);
      }
    }
  }
  async crearTransaccion2(id, payload, pse, user) {
    let transaccion = {
      usuario: this.idUser,
      poliza: id,
      concepto: payload.description,
      total: payload.valor,
      subtotal: payload.iva,
      tax: payload.baseiva,
      estado: 'Pendiente epayco',
      tipo: 'PSE',
      estadoEpayco: 'Pendiente',
      referenciaEpayco: pse.ref_payco,
      autorizacionPseEpayco: pse.autorizacion,
    };
    let result: any = await this.mainService.post('transaccion/', {
      usuario: this.idUser,
      poliza: id,
      concepto: payload.description,
      total: payload.value,
      subtotal: payload.tax_base,
      tax: payload.tax,
      estado: 'Pendiente epayco',
      tipo: 'PSE',
      estadoEpayco: 'Pendiente',
      referenciaEpayco: pse.ref_payco,
      autorizacionPseEpayco: pse.autorizacion,
    });
    if (result?.error) {
      this.spinner.hide();
      Swal.fire('¡Error!', 'Error. Por favor intenta de nuevo.', 'warning');
    } else {
      localStorage.setItem('idPSE', JSON.stringify(pse.ref_payco));
      localStorage.setItem('idTransaccion', JSON.stringify(result._id));
      localStorage.setItem('idPoliza', JSON.stringify(id));
      console.log('eooayco', pse.ref_payco);
      console.log('idTransaccion', result._id);
      console.log('poliza', id);
      // window.open(pse.urlbanco, '_self');
    }
  }

  public async actualizarTransaccion(pse, idTransaccion) {
    try {
      let transaccion = {
        referenciaEpayco: pse.ref_payco,
        autorizacionPseEpayco: pse.autorizacion,
      };

      let respuesta: any = await this.mainService.put(
        'transaccion/' + idTransaccion,
        transaccion
      );

      if (respuesta && respuesta._id) {
        return true;
      }
    } catch (err) {
      return false;
    }
  }

  public async psdValidarIdentidad() {
    this.spinner.show();
    this.loading = true;

    let primerApellido = this.user.apellido.split(' ');
    let json = {
      numeroIdentificacion: this.user.numeroDocumento,
      tipoIdentificacion: '1',
      nombres: this.user.nombre,
      primerApellido: primerApellido[0],
    };
    console.log('json', json);
    let result: any = await this.mainService.post(
      'poliza/psdValidarIdentidad/',
      json
    );
    if (result !== undefined) {
      this.spinner.hide();
      this.preguntas = result;

      if (!this.preguntas.success) {
        console.log('me quede aca');

        if (this.preguntas.result.payload.ValidarIdentidadResult) {
          this.spinner.hide();
          Swal.fire(
            `¡Error ${this.preguntas.result.payload.ValidarIdentidadResult['a:DescripcionRespuesta']}!`,
            this.preguntas.result.payload.ValidarIdentidadResult[
              'a:DescripcionRespuesta'
            ],
            'warning'
          );
          return false;
        } else {
          this.spinner.hide();
          Swal.fire(
            '¡Error!',
            'No fue posible generar las preguntas.',
            'warning'
          );
          return false;
        }
      }

      if (this.preguntas.preguntas['a:CodigoRespuesta'] == '01') {
        this.preguntasRecorrer =
          this.preguntas.preguntas['a:Preguntas']['b:Pregunta'];
      } else {
        this.spinner.hide();
        Swal.fire(
          `¡Error ${this.preguntas.preguntas['a:CodigoRespuesta']}!`,
          this.preguntas.preguntas['a:DescripcionRespuesta'],
          'warning'
        );
        return false;
      }
    }

    if (result?.error) {
      this.spinner.hide();
      Swal.fire(
        '¡Error!',
        'Error al enviar la OTP. Por favor intenta de nuevo.',
        'warning'
      );
    }
  }

  public verificarUsuario() {
    this.spinner.show();
    this.authService
      .post({
        api: 'api/poliza/verificarIdentidad/' + this.user.numeroDocumento,
      })
      .subscribe(
        (result) => {
          this.otpEnviada = true;
          // TODO actualizar el usuario con la cédula que ingrese aquí
          this.spinner.hide();
          //TODO:Quitar el true
          if (result['success']) {
            this.validacionCedulaResultado = result['cedulaValida'];
            this.validacionCedulaMensaje = result['msg'];
            this.paso = 2;
          } else {
            this.validacionCedulaResultado = false;
            this.validacionCedulaMensaje = ': No se pudo validar la cédula';
            Swal.fire(
              '¡Precaución!',
              'Sólo es permitido un intento diario. Por favor realizar nuevamente el proceso en 24 horas.',
              'warning'
            );
          }
        },
        (err) => {
          this.spinner.hide();
          this.otpEnviada = true;
        }
      );
  }
  vistaPago() {
    this.paso = 5;
    this.traerBancosPSE();
  }

  /**
   * Método para seleccionar respuestas
   * @param event
   * @param i
   * @param j
   */
  changeRadio(event, i, j) {
    for (let r of this.preguntasRecorrer[i]['b:OpcionesRespuesta'][
      'b:OpcionRespuesta'
    ]) {
      r.sel = false;
    }
    this.preguntasRecorrer[i]['b:OpcionesRespuesta']['b:OpcionRespuesta'][
      j
    ].sel = true;
  }

  // Método para validar que haya respondido todas las respuestas y contruir el json para enivar la info
  chequearRespuestas() {
    let json = {
      IdCuestionario: this.preguntas.preguntas['a:IdCuestionario'],
      RegistroCuestionario: this.preguntas.preguntas['a:RegistroCuestionario'],
      NumeroIdentificacion: this.user.numeroDocumento,
      Respuestas: [],
    };

    for (let p of this.preguntasRecorrer) {
      if (p['b:OpcionesRespuesta']) {
        for (let r of p['b:OpcionesRespuesta']['b:OpcionRespuesta']) {
          if (r.sel && r.sel === true) {
            let enviar = {
              IdPregunta: p['b:IdPregunta'],
              IdRespuesta: r['b:IdRespuesta'],
            };
            json.Respuestas.push(enviar);
          }
        }
      }
    }

    if (this.preguntasRecorrer.length === json.Respuestas.length) {
      this.enviarRespuestas(json);
    } else {
      Swal.fire(
        '¡Error!',
        'Debes responder todas las preguntas para poder continuar.',
        'warning'
      );
    }
  }

  enviarRespuestas(enviar) {
    // this.spinner.show();
    // this.loading = true;
    this.mainService.post('poliza/psdEnviarRespuestas', enviar).then(
      (result) => {
        console.log('respuestas a verificar son correctas?', result);
        // this.loading = false;
        if (result['data']['a:Resultado'] === 'false') {
          Swal.fire(
            '¡Error!',
            'No se pudo hacer el proceso de verificación, vuelve a intentarlo. ',
            'warning'
          );
        }
        if (
          result['data']['a:CodigoRespuesta'] === '01' &&
          result['data']['a:DescripcionRespuesta'] === 'No aprobado'
        ) {
          Swal.fire(
            '¡Error!',
            'Las respuestas son incorrectas, vuelve a intentarlo.',
            'warning'
          );
        } else if (
          result['data']['a:CodigoRespuesta'] === '00' &&
          result['data']['a:DescripcionRespuesta'] === 'Aprobado'
        ) {
          console.log(result['a:CodigoRespuesta']);
          this.validacionCedulaResultado = result['cedulaValida'];
          this.validacionCedulaMensaje = result['msg'];
          this.paso = 2;
        } else {
          Swal.fire(
            '¡Error!',
            'Las respuestas son incorrectas, vuelve a intentarlo. ',
            'warning'
          );
        }
      },
      (err) => {
        this.loading = false;
        Swal.fire(
          '¡Error!',
          'Ocurrio un error al enviar las respuestas, intentalo de nuevo más tarde.',
          'warning'
        );
      }
    );
  }

  //  * Método para enviar otp
  public async enviarOtp() {
    this.spinner.show();
    let payload = {
      correo: this.user.email,
      celular: this.celularVerificacion,
    };
    let result: any = await this.mainService.post('poliza/enviarOtp/', {
      correo: this.user.email,
      celular: this.celularVerificacion,
    });
    if (result?.error) {
      this.spinner.hide();
      Swal.fire(
        '¡Error!',
        'Error al enviar la OTP. Por favor intenta de nuevo.',
        'warning'
      );
    } else {
      this.spinner.hide();
      this.identificadorOtp = result['identificador'];
      this.paso = 3;
      Swal.fire(
        '¡Éxito!',
        'Código de verificación enviado a tu celular y correo.',
        'success'
      );
    }
  }

  /**
   * Método para firmar el documento
   */
  public async firmarDocumento() {
    this.spinner.show();
    if (this.codigoOtp === '') {
      this.spinner.hide();
      Swal.fire(
        '¡Error!',
        'Debe ingresar el código que ha recibido en tu celular y correo.',
        'warning'
      );
      return false;
    } else {
      this.firmaElectronica();
    }
  }

  public async firmaElectronica() {
    let payload = {
      identificador: this.identificadorOtp,
      otp: this.codigoOtp,
      nombreUsuario: `${this.user.nombre} ${this.user.apellido}`,
      correoUsuario: this.user.email,
      valor_capital: this.maxValue,
      interes_corriente: ' ',
      interes_mora: ' ',
      suscripcion_dia: moment().format('DD'),
      suscripcion_año: moment().format('YY'),
      suscripcion_mes: moment().format('MM'),
      vencimiento_dia: moment().add(1, 'year').format('DD'),
      vencimiento_mes: moment().add(1, 'year').format('MM'),
      vencimiento_año: moment().add(1, 'year').format('YY'),
      lugar_obligacion: 'Bogotá',
      pagare_nu: this.numeroPoliza,
      pagare_orden_nu: ' ',
      suscripcion_ciudad: 'Bogotá',
      suscripcion_dias: moment().format('DD'),
      firma_deudor: `${this.user.nombre} ${this.user.apellido}`,
      firma_aval: ' ',
      deudor_nombre: `${this.user.nombre} ${this.user.apellido}`,
      deudor_cc_nit: this.user.numeroDocumento,
      deudor_tipo_persona: this.user.tipoDocumento,
      deudor_direccion: ' ',
      deudor_ciudad: 'Bogotá',
      deudor_telefono: this.celularVerificacion,
      aval_nombre: ' ',
      aval_cc_nit: ' ',
      deudor_representante: ' ',
      aval_tipo_persona: ' ',
      aval_direccion: ' ',
      aval_ciudad: ' ',
      aval_telefono: ' ',
      paguese_a: ' ',
      paguese_a_cc_nit: ' ',
      firma_endoso: ' ',
      endoso_nombre: ' ',
    };
    console.log('payload', payload);
    let result: any = await this.mainService.post(
      'poliza/firmarDocumentoNew/',
      {
        payload,
      }
    );
    if (result['success']) {
      this.spinner.hide();
      Swal.fire('¡Éxito!', result['msg'], 'success');
      // this.onGoToPago();
      this.paso = 5;
      this.traerBancosPSE();
    } else {
      this.spinner.hide();
      Swal.fire('¡Error!', result['msg'], 'warning');
    }
  }
}
