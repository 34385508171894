<div class="row margin-row">
  <div class="col-3 d-none d-md-none d-lg-block fondo-menu">
    <div class="inciales">
      <p class="letras">{{ user.nombre[0] }} {{ user.apellido[0] }}</p>
    </div>
    <div class="nombre">{{ user.nombre }} {{ user.apellido }}</div>
    <div class="padding-div">
      <div
        class="text-no-activo"
        (click)="seleccionarMenu('resumen')"
        [ngClass]="{ 'text-activo': menu === 'resumen' }"
      >
        <span>Resumen</span>
        <img
          class="flecha"
          [hidden]="menu !== 'resumen'"
          src="assets/recursos/logos-iconos/right-arrow (1).png"
          alt="Imagen flecha"
        />
      </div>
      <div
        class="text-no-activo"
        (click)="seleccionarMenu('perfil')"
        [ngClass]="{ 'text-activo': menu === 'perfil' }"
      >
        Perfil
        <img
          class="flecha"
          [hidden]="menu !== 'perfil'"
          src="assets/recursos/logos-iconos/right-arrow (1).png"
          alt="Imagen flecha"
        />
      </div>
      <div
        class="text-no-activo"
        (click)="seleccionarMenu('financiaciones')"
        [ngClass]="{ 'text-activo': menu === 'financiaciones' }"
      >
        Financiaciones
        <img
          class="flecha"
          [hidden]="menu !== 'financiaciones'"
          src="assets/recursos/logos-iconos/right-arrow (1).png"
          alt="Imagen flecha"
        />
      </div>
      <div
        class="text-no-activo"
        (click)="seleccionarMenu('pqr')"
        [ngClass]="{ 'text-activo': menu === 'pqr' }"
      >
        PQR's
        <img
          class="flecha"
          [hidden]="menu !== 'pqr'"
          src="assets/recursos/logos-iconos/right-arrow (1).png"
          alt="Imagen flecha"
        />
      </div>

      <div class="text-no-activo" (click)="cerrar()">Cerrar sesión</div>
    </div>
  </div>
  <div class="col-12 col-md-12 col-lg-9 padding-div2">
    <div *ngIf="menu === 'resumen'">
      <app-resumen></app-resumen>
    </div>
    <div *ngIf="menu === 'perfil'">
      <app-perfil></app-perfil>
    </div>
    <div *ngIf="menu === 'financiaciones'">
      <app-financiaciones></app-financiaciones>
    </div>
    <div *ngIf="menu === 'pqr'">
      <app-pqr></app-pqr>
    </div>
  </div>
</div>
