import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UtilidadesService } from '../../../services/utilidades.service';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormatterDataService } from 'src/app/services/formatter-data.service';
import { ValidateDataService } from 'src/app/services/validate-data.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioComponent } from '../../usuario/usuario.component';
import { cities } from '../../../cities.js';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-crear-cuenta',
  templateUrl: './crear-cuenta-cliente.component.html',
  styleUrls: ['./crear-cuenta-cliente.component.scss'],
})
export class CrearCuentaClienteComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  acepta: boolean = false;
  terminos: boolean = false;
  nombre: string;
  apellido: string;
  direccion: string;
  ciudad: string;
  email: string;
  //Paso 1 del wizard: corresponde a la primera secciòn del formulario
  paso1: boolean = true;
  //Paso 2 del wizard: corresponde a la segunda secciòn del formulario
  paso2: boolean = false;
  muestra: string = 'password';
  muestraCo: string = 'password';
  samePasswords: boolean = false;
  tipoUser: string;
  citiesList: any;
  listsDocumento = ['Tipo de documento', 'CC', 'CE', 'NIT', 'PASAPORTE'];
  bloqueaEmail: boolean = false;
  muestrarFrm: boolean;

  routeChanged = new Subject<any>();

  validatedData = {};

  constructor(
    private fb: FormBuilder,
    private customValidator: UtilidadesService,
    private authService: AuthService,
    private formatterDataService: FormatterDataService,
    private validateDataService: ValidateDataService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private mainService: MainService,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this.validatedData = this.validateDataService.getValidatedData();
  }

  // cycles

  ngOnInit(): void {
    if (this.router.url.includes('intermediario'))
      this.tipoUser = 'Intermediario';
    else this.tipoUser = 'Usuario';

    this.routeChanged
      .pipe(debounceTime(3000))
      .subscribe(() => this.getRouteParams());
    this.onFilterCities();
    this.registerForm = this.fb.group(
      {
        nombre: ['', [Validators.required]],
        apellido: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          Validators.compose([
            Validators.required,
            this.customValidator.patternValidator(),
          ]),
        ],
        confirmPassword: [
          '',
          Validators.compose([
            Validators.required,
            this.customValidator.patternValidator(),
          ]),
        ],
        telefono: ['', [Validators.required]],
        tipoPersona: ['', [Validators.required]],
        razonSocial: [''],
        tipoDocumento: ['', [Validators.required]],
        numeroDocumento: ['', [Validators.required]],
        codigoVerificacion: [''],
        ciudad: ['', [Validators.required]],
        direccion: ['', [Validators.required]],
        tipo: [''],
        //password: ['', [Validators.required]],
      } /*,
      {
        validator: this.customValidator.MatchPassword('password', 'confirmPassword'),
      }*/
    );
  }

  ngAfterViewChecked(): void {
    this.routeChanged.next();
  }

  // component methods

  formatterInput(formatType: string, dataToFormat: string) {
    let data = this.registerForm.value[dataToFormat];
    data = this.formatterDataService[formatType](data);

    if (dataToFormat !== 'codigoVerificacion')
      data = this.formatterDataService.shortNumber(data);
    if (dataToFormat === 'numeroDocumento') this.validateLengthDocument();

    this.registerForm.get(dataToFormat).setValue(data);
  }

  getRouteParams() {
    this.route.paramMap.subscribe((params) => {
      let parametro = params.get('tipo');
      if (parametro === 'intermediario') {
        this.tipoUser = 'Intermediario';
      } else {
        this.tipoUser = 'Usuario';
      }
      if (params.has('email')) {
        this.bloqueaEmail = true;
        let email = params.get('email');
        this.registerForm.value.email = email.trim();
        this.email = email.trim();
        this.mainService.get(`invitado/${email}`).subscribe((result: any) => {
          console.log('resultado get email....', result);
          if (result.success) {
            this.registerForm.value.nombre = result.msg.nombre;
            this.registerForm.value.apellido = result.msg.apellido;
            this.nombre = result.msg.nombre;
            this.apellido = result.msg.apellido;
            this.email = result.msg.email;
          } else {
            this.router.navigate(['/']);
          }
        });
      }
    });
    this.muestrarFrm = true;
  }

  get registerFormControl() {
    return this.registerForm.controls;
  }

  togglePass(whichPassword = 'muestra') {
    if (this[whichPassword] === 'password') {
      this[whichPassword] = 'text';
      return true;
    } else {
      this[whichPassword] = 'password';
      return true;
    }
  }

  async esCorreoNuevo() {
    return new Promise((resolve, reject) => {
      this.mainService
        .get(`user/email/${this.registerForm.value.email}`)
        .subscribe((respuesta) => {
          console.log('Voy a imprimir respuesta ');
          console.log(respuesta);
          if (respuesta == true) {
            reject(false);
          } else {
            resolve(true);
          }
        });
    });
  }

  public onFilterCities() {
    this.citiesList = cities.sort();
  }

  public onMostrarModal(content) {
    this.modalService.open(content, { size: 'lg', centered: true });
    setTimeout(() => {
      let element = document.getElementById('groupModal');
      element.scrollIntoView(true);
    }, 200);
  }

  public onCloseModal() {
    this.modalService.dismissAll();
  }

  async siguiente() {
    if (
      this.registerForm.value.nombre &&
      this.registerForm.value.apellido &&
      this.registerForm.value.email &&
      this.registerForm.value.direccion &&
      this.registerForm.value.ciudad &&
      this.registerForm.value.password &&
      this.mainService.validarEmail(this.registerForm.value.email) &&
      this.validarContrasena(this.registerForm.value.password)
    ) {
      this.paso2 = true;
      this.paso1 = false;
    } else {
      Swal.fire('', 'Hay campos vacíos o inválidos.', 'warning');
    }

    /*
return false
    if (
      this.registerForm.value.nombre === '' ||
      this.registerForm.value.apellido === '' ||
      this.registerForm.value.email === '' ||
      this.registerForm.value.password === ''
    ) {
      Swal.fire('¡Error!', 'Debe diligenciar todos los campos', 'warning');
    } else {
      let esNuevo = await this.esCorreoNuevo().then(
        (loEs) => loEs,
        (noLoEs) => noLoEs
      );

      console.log('Voy a imprimir variable');
      console.log(esNuevo);
      if (esNuevo) {
        this.paso2 = true;
        this.paso1 = false;
      } else {
        Swal.fire('¡Error!', 'El correo ingresado ya está en uso', 'warning');
      }
    }*/
  }

  async onSubmit() {
    if (this.validateAllForm()) {
      this.submitted = true;
      this.registerForm.value.tipo = this.tipoUser;
      let obj = this;

      this.authService.register(this.registerForm.value).subscribe(
        (res) => {
          if (res['success']) {
            let obj = this;
            let login = {
              email: this.registerForm.value.email,
              password: this.registerForm.value.password,
            };
            this.authService.authenticateUser(login).subscribe(
              (data) => {
                this.spinner.hide();

                if (data['success']) {
                  this.spinner.hide();
                  data['user']._id = data['success'].id;
                  ['success'];
                  obj.authService.storeUserData(data['token'], data['user']);
                  obj.authService.settings.userType =
                    data['user'].tipo.toLowerCase();
                  if (data['user'].tipo == 'Intermediario') {
                    Swal.fire(
                      '¡Éxito!',
                      'Se ha iniciado sesión con éxito.',
                      'success'
                    );
                    obj.router.navigate(['intermediario']);
                  } else if (data['user'].tipo == 'Usuario') {
                    Swal.fire(
                      '¡Éxito!',
                      'Se ha iniciado sesión con éxito.',
                      'success'
                    );
                    obj.router.navigate(['usuario']);
                  } else {
                    Swal.fire(
                      '¡Error!',
                      'El usuario no es de tipo comprador ni inversionista.',
                      'warning'
                    );
                  }
                  // })
                } else {
                  this.spinner.hide();
                  Swal.fire(
                    '¡Error!',
                    'No se ha podido crear cuenta.',
                    'warning'
                  );
                }
              },
              (err) => {
                this.spinner.hide();

                Swal.fire(
                  '¡Error!',
                  'No se ha podido iniciar sesión. Por favor intente de nuevo.',
                  'warning'
                );
              }
            );
            // })
          } else {
            this.spinner.hide();
            Swal.fire('¡Error!', `${res['msg']}.`, 'warning');
          }
        },
        (err) => {
          Swal.fire('¡Error!', `${err.msg}.`, 'warning');
        }
      );
    }
  }

  // validations

  validateAllForm(): Boolean {
    this.spinner.show();
    this.registerForm.value.email = this.registerForm.value.email
      .toLowerCase()
      .trim();

    if (
      this.registerForm.value.password !==
      this.registerForm.value.confirmPassword
    ) {
      Swal.fire('¡Error!', 'Las contraseñas no son iguales.', 'warning');
      return false;
    }

    if (this.acepta === false) {
      this.spinner.hide();
      Swal.fire(
        '¡¡Error!!',
        'Debe aceptar los términos y condiciones para el registro.',
        'warning'
      );
      return false;
    }

    if (this.terminos === false) {
      this.spinner.hide();
      Swal.fire(
        '¡¡Error!!',
        'Debe aceptar los términos de tratamiento de datos para el registro',
        'warning'
      );
      return false;
    }

    const validandoEmail = this.validar_email(this.registerForm.value.email);
    if (!validandoEmail) {
      Swal.fire(
        '!¡',
        'El email: ' + this.registerForm.value.email + ' es inválido.',
        'warning'
      );
      this.spinner.hide();
      return false;
    }

    if (!this.esNumeroDeTelefono()) {
      this.spinner.hide();
      Swal.fire('¡Error!', 'El número de teléfono no es válido.', 'warning');
      return false;
    }

    if (!this.esDocumento()) {
      this.spinner.hide();
      Swal.fire(
        '¡Error!',
        'El número de documento o NIT no es válido.',
        'warning'
      );
      return false;
    }

    if (!this.registerForm.valid) {
      this.spinner.hide();
      Swal.fire('¡Error!', 'Debe dilingenciar todos los datos.', 'warning');
      return false;
    }
    return true;
  }

  validar_email(email: string) {
    const data = email.split(' ');
    if (data.length > 1) {
      Swal.fire(
        '¡Error!',
        'El email: ' + email + ' contiene espacios en blanco.',
        'warning'
      );
      return false;
    } else {
      const regex =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email) ? true : false;
    }
  }

  validateLengthDocument() {
    const document = this.registerForm.value.numeroDocumento;
    this.validateDataService.documentCC(document);
  }

  esDocumento() {
    let documento = this.registerForm.value.numeroDocumento;
    return /^-?\d+$/.test(documento);
  }

  esNumeroDeTelefono() {
    let telefono = this.registerForm.value.telefono;
    return /^-?\d+$/.test(telefono) && String(telefono).length == 10;
  }

  validarContrasena(contrasenna) {
    if (contrasenna.length >= 6) {
      var letra = false;
      var numero = false;
      //var caracter_raro = false;

      for (var i = 0; i < contrasenna.length; i++) {
        if (
          contrasenna.charCodeAt(i) >= 65 &&
          contrasenna.charCodeAt(i) <= 122
        ) {
          letra = true;
        } else if (
          contrasenna.charCodeAt(i) >= 48 &&
          contrasenna.charCodeAt(i) <= 57
        ) {
          numero = true;
        } else {
          // caracter_raro = true;
        }
      }
      if (
        letra == true &&
        //  caracter_raro == true &&
        numero == true
      ) {
        return true;
      }
    }
    return false;
  }

  samePassword() {
    if (
      this.registerForm.value.password ===
      this.registerForm.value.confirmPassword
    )
      return (this.samePasswords = true);
    this.samePasswords = false;
  }
}
