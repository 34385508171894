<div class="row margin-row">
  <div class="col-md-12">
    <p class="title-principal">Características</p>
  </div>
  <div class="col-md-3 d-md-none d-lg-block"></div>
  <div class="col-lg-3 col-md-6 card-carne">
    <img
      class="imagen"
      width="80%"
      src="assets/recursos/financiar/computador.png"
      alt="Imagen computador"
    />
  </div>
  <div class="col-lg-3 col-md-6 card-white">
    <p class="title">
      Digital <br />
      <label class="text"
        >Todo el proceso desde tu computador o celular, sin papeleo ni hacer
        filas.</label
      >
    </p>
  </div>
  <div class="col-md-3 d-md-none d-lg-block"></div>
  <div class="col-md-3 d-md-none d-lg-block"></div>
  <div class="col-lg-3 col-md-6 card-carne2 text-center d-block d-md-none">
    <img
      class="imagen"
      width="70%"
      src="assets/recursos/financiar/reloj.png"
      alt="Imagen reloj"
    />
  </div>
  <div class="col-lg-3 col-md-6 card-white2">
    <p class="title">
      Rápido y fácil <br />
      <label class="text"
        >En minutos y con solo unos cuantos clics inicias tu
        financiación.</label
      >
    </p>
  </div>
  <div class="col-lg-3 col-md-6 card-carne2 text-center d-none d-md-block">
    <img
      class="imagen"
      width="70%"
      src="assets/recursos/financiar/reloj.png"
      alt="Imagen reloj"
    />
  </div>
  <div class="col-md-3 d-md-none d-lg-block"></div>

  <div class="col-md-3 d-md-none d-lg-block"></div>
  <div class="col-lg-3 col-md-6 card-carne3">
    <img
      class="imagen"
      width="70%"
      src="assets/recursos/financiar/ckeck.png"
      alt="Imagen check"
    />
  </div>
  <div class="col-lg-3 col-md-6 card-white2">
    <p class="title">
      Facilidad de pago <br />
      <label class="text"
        >Paga online a cuotas y además con una baja tasa de interés.</label
      >
    </p>
  </div>
  <div class="col-md-3 d-md-none d-lg-block"></div>
  <div class="col-md-3 d-md-none d-lg-block"></div>
  <div class="col-lg-3 col-md-6 card-carne2 text-center d-block d-md-none">
    <img
      class="imagen"
      width="70%"
      src="assets/recursos/financiar/barras-inversion.svg"
      alt="Imagen barrar"
    />
  </div>
  <div class="col-lg-3 col-md-6 card-white2">
    <p class="title">
      Inversión de bajo riesgo <br />
      <label class="text"
        >En caso de mora en el pago de las cuotas, será cancelada la póliza y se
        devolverá el saldo al inversionista.</label
      >
    </p>
  </div>
  <div class="col-lg-3 col-md-6 card-carne2 text-center d-none d-md-block">
    <img
      class="imagen"
      width="70%"
      src="assets/recursos/financiar/barras-inversion.svg"
      alt="Imagen barrar"
    />
  </div>
  <div class="col-md-3 d-md-none d-lg-block"></div>
  <div class="col-md-3 d-md-none d-lg-block"></div>
  <div class="col-lg-3 col-md-6 card-carne4">
    <img
      class="imagen"
      width="60%"
      src="assets/recursos/financiar/mejor-retorno.svg"
      alt="Imagen retorno"
    />
  </div>
  <div class="col-lg-3 col-md-6 card-white3">
    <p class="title">
      Mejor retorno <br />
      <label class="text"
        >Ofrecemos 1% mensual sobre el saldo capital invertido</label
      >
    </p>
  </div>
  <div class="col-md-3 d-md-none d-lg-block"></div>
</div>
