import { Component, OnInit } from '@angular/core';
import { AppToastService } from 'src/app/services/toast.service';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { timestamp } from 'rxjs/operators';
@Component({
  selector: 'app-perfil-intermediario',
  templateUrl: './perfil-intermediario.component.html',
  styleUrls: ['./perfil-intermediario.component.scss'],
})
export class PerfilIntermediarioComponent implements OnInit {
  datos: any = {};
  id = '';
  documento = '';
  errorNueva = '';
  mostrarAntigua = 'password';
  mostrarNueva = 'password';
  errorAct = '';
  mostrar = false;
  contrasenaAntigua: string;
  contrasenaNueva: string;
  tipoDocumento = {
    CC: 'Cédula de ciudadanía ',
    CE: 'Cédula de extranjería',
    NIT: 'NIT',
    PASAPORTE: 'Pasaporte'};
  direccion = '';
  ciudad = '';



  constructor(
    private toastService: AppToastService,
    private mainService: MainService,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.datos = JSON.parse(localStorage.getItem('usuario'));
    this.id = this.datos.id;
    this.spinner.show();
    this.mainService.get(`user/${this.datos.id}`).subscribe((result) => {
      console.log('Este es el resultado:', result);
      this.datos = result;
      this.spinner.hide();
    });

    this.documento = this.tipoDocumento[this.datos.tipoDocumento];
  }

  cambiarInputAntigua() {
    this.mostrarAntigua =
      this.mostrarAntigua == 'password' ? 'text' : 'password';
  }

  cambiarInputNueva() {
    this.mostrarNueva = this.mostrarNueva == 'password' ? 'text' : 'password';
  }

  cerrarModal() {
    this.mostrar = false;
  }

  manejarCambioContrasena() {
    this.errorNueva = '';
    this.errorAct = '';
    if (!this.mainService.esInputNoVacio(this.contrasenaNueva)) {
      this.errorNueva = 'El campo esta vácio';
    } else if (
      this.mainService.verificarPatronContrasena(this.contrasenaNueva)
    ) {
      this.authService
        .verificarContraseña(this.datos.email, this.contrasenaAntigua)
        .subscribe((esCorrecta: any) => {
          if (esCorrecta.success) {
            this.authService
              .cambiarContrasena(this.datos.email, this.contrasenaNueva)
              .subscribe((response: any) => {
                if (response.success) {
                  this.cerrarModal();
                  this.toastService.mostrar(
                    'Éxito',
                    'Se ha cambiado la contraseña',
                    'exito'
                  );
                }
              });
          } else {
            this.errorAct = 'La contraseña no concuerda';
          }
        });
    } else {
      this.errorNueva =
        'La contraseña no es alfanumérica o no tiene más de 8 caracteres';
    }
  }

  mostrarModal() {
    this.mostrar = true;
  }

  manejarFormulario() {
    if (!this.datos.nombre || !this.datos.apellido || !this.datos.telefono) {
      this.toastService.mostrar(
        'Error',
        'Hay campos vacíos o inválidos',
        'advertencia'
      );
    } else if (isNaN(this.datos.telefono) || this.datos.telefono.length < 10) {
      this.toastService.mostrar(
        'Error',
        'El número celular es inválido',
        'advertencia'
      );
    } else {
      this.spinner.show();
      this.mainService
        .put(`user/${this.id}`, {
          telefono: this.datos.telefono,
          nombre: this.datos.nombre,
          apellido: this.datos.apellido,
        })
        .then((result) => {
          console.log('Este es el resultado', result);
          this.toastService.mostrar(
            'Éxito',
            'Datos cambiados satisfactoriamente',
            'exito'
          );

          this.authService.cambiarNombreAct(this.datos.nombre);
          this.authService.cambiarApellidoAct(this.datos.apellido);

          this.spinner.hide();
          this.ngOnInit();
        })
        .catch((error) => {
          console.log('Este es el error', error);
          this.toastService.mostrar(
            'Error',
            'No se pudieron cambiar los datos. Intentalo más tarde',
            'peligro'
          );
          this.spinner.hide();
        });
    }
  }

  getInContact(){
    sessionStorage.setItem('getContact', 'true')
  }
}
