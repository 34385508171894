import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pago-pse',
  templateUrl: './pago-pse.component.html',
  styleUrls: ['./pago-pse.component.scss']
})
export class PagoPseComponent implements OnInit {
  @Input() valorPagar: any;
  @Input() poliza: any;
  @Input() numeroPoliza: any;
  @Input() valorCuotaInicial: any;
  @Output() public volver = new EventEmitter();
  @Input() cuotasPagar: any;
  @Input() user: any;
  @Input() saldo: any;
  loading: boolean = false;
  banco: number = 0;
  bancos: any = [];
  descripcionPago: string = "";
  customLoadingTemplate;


  constructor(public router: Router, private mainService: MainService, private spinner: NgxSpinnerService,) {
    this.user = JSON.parse(localStorage.getItem('usuario'));
    console.log("userrr", this.user)
  }

  ngOnInit(): void {
    console.log(this.poliza, "poliza...")
    this.traerBancosPse();
    if (
      this.poliza._id &&
      this.poliza.estado == "Cuota inicial pago rechazado"
    ) {
      this.numeroPoliza = this.poliza.numeroPoliza;
      this.valorCuotaInicial = this.poliza.cuotaInicial;
    }
    console.log(this.poliza, "pol")
  }

  traerBancosPse() {
    this.loading = true;
    console.log("result bancos", this.loading)
    this.mainService.get("pagos/bancos", false).subscribe(
      (result: any) => {
        console.log("result bancos", result)
        if (result && result.success && result.bancos) {
          this.loading = false;
          this.bancos = result.bancos.data;
        } else {
          this.loading = false;
          this.volver.emit(true);
          Swal.fire(
            "¡Error!",
            "El pago con PSE no se encuentra disponible en estos momentos, intenta de nuevo más tarde.",
            "warning"
          );
        }
      },
      (err) => {
        this.loading = false;
        this.volver.emit(true);
        Swal.fire(
          "¡Error!",
          "El pago con PSE no se encuentra disponible en estos momentos, intenta de nuevo más tarde.",
          "warning"
        );
      }
    );
  }

  public crearTransaccion() {
    if (this.valorPagar > 0) {
      if (this.banco !== 0) {
        if (this.verificarPagoCuotas()) {
          this.loading = true;
          let impuesto = Math.round(this.valorPagar * 0.19);
          let subtotal = Math.round(this.valorPagar - impuesto);
          this.descripcionPago =
            "Pago de " +
            this.cuotasPagar +
            " cuota(s) " +
            this.poliza.numeroPagare +
            " con id FINSU " +
            this.poliza._id;
          let transaccion = {
            usuario: this.user.id,
            poliza: this.poliza._id,
            concepto: this.descripcionPago,
            total: this.valorPagar,
            subtotal: 0,
            impuesto: 0,
            estado: "Pendiente epayco",
            tipo: "PSE",
            estadoEpayco: "Pendiente",
          };
          console.log("trans...", this.poliza.numeroPagare )
          this.mainService.post("transaccion", transaccion).then(
            (resultTransaccion: any) => {
              console.log("id", resultTransaccion)
              this.crearPagoPSE(resultTransaccion._id);
            },
            (err) => {
              this.loading = false;
              Swal.fire("¡Error!", "Error al realizar pago con PSE.", "warning");
            }
          );
        } else {
          Swal.fire("¡Error!", "El pago excede el saldo del crédito.", "warning");
        }
      } else {
        Swal.fire(
          "¡Error!",
          "Debes seleccionar el banco para poder continuar.",
          "warning"
        );
      }
    } else {
      Swal.fire(
        "¡Error!",
        "El monto del pago debe ser mayor a 0, intentalo de nuevo.",
        "warning"
      );
    }
  }


  public verificarPagoCuotas() {
    let ret = false;
    if (this.valorPagar > this.saldo) {
      ret = false;
    } else {
      ret = true;
    }
    return ret;
  }


  crearPagoPSE(id) {
    this.loading = true;
    let description = "";
    let value = 0;
    if (this.descripcionPago && this.descripcionPago !== "") {
      description = this.descripcionPago;
      value = this.valorPagar;
    } else {
      description =
        "Pago de cuota inicial de la póliza " +
        this.numeroPoliza +
        " con id FINSU " +
        id;
      value = this.valorCuotaInicial;
    }
    //(subtotal = Math.round(subtotal)),
    //(impuesto = Math.round(impuesto)),
    //(value = subtotal + impuesto);
    var pse = {
      bank: this.banco,
      invoice: "FINS-" + id,
      description: description,
      value: Math.round(value),
      tax: 0,
      tax_base: 0,
      currency: "COP",
      type_person: "0",
      doc_type: this.user.tipoDocumento,
      doc_number: this.user.numeroDocumento,
      name: this.user.nombre,
      last_name: this.user.apellido,
      email: this.user.email,
      cell_phone: this.user.telefono,
    };
    console.log("pse", pse)
    this.mainService.post("pagos/crearTransaccionPSE/", pse, false).then(
      async (res: any) => {
        console.log("res..", res)
        if (res.success) {
          if (!res.payment.success) {
            this.loading = false;
            Swal.fire("¡Error!", "Error al realizar pago con PSE.", "warning");
          } else {
            if (
              this.poliza == true ||
              (this.poliza._id &&
                this.poliza.estado &&
                this.poliza.estado == "Cuota inicial pago rechazado")
            ) {
              this.crearTransaccion2(id, pse, res.payment.data);
              if (this.poliza.estado == "Cuota inicial pago rechazado") {
                this.actualizarPoliza(id);
              }
            } else {
              this.loading = false;
              let transaccionActualizada = await this.actualizarTransaccion(
                res.payment.data,
                id
              );
              if (transaccionActualizada) {
                localStorage.setItem(
                  "idPSE",
                  JSON.stringify(res.payment.data.ref_payco)
                );
                localStorage.setItem("idTransaccion", JSON.stringify(id));
                // this.localService.removeItemToken("plan");
                //Aqui abrir el navegador

                window.open(res.payment.data.urlbanco, "_self");
              } else {
                Swal.fire(
                  "¡Error!",
                  "Error al actualizar transacción.",
                  "warning"
                );
              }
            }
          }
        } else {
          this.loading = false;
          Swal.fire("¡Error!", "Error al realizar pago con PSE.", "warning");
        }
      },
      (error) => {
        this.loading = false;
        Swal.fire("¡Error!", "Error al realizar pago con PSE.", "warning");
      }
    );
  }

  crearTransaccion2(id, payload, pse) {
    let transaccion = {
      usuario: this.user._id,
      poliza: id,
      concepto: payload.description,
      total: payload.value,
      subtotal: payload.tax_base,
      tax: payload.tax,
      estado: "Pendiente epayco",
      tipo: "PSE",
      estadoEpayco: "Pendiente",
      referenciaEpayco: pse.ref_payco,
      autorizacionPseEpayco: pse.autorizacion,
    };
    this.mainService
      .post("api/transaccion", transaccion)
      .then((resultTransaccion: any) => {
        this.loading = false;
        localStorage.setItem("idPSE", JSON.stringify(pse.ref_payco));
        localStorage.setItem(
          "idTransaccion",
          JSON.stringify(resultTransaccion._id)
        );
        localStorage.setItem("idPoliza", JSON.stringify(id));
        // this.localService.removeItemToken("plan");
        //Aqui abrir el navegador

        window.open(pse.urlbanco, "_self");
      });
  }

  public actualizarPoliza(idPoliza) {
    let json = {
      fechaPagoDeCuotaInicial: Date.now(),
    };
    this.mainService.put("poliza/" + idPoliza, json).then(
      (result) => {},
      (err) => {}
    );
  }

  public actualizarTransaccion(pse, idTransaccion) {
    return new Promise((success, reject) => {
      let transaccion = {
        referenciaEpayco: pse.ref_payco,
        autorizacionPseEpayco: pse.autorizacion,
      };
      this.mainService
        .put("transaccion/" + idTransaccion, transaccion)
        .then(
          (resultTransaccion: any) => {
            if (resultTransaccion && resultTransaccion._id) {
              success(true);
            } else {
              reject(false);
            }
          },
          (err) => {
            reject(false);
          }
        );
    });
  }

}
