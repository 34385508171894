import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';
import _ from 'lodash';
@Component({
  selector: 'app-clientes-intermediario',
  templateUrl: './clientes-intermediario.component.html',
  styleUrls: ['./clientes-intermediario.component.scss'],
})
export class ClientesIntermediarioComponent implements OnInit {
  infoClientes: any[];
  info: any[] = [];
  infoInvitaciones: any[] = [];
  datosCargados = false;
  tabActive:string= 'lista';
  settings = {
    pager: {
      display: true,
      perPage: 50,
    },
    hideSubHeader: false,
    class: 'celdas_header',
    actions: {
      columnWidth: '200px',
      columnTitle: 'Acciones',
      add: false,
      filter: false,
      edit: false,
      delete: false,
    },
    columns: {
      nombre: {
        title: 'Nombre cliente',
        editable: true,
        filter: true,
      },
      apellido: {
        title: 'Apellido cliente',
        editable: true,
        filter: true,
      },
      polizaNumero: {
        title: 'Número de póliza',
        editable: true,
        filter: true,
      },
      fechaCreado: {
        title: 'Fecha de creación',
        editable: true,
        filter: true,
      },
      fechaLimite: {
        title: 'Fecha de límite de pago',
        editable: true,
        filter: true,
      },
      cuotasPendientes: {
        title: 'Cuotas pendientes',
        editable: true,
        filter: true,
      },
    },
  };
  settingsInvitados = {
    pager: {
      display: true,
      perPage: 50,
    },
    hideSubHeader: false,
    class: 'celdas_header',
    actions: {
      columnWidth: '200px',
      columnTitle: 'Acciones',
      add: false,
      filter: false,
      edit: false,
      delete: false,
    },
    columns: {
      nombre: {
        title: 'Nombre cliente',
        editable: true,
        filter: true,
      },
      apellido: {
        title: 'Apellido cliente',
        editable: true,
        filter: true,
      },
      usuarioCreado: {
        title: '¿Usuario Registrado?',
        editable: true,
        filter: true,
        valuePrepareFunction: (item) => {
          if(item == true){
            return "Si"
          }else{
            return "No"
          }
        },
        filterFunction(item?: any, search?: string): boolean {
          let match = item.indexOf(search) > -1
          if (match || search === '') {
            return true;
          } else {
            return false;
          }
        },
      },
      createdAt: {
        title: 'Fecha de invitación',
        editable: true,
        filter: true,
      },
    },
  };
  constructor(
    private mainService: MainService,
    private authService: AuthService
  ) {}


  crearInfoTabla() {
    this.infoClientes.forEach((cliente) => {
      if (cliente.usuario != null) {
        let fila = {};
        fila['nombre'] = cliente.usuario.nombre;
        fila['apellido'] = cliente.usuario.apellido;
        fila['polizaNumero'] = cliente.numeroPoliza;
        fila['fechaCreado'] = moment(cliente.createdAt).format('DD/MM/YYYY');
        fila['fechaLimite'] = this.calcularFechalimite(cliente);
        fila['cuotasPendientes'] = this.calcularCuotasPendientes(cliente);
        this.info.push(fila);
      }
    });
    this.datosCargados = true;
}

  calcularCuotasPendientes(poliza) {
    let cuotasPendientes = 0;
    for (let cuota of poliza.planDePagos) {
      if (cuota.pagado == 'no') {
        cuotasPendientes++;
      }
    }
    return cuotasPendientes;
  }

  calcularFechalimite(poliza) {
    let fechaLimite = '-';
    let encontrada = false;
    for (let i = 0; i < poliza.planDePagos.length; i++) {
      if (poliza.planDePagos[i].pagado === 'no') {
        encontrada = true;
        fechaLimite = moment(poliza.planDePagos[i].fecha).format('DD/MM/YYYY');
      }
    }
    return fechaLimite;
  }

  ngOnInit(): void {
    this.mainService
      .get(`poliza/intermediario/${this.authService.obtenerUsuarioActual().id}`)
      .subscribe((res: any) => {
        this.infoClientes = res;
        if (this.infoClientes) {
          this.crearInfoTabla();
        } else {
          alert('error');
        }
      });
      this.mainService
      .get(`clientes/invitacionesIntermediario/${this.authService.obtenerUsuarioActual().id}`)
      .subscribe((res: any) => {
        this.infoInvitaciones = res.data.map(invitado => {
          invitado.createdAt = moment(invitado.createdAt).format('DD/MM/YYYY')
          return invitado
        });
      });
  }

}
