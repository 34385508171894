<div class="row margin-row">
    <div class="col-md-12 text-center">
        <p class="title-principal">
            ¿Cómo lo hacemos?
        </p>
    </div>
    <div class="col-md-4">
        <div class="card">
            <div class="text-center">
                <img class="imagen" src="assets/recursos/financiar/carpeta-registro.png" alt="Imagen carpeta" />
            </div>
            <p class="title">Regístrate y firma tu pagaré</p>
            <p class="descripcion">
                Conoce las condiciones de tu financiación y realiza la firma digital de tu pagaré.
            </p>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card">
            <div class="text-center">
                <img class="imagen2" src="assets/recursos/financiar/grafica-cuota-inicial.svg"
                    alt="Imagen carpeta" />
            </div>
            <p class="title">Paga tu cuota inicial</p>
            <p class="descripcion">
                Paga mínimo el 15% del valor de tu seguro como cuota inicial.
            </p>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card">
            <div class="text-center">
                <img class="imagen2" src="assets/recursos/financiar/tarjetas.svg" alt="Imagen carpeta" />
            </div>
            <p class="title">Finsu paga el resto</p>
            <p class="descripcion">
                Un inversionista pagará el saldo restante de tu seguro y tú empezarás a pagar en cuotas mensuales
                disfrutando la cobertura.
            </p>
        </div>
    </div>
</div>