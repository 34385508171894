import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { AppToastService } from 'src/app/services/toast.service';

interface usuarioInvitado {
  email: string;
  nombre: string;
  apellido: string;
  usuarioCreado: boolean;
  usuarioIntermediario:string;
}
@Component({
  selector: 'app-crear-cliente',
  templateUrl: './crear-cliente.component.html',
  styleUrls: ['./crear-cliente.component.scss'],
})
export class CrearClienteComponent implements OnInit {
  public usuarioActivo:any;

  constructor(
    private toastService: AppToastService,
    private mainService: MainService,
    private router: Router
  ) {
    this.usuarioActivo = JSON.parse(localStorage.getItem('usuario'));
    console.log(this.usuarioActivo)
  }

  usuario: usuarioInvitado = {
    nombre: null,
    email: null,
    apellido: null,
    usuarioCreado: false,
    usuarioIntermediario: null
  };
  ngOnInit(): void {}

  manejarFormulario() {
    this.usuario.usuarioIntermediario= this.usuarioActivo.id;
    console.log("USuario", this.usuario)
    this.usuario.email = this.usuario.email.toLowerCase().trim();
    if (!this.usuario.apellido || !this.usuario.email || !this.usuario.nombre) {
      this.toastService.mostrar(
        'Error',
        'Hay campos vacíos o inválidos',
        'advertencia'
      );
    } else if (!this.mainService.validarEmail(this.usuario.email)) {
      this.toastService.mostrar(
        'Error',
        'El email no es válido',
        'advertencia'
      );
    } else {
      this.mainService
        .get(`user/email/info/${this.usuario.email}`)
        .subscribe((res: any) => {
          if (!res.success) {
            this.mainService.post('invitado', this.usuario).then((res: any) => {
              if (res.success) {
                this.toastService.mostrar(
                  'Éxito',
                  'Se ha enviado correctamente al correo',
                  'exito'
                );

                this.router.navigate(['intermediario']);
              } else {
                this.toastService.mostrar(
                  'Error',
                  'Intentelo más tarde',
                  'peligro'
                );
              }
            });
          } else {
            this.toastService.mostrar(
              'Error',
              'Ya existe un usuario con este correo',
              'advertencia'
            );
          }
        });
    }
  }
}
