<div class="wrapper-crear">
  <div class="container card-crear" *ngIf="!paso2">
    <h2>Crear póliza</h2>
    <div class="datos-cliente">
      <div class="row justify-content-start container-inputs">
        <div class="col-12 col-md-4 d-flex flex-column">
          <label class="label-poliza" for="nombre">Nombre del cliente</label>
          <input
            class="input-poliza"
            id="nombre"
            type="text"
            [(ngModel)]="nombre"
            placeholder="Escribe aquí"
          />
        </div>
        <div class="col-12 col-md-4 d-flex flex-column">
          <label class="label-poliza" for="email">Correo electrónico</label>
          <input
            class="input-poliza"
            type="email"
            [(ngModel)]="correoUsuario"
            (change)="onChangeValidarCorreo()"
            id="email"
            placeholder="Escribe aquí"
          />
          <p class="text-danger" *ngIf="correoValidacion">Correo no válido</p>
        </div>
      </div>

      <div class="row justify-content-start margin-top15 container-inputs">
        <div class="col-12 col-md-4 d-flex flex-column">
          <label class="label-poliza" for="fecha"
            >Fecha de inicio de vigencia*</label
          >
          <input
            class="input-poliza"
            id="fecha"
            placeholder="AAAA/MM/DD"
            (click)="d.toggle()"
            (blur)="onBlurMethod()"
            [(ngModel)]="fechaInicioVigencia"
            name="dp"
            ngbDatepicker
            #d="ngbDatepicker"
          />
        </div>
        <div class="col-12 col-md-4 d-flex flex-column">
          <label class="label-poliza" for="beneficiario"
            >Beneficiario oneroso (opcional)</label
          >
          <input
            class="input-poliza"
            type="input"
            id="beneficiario"
            [(ngModel)]="beneficiarioOneroso"
            placeholder="Escribe aquí"
          />
        </div>

        <div class="fecha-pago col-12 col-md-4 d-flex flex-column">
          <label class="label-poliza"
            >Plazo de pago para la primera cuota*</label
          >
          <p class="fecha">
            {{ fechaInicio.format("YYYY/MM/DD") }} hasta el
            {{ fechaFin.format("YYYY/MM/DD") }}
          </p>
        </div>
      </div>

      <div class="row margin-top15 container-inputs">
        <div class="col-12 d-flex flex-column">
          <label class="label-poliza" for="slider"
            >Ingresa el valor del seguro a financiar (incluido IVA)*
          </label>
        </div>
      </div>

      <div class="row margin-top15 container-inputs">
        <div class="col-12 col-md-6 d-flex flex-column">
          <label class="label-poliza">Valor*</label>
          <input
            (keyup)="insuranceChanged.next($event)"
            (userChange)="actualizarLabel()"
            [(ngModel)]="valorSeguro"
            [(ngModel)]="insuranceValue"
            class="input-valor"
            type="string"
            currencyMask
            [options]="{
              suffix: '',
              thousands: '.',
              decimal: ',',
              precision: 0,
              align: 'center'
            }"
          />
        </div>
      </div>

      <div class="row margin-top15 container-inputs">
        <div class="col-12 col-md-6 d-flex flex-column">
          <label class="label-poliza" for="nombre"
            >Porcentaje de cuota inicial*</label
          >
          <select
            class="input-largo"
            name="porcentaje"
            placeholder="Selecciona una opción"
            [(ngModel)]="porcentajeCuotaInicial"
          >
            <option value="">Selecciona una opción</option>
            <option
              value="{{ cuota }}"
              *ngFor="let cuota of cuotaInicialPermitidas()"
            >
              {{ cuota * 100 }}% del valor total ({{
                insuranceValue * cuota | currency : "COP" : "symbol" : "1.0-0"
              }})
            </option>
          </select>
        </div>
        <div class="col-12 col-md-6 d-flex flex-column">
          <label class="label-poliza" for="cuotas"
            >Selecciona el número de cuotas de tu seguro*</label
          >
          <select
            class="input-largo"
            id="cuotas"
            placeholder="Selecciona una opción"
            [(ngModel)]="numeroCuotas"
          >
            <option value="">Selecciona una opción</option>
            <option
              value="{{ cuotas }}"
              *ngFor="let cuotas of cuotasPermitidas()"
            >
              {{ cuotas }} cuotas
            </option>
          </select>
        </div>
      </div>

      <div class="row margin-top15 container-inputs">
        <div class="col-12 col-md-6 d-flex flex-column">
          <label class="label-poliza" for="nombre"
            >Selecciona una compañía de seguros*</label
          >
          <select
            class="input-largo"
            (change)="llenarOpcionesRamo(empresaAseguradora)"
            [(ngModel)]="empresaAseguradora"
            placeholder="Selecciona una opción"
          >
            <option selected disabled hidden [ngValue]="null">
              Selecciona una opción
            </option>
            <option value="Mundial Seguros">Mundial Seguros</option>
            <option value="SBS Seguros">SBS Seguros</option>
          </select>
        </div>

        <div class="col-12 col-md-6 d-flex flex-column">
          <label class="label-poliza" for="nombre"
            >Selecciona una Sucursal de seguros*</label
          >
          <select
            class="input-largo"
            [(ngModel)]="sucursal"
            placeholder="Selecciona una opción"
          >
            <option selected disabled hidden [ngValue]="null">
              Selecciona una opción
            </option>
            <option *ngFor="let s of selecSucursales" [value]="s">
              {{ s }}
            </option>
          </select>
        </div>
      </div>

      <div class="row margin-top15 container-inputs">
        <div class="col-12 col-md-6 d-flex flex-column">
          <label class="label-poliza" for="nombre">Ramo*</label>
          <select class="input-largo" [(ngModel)]="ramo">
            <option selected disabled hidden [ngValue]="null">
              Selecciona una opción
            </option>
            <option *ngFor="let r of ramoOpciones" value="{{ r }}">
              {{ r }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-6 d-flex flex-column">
          <label class="label-poliza" for="nombre">Número de póliza*</label>
          <input
            class="input-largo"
            id="nombre"
            type="text"
            [(ngModel)]="numeroPoliza"
            placeholder="Escribe aquí"
          />
        </div>
      </div>

      <ng-container
        *ngIf="
          ramo === '3 VEHÍCULOS PESADOS DE CARGA' ||
          ramo === '64 RESPONSABILIDAD CIVIL EXTRACONTRACTUAL - AUTOMÓVILES'
        "
      >
        <div class="row margin-top15 margin-bottom15 flex-end container-inputs">
          <div class="col-12 col-md-6 d-flex flex-column">
            <label class="label-poliza" for="nuevaPlaca">Placas*</label>
            <input
              class="input-largo"
              placeholder="ABC123"
              type="text"
              name="nuevaPlaca"
              [(ngModel)]="nuevaPlaca"
              #nuevaPlacaU="ngModel"
              pattern="^[a-zA-Z]{3}[0-9]{3}$"
              maxlength="6"
            />
            <div
              *ngIf="
                nuevaPlacaU.invalid &&
                (nuevaPlacaU.dirty || nuevaPlacaU.touched)
              "
              class="invalid"
            >
              El formato de la placa es incorrecto.
            </div>
          </div>
          <button
            type="button"
            (click)="agregarPlaca(nuevaPlacaU)"
            class="crear margin-left15"
            [class.margin-bottom24]="
              nuevaPlacaU.invalid && (nuevaPlacaU.dirty || nuevaPlacaU.touched)
            "
          >
            Agregar placa
          </button>
        </div>

        <div *ngIf="placas.length > 0 && placas.length <= 10; else noPlacas">
          <p>
            Se han agregado las siguientes placas:
            <span
              *ngFor="let placa of placas; index as i"
              (click)="eliminarPlaca(i)"
              class="placa-texto"
              >{{ placa }}{{ i === placas.length - 1 ? "" : ", " }}</span
            >
          </p>
        </div>
        <ng-template #noPlacas>
          <p>No se han agregado placas</p>
        </ng-template>
      </ng-container>

      <div class="row margin-top15 container-inputs">
        <div class="col-12 col-md-6 d-flex flex-column">
          <label class="label-poliza" for="nombre"
            >Número de certificado*</label
          >
          <input
            class="input-largo"
            id="nombre"
            type="text"
            [(ngModel)]="numeroCertificado"
            placeholder="Escribe aquí"
          />
        </div>
      </div>

      <div class="mt-5 botones row justify-content-center container-inputs">
        <button routerLink="/intermediario" class="volver">Volver</button>
        <button (click)="cambiarPaso2()" class="crear">Siguiente</button>
      </div>
    </div>
  </div>
  <div class="fondo" *ngIf="paso2">
    <div class="container">
      <app-plan-de-pagos
        [(numeroCuotas)]="numeroCuotas"
        [(metodovolver)]="metodovolver"
        [(porcentajeCuotaInicial)]="porcentajeCuotaInicial"
        [(valorTotal)]="insuranceValue"
      ></app-plan-de-pagos>
      <div class="row margin-bot">
        <div class="mt-5 botones row justify-content-center">
          <button (click)="paso2 = false" class="volver">Volver</button>
          <button
            class="btn btn-cotizar"
            class="crear"
            (click)="manejarEnvioFormulario()"
          >
            Procesar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
