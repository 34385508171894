<p class="titulo cursor">Peticiones, quejas y reclamos</p>
<div class="container">
  <div class="col-md-10">
    <div class="row card-inicio">
      <div class="col-md-12">
        <p class="text-input">
          Un agente de Finsu te contactará por correo, llena tus datos para
          poder comunicarnos contigo.
        </p>
      </div>
      <div class="col-md-12 margin-col">
        <p class="text-input">Motivo</p>
        <select [(ngModel)]="motivo" class="input" name="cuotas">
          <option selected disabled hidden>Selecciona</option>
          <option>Petición</option>
          <option>Queja</option>
          <option>Reclamo</option>
        </select>
      </div>
      <div class="col-md-12 margin-col">
        <p class="text-input">Descripción</p>
        <textarea
          name="solicitud"
          id=""
          cols="10"
          rows="4"
          placeholder="Escribe aquí"
          [(ngModel)]="mensaje"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="col-12">
    <button (click)="manejarEnvio()" class="btn btn-actualizar">Enviar</button>
  </div>
</div>
