import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { PolizaService } from 'src/app/services/poliza.service';
import { FormatterDataService } from 'src/app/services/formatter-data.service';

import * as moment from 'moment';
@Component({
  selector: 'app-cartera',
  templateUrl: './cartera.component.html',
  styleUrls: ['./cartera.component.scss'],
})
export class CarteraComponent implements OnInit {
  //Clase que contiene las propiedades o objectos que tiene cada usuario
  settings = {
    pager: {
      display: true,
      perPage: 50,
    },
    hideSubHeader: false,
    actions: false,
    class: 'celda',
    columns: {
      cedulaCliente: {
        title: 'Número de documento',
        editable: true,
        filter: true,
      },
      nombreCliente: {
        title: 'Nombre del cliente',
        editable: true,
        filter: true,
      },
      apellidoCliente: {
        title: 'Apellido del cliente',
        editable: true,
        filter: true,
      },
      numeroPoliza: {
        title: 'Número de póliza',
        editable: true,
        filter: true,
      },
      estadoCartera: {
        title: 'Estado de cartera',
        editable: true,
        filter: true,
      },
      numeroCuota: {
        title: 'Número de cuota',
        editable: true,
        filter: true,
      },
      diasMora: {
        title: 'Días de mora',
        editable: true,
        filter: true,
      },
      valorCuota: {
        title: 'Valor de cuota',
        editable: true,
        filter: true,
      },
    },
  };
  polizasPagosCartera: any[] = [];
  polizas: any[];
  datosCargados = false;

  //Constructor con parametros de modificadores de acceso de la data
  constructor(
    private mainService: MainService,
    private authService: AuthService,
    private polizaService: PolizaService,
    private formatterDataService: FormatterDataService
  ) {}

  ngOnInit(): void {
    this.mainService
      .get(`poliza/intermediario/${this.authService.obtenerUsuarioActual().id}`)
      .subscribe((polizasIntermediario: any) => {
        this.polizas = polizasIntermediario;
        this.cargarDatos();
      });
  }

  estaActiva(poliza) {
    //Metodo calcula si la poliza esta activa
    if (poliza.estado == 'Vencida') {
      return false;
    } else {
      if (poliza.fechaPagoDeCuotaInicial) {
        return true;
      } else {
        //TODO: para tener pólizas en la cartera, se puso return a true. Pero cambiarlo a false
        return true;
      }
    }
  }

  cargarDatos() {
    //Metodo que permite cargar datos llamandolos desde la clase y asignando data desde el back
    this.polizas.forEach((poliza) => {
      if (this.estaActiva(poliza)) {
        let entrada = {};

        entrada['estadoCartera'] = this.polizaService.calcularEstado(poliza);
        if(
          poliza.planDePagos[poliza.planDePagos.length - 1].pagado === 'si'
          || entrada["estadoCartera"] == 'Revocado por mora'
          || entrada["estadoCartera"] == 'Cancelacion voluntaria'
        ) return

        entrada['cedulaCliente'] = poliza.usuario?.numeroDocumento;
        entrada['nombreCliente'] = poliza.usuario?.nombre;
        entrada['apellidoCliente'] = poliza.usuario?.apellido;
        entrada['numeroPoliza'] = poliza.numeroPoliza;

        poliza.planDePagos.some((cuota, index: number) => {
          if (cuota.pagado === 'si') return false

          entrada['numeroCuota'] = index + 1
          entrada['diasMora'] = cuota.diasMora;

          const valorCuota = this.formatterDataService.numberCurrency(
            this.formatterDataService.numberRound(cuota.valor)
            + this.formatterDataService.numberRound(cuota.valorMora)
          )

          entrada['valorCuota'] = valorCuota

          this.polizasPagosCartera.push({...entrada, ...entrada});
          return true
        })

      }
    });

    this.datosCargados = true;
  }

  calcularDiasMora(poliza) {
    //Metodo que permite calcular los dias de mora de una poliza
    let dias = 0;
    if (!poliza.fechaPagoDeCuotaInicial) {
      return dias;
    } else {
      let encontrada = false;
      for (let i = 0; i < poliza.planDePagos.length && !encontrada; i++) {
        //Obtiendo cuota actual del for
        let cuota = poliza.planDePagos[i];
        //Verificamos si esta pagada
        if (cuota.pagado == 'no') {
          let hoy = moment();
          let fechaCuota = moment(cuota.fecha);
          dias = hoy.diff(fechaCuota, 'days');
          encontrada = true;
        }
      }
    }

  }

  calcularPago(poliza) {
    //Metodo que valida el valor a pagar por la poliza segun el valor del seguro data desde el back
    let monto = 0;
    if (!poliza.fechaPagoDeCuotaInicial) {
 //  monto += poliza.valorSeguro;
        poliza.planDePagos.forEach((cuota) => {
        if (cuota.pagado == 'si') {
          monto += cuota.valorMora + cuota.valor;
        }
        return monto;
      });
    }
    let currency = Intl.NumberFormat('es-CO', {style: 'currency', currency: 'COP'}).format(monto);
    return currency;
}

  calcularCoutasEnMora(poliza) {
    //Metodo que las coutas que se encuentra en mora el usuario
    let cuotasEnMora = [];
    for (let i = 0; i < poliza.planDePagos.length; i++) {
      let hoy = moment();
      let fechaCuota = moment(poliza.planDePagos[i].fecha);
      if (poliza.planDePagos[i].pagado == 'no' && fechaCuota.diff(hoy, 'days') < 0) {
        cuotasEnMora.push(`Cuota ${i + 1}`);
      }
    }
    return cuotasEnMora;
  }
}

