import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import * as moment from 'moment';
import { Options } from 'ng5-slider';
import {
  NgbDateStruct,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-detalle-poliza',
  templateUrl: './detalle-poliza.component.html',
  styleUrls: ['./detalle-poliza.component.scss'],
})
export class DetallePolizaComponent implements OnInit {
  tipoDocumento = {
    CC: 'Cédula de ciudadanía ',
    CE: 'Cédula de extranjería',
    NIT: 'NIT',
    PASAPORTE: 'Pasaporte',
  };

  public cuotasSinBeneficiario: number = 0;
  /**Variable para almacenar cuotas con beneficiario */
  public cuotasConBeneficiario: number = 0;
  activeModal: NgbModalRef;
  esEditable: boolean = true;
  editarPoliza: boolean = false;
  esVencida: boolean;
  ramoOpciones: any = [];
  tasaMensual: number = 0.016708963873128146;
  public simulacion: any = [];
  plan: any = [];
  usuario: any;
  estado: string;
  beneficiarioOneroso: string;
  valorSeguro: number;
  porcentajeCuotaInicial: number = null;
  cuotaInicial: number;
  numeroCuotas: string = null;
  empresaAseguradora: string = null;
  ramo: string = null;
  numeroPoliza: string;
  numeroCertificado: string;
  fechaInicioVigencia: NgbDateStruct;
  fechaInicio = moment();
  fechaFin = moment().add(5, 'days');
  maxValue: number = 2000000;
  fechaCreacion: string;
  fechaLimite: string;
  loadingCarga: boolean;

  poliza: any = {};

  constructor(
    private ruta: ActivatedRoute,
    private authService: AuthService,
    private mainService: MainService,
    private modalService: NgbModal
  ) {}

  onBlurMethod() {
    if (this.fechaInicioVigencia !== undefined) {
      let fecha =
        this.fechaInicioVigencia.year +
        '/' +
        this.fechaInicioVigencia.month +
        '/' +
        this.fechaInicioVigencia.day;
      fecha = moment(fecha).format('YYYY/MM/DD');
      this.fechaInicio = moment(fecha);
      this.fechaFin = moment(this.fechaInicio).add(5, 'days');

      if (!moment(fecha).isValid()) {
        this.fechaInicioVigencia = undefined;
        Swal.fire(
          'Formato de fecha no valida, por favor indique un formato válido para poder continuar.'
        );
      }
    }
  }

  mostrarModal(contenido) {
    this.activeModal = this.modalService.open(contenido);
  }

  cerrarModal() {
    this.activeModal.close();
  }

  public cuotasPermitidas() {
    let cuotas = [];
    if (this.maxValue && this.maxValue > 0) {
      if (this.maxValue >= 500000 && this.maxValue < 600000) {
        cuotas = [5];
      } else if (this.maxValue >= 600000 && this.maxValue < 700000) {
        cuotas = [5, 6];
      } else if (this.maxValue >= 700000 && this.maxValue < 800000) {
        cuotas = [5, 6, 7];
      } else if (this.maxValue >= 800000 && this.maxValue < 900000) {
        cuotas = [5, 6, 7, 8];
      } else if (this.maxValue >= 900000 && this.maxValue < 1000000) {
        cuotas = [5, 6, 7, 8, 9];
      } else {
        cuotas = [5, 6, 7, 8, 9];
      }
      let i = cuotas.length;
      while (i--) {
        if (
          this.beneficiarioOneroso &&
          cuotas[i] > this.cuotasConBeneficiario
        ) {
          cuotas.splice(i, 1);
        }
        if (
          !this.beneficiarioOneroso &&
          cuotas[i] > this.cuotasSinBeneficiario
        ) {
          cuotas.splice(i, 1);
        }
      }

      return cuotas;
    } else {
      return [''];
    }
  }

  public cuotaInicialPermitidas() {
    let cuotasIniciales = [];
    if (this.fechaInicioVigencia) {
      let fecha = '';
      if (typeof this.fechaInicioVigencia == 'object') {
        fecha =
          this.fechaInicioVigencia.year +
          '/' +
          this.fechaInicioVigencia.month +
          '/' +
          this.fechaInicioVigencia.day;
      } else {
        fecha = this.fechaInicioVigencia;
      }
      fecha = moment(fecha).format('YYYY/MM/DD');
      let diasDiferenciaVigencia = moment(fecha).diff(moment(), 'days');
      // Diferencia en días desde el inicio de la vigencia y el día actual. Negativo son días en el pasado         "YYYY-MM-DD"
      if (diasDiferenciaVigencia <= -61) {
        cuotasIniciales = [0.4];
        this.cuotasSinBeneficiario = 7;
        this.cuotasConBeneficiario = 6;
        return cuotasIniciales;
      }

      if ( diasDiferenciaVigencia <= -30 && diasDiferenciaVigencia >= -60) {
        cuotasIniciales = [0.3, 0.4];
        this.cuotasSinBeneficiario = 7;
        this.cuotasConBeneficiario = 6;
        return cuotasIniciales;
      }

      if ( diasDiferenciaVigencia <= -16 && diasDiferenciaVigencia > -30) {
        cuotasIniciales = [0.3, 0.4];
        this.cuotasSinBeneficiario = 8;
        this.cuotasConBeneficiario = 7;
        return cuotasIniciales;
      }

      if (diasDiferenciaVigencia <= -1 && diasDiferenciaVigencia > -16) {
        cuotasIniciales = [0.2, 0.3, 0.4];
        this.cuotasSinBeneficiario = 9;
        this.cuotasConBeneficiario = 8;
        return cuotasIniciales;
      } else {
        cuotasIniciales = [0.15, 0.2, 0.3, 0.4];
        this.cuotasSinBeneficiario = 9;
        this.cuotasConBeneficiario = 8;
      }

      return cuotasIniciales;
    } else {
      return [''];
    }
  }

  hayInputsVacios() {
    return (
      this.fechaInicioVigencia == null ||
      this.porcentajeCuotaInicial == null ||
      this.numeroCuotas == null ||
      this.empresaAseguradora == null ||
      this.ramo == null ||
      this.numeroPoliza.length == 0 ||
      this.numeroCertificado.length == 0
    );
  }

  generarPlanDePagos() {
    this.simulacion = [];
    for (let i = 1; i < parseInt(this.numeroCuotas) + 1; i++) {
      let x;
      if (i == 1) {
        x = {
          fecha: moment().add(i, 'M').format('YYYY-MM-DD'),
          saldoCapital: this.valorSeguro * (1 - this.porcentajeCuotaInicial),
          cuotaFija: this.PMT(
            this.tasaMensual, //, //* (1 + this.config.iva),,
            this.numeroCuotas,
            this.valorSeguro * (1 - this.porcentajeCuotaInicial) * -1,
            0,
            0
          ),
          abonoCapital:
            this.PMT(
              this.tasaMensual, //, //* (1 + this.config.iva),,
              this.numeroCuotas,
              this.valorSeguro * (1 - this.porcentajeCuotaInicial) * -1,
              0,
              0
            ) -
            this.valorSeguro *
              (1 - this.porcentajeCuotaInicial) *
              this.tasaMensual, //, //* (1 + this.config.iva),,
          interes:
            this.valorSeguro *
            (1 - this.porcentajeCuotaInicial) *
            this.tasaMensual,
          /* IVA:
                this.valorTotal *
                (1 - this.porcentajeCuotaInicial) *
                this.tasaMensual * this.config.iva, */
        };
      } else {
        x = {
          fecha: moment().add(i, 'M').format('YYYY-MM-DD'),
          saldoCapital:
            this.simulacion[i - 2].saldoCapital -
            this.simulacion[i - 2].abonoCapital,
          cuotaFija: this.PMT(
            this.tasaMensual, //, //* (1 + this.config.iva),,
            this.numeroCuotas,
            this.valorSeguro * (1 - this.porcentajeCuotaInicial) * -1,
            0,
            0
          ),
          abonoCapital:
            this.PMT(
              this.tasaMensual, //, //* (1 + this.config.iva),,
              this.numeroCuotas,
              this.valorSeguro * (1 - this.porcentajeCuotaInicial) * -1,
              0,
              0
            ) -
            (this.simulacion[i - 2].saldoCapital -
              this.simulacion[i - 2].abonoCapital) *
              this.tasaMensual, //*(1 + this.config.iva),
          interes:
            (this.simulacion[i - 2].saldoCapital -
              this.simulacion[i - 2].abonoCapital) *
            this.tasaMensual,
          /* IVA:
                (this.simulacion[i - 2].saldoCapital -
                  this.simulacion[i - 2].abonoCapital) *
                this.tasaMensual, // *    this.config.iva, */
        };
      }
      this.simulacion.push(x);
      this.plan = this.simulacion;
      // this.enviarPlan();
    }
  }

  esCorreoValido(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  manejarEnvioFormulario() {
    if (
      this.fechaInicio.startOf('day').isSameOrBefore(moment().startOf('day'))
      || this.fechaInicio.startOf('day').isSameOrAfter(moment(this.poliza.fechaFinVigencia).startOf('day'))) {
      return Swal.fire(
        'Fecha no válida. Debe ser entre la fecha límite y hoy.'
      );
    }

    if (this.hayInputsVacios()) {
      Swal.fire('', 'Hay campos vacíos o incompletos.', 'warning');
    } else if (this.valorSeguro > 10000000 || this.valorSeguro < 500000) {
      Swal.fire(
        '',
        'El valor de la póliza esta fuera de los rangos.',
        'warning'
      );
    } else {
      this.generarPlanDePagos();
      let polizaData = {
        usuario: this.usuario._id,
        estado: 'Pendiente Usuario',
        cuotaInicial: this.valorSeguro * this.porcentajeCuotaInicial,
        valorSeguro: this.valorSeguro,
        porcentajeCuotaInicial: this.porcentajeCuotaInicial,
        fechaInicioVigencia: this.fechaInicio.format('YYYY-MM-DD'),
        numeroPoliza: this.numeroPoliza,
        numeroCuotas: this.numeroCuotas,
        planDePagos: this.plan,
      };

      if (this.beneficiarioOneroso != null) {
        polizaData['beneficiarioOneroso'] = this.beneficiarioOneroso;
      }

      this.mainService
        .put(`poliza/${this.poliza._id}`, polizaData)
        .then((polizaRespuesta: any) => {
          if (polizaRespuesta._id) {
            Swal.fire({
              title: '<strong>¡Bien hecho! <br/> Editaste de nuevo la póliza</strong>',
              html:
                'La póliza fue actualizada. <br/>',
              showCloseButton: true,
              focusConfirm: false,
              confirmButtonColor: '#415ce5',
              confirmButtonAriaLabel:
                'OK'
            })
            this.mainService.redirigirA('intermediario');
          }
        })
        .catch((error) => {
          Swal.fire('', 'Error al crear la póliza.', 'warning');
        });
    }
  }

  crearNuevaPoliza() {
    if (this.hayInputsVacios()) {
      Swal.fire('', 'Hay campos vacíos o incompletos', 'warning');
    } else if (this.valorSeguro > 10000000 || this.valorSeguro < 500000) {
      Swal.fire(
        '',
        'El valor de la póliza esta fuera de los rangos.',
        'warning'
      );
    } else {
      this.generarPlanDePagos();
      let polizaData = {
        usuario: this.usuario._id,
        usuarioIntermediario: this.authService.user.id,
        estado: 'Pendiente Usuario',
        cuotaInicial: this.valorSeguro * this.porcentajeCuotaInicial,
        valorSeguro: this.valorSeguro,
        porcentajeCuotaInicial: this.porcentajeCuotaInicial,
        fechaInicioVigencia: this.fechaInicio.format('YYYY-MM-DD'),
        numeroPoliza: this.numeroPoliza,
        numeroCuotas: this.numeroCuotas,
        planDePagos: this.plan,
        empresaAseguradora: this.empresaAseguradora,
        ramo: this.ramo,
      };

      if (this.beneficiarioOneroso != null) {
        polizaData['beneficiarioOneroso'] = this.beneficiarioOneroso;
      }

      this.mainService
        .post(`poliza`, polizaData)
        .then((polizaRespuesta: any) => {
          if (polizaRespuesta._id) {
            Swal.fire(
              '¡La póliza fue creada exitosamente!',
              'Se ha enviado al cliente para que procese la primera cuota de pago.',
              'success'
            );
            this.mainService.redirigirA('intermediario');
          }
        })
        .catch((error) => {
          Swal.fire('', 'Error al crear la póliza.', 'warning');
        });
    }
  }

  public PMT(ir, np, pv, fv, type) {
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0) return -(pv + fv) / np;

    pvif = Math.pow(1 + ir, np);
    pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

    if (type === 1) pmt /= 1 + ir;

    return pmt;
  }

  estaVencida() {
    return new Promise((success, reject) => {
      if (this.poliza.estado == 'Vencida') {
        success(true);
      } else {
        success(false);
      }

    });
  }

  sePuedeEditar() {
    return new Promise((success, reject) => {
      if (this.poliza.estado == 'Vencida') {
        success(false);
      } else {
        if (this.poliza.fechaPagoDeCuotaInicial) {
          success(false);
        } else {
          success(true);

        }
      }
    });
  }

  ngOnInit(): void {
    this.loadingCarga = true;
    let idPoliza = this.ruta.snapshot.params.idPoliza;
    this.mainService.get(`poliza/${idPoliza}`).subscribe(async (poliza: any) => {
      this.poliza = poliza;

      this.plan = poliza.planDePagos;

      this.usuario = poliza.usuario;

      this.estado = poliza.estado;

      this.beneficiarioOneroso = poliza.beneficiarioOneroso;

      this.valorSeguro = poliza.valorSeguro;

      this.porcentajeCuotaInicial = poliza.porcentajeCuotaInicial;

      this.cuotaInicial = poliza.cuotaInicial;

      this.numeroCuotas = poliza.numeroCuotas;

      this.empresaAseguradora = poliza.empresaAseguradora;

      this.ramo = poliza.ramo;

      this.numeroPoliza = poliza.numeroPoliza;

      this.numeroCertificado = poliza.numeroCertificado;

      let fechaVigencia = moment(poliza.fechaInicioVigencia).toObject();

      this.fechaInicioVigencia = {
        year: fechaVigencia.years,
        month: fechaVigencia.months + 1,
        day: fechaVigencia.date,
      };
      this.fechaCreacion = moment(this.poliza.createdAt).format('DD/MM/YYYY');
      this.fechaLimite = moment(this.poliza.createdAt)
        .add(5, 'days')
        .format('DD/MM/YYYY');
      let editable = await this.sePuedeEditar();
      let esVencida = await this.estaVencida();
      this.editarPoliza = !editable;

      if (esVencida) {
        this.esVencida= true;
        this.esEditable = true;
      }
      this.loadingCarga = false;

      this.onBlurMethod()
    });
  }
}
