<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Creando cuenta ...</p>
</ngx-spinner>
<div class="fondo">
  <div class="container">
    <form class="form" [formGroup]="registerForm">
      <div class="row card-inicio" *ngIf="tipoUser == 'cliente'">
        <div class="col-md-10">
          <p class="crear-cuenta">Ingresa tus datos</p>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Nombre</p>
          <input
            class="input"
            type="text"
            name="nombre"
            placeholder="Escribe aquí"
            formControlName="nombre"
            [(ngModel)]="nombre"
          />

          <span
            class="text-danger"
            *ngIf="
              (registerFormControl.nombre.touched || submitted) &&
              registerFormControl.nombre.errors?.required
            "
            >Nombre es requerido.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Apellido</p>
          <input
            class="input"
            type="text"
            name="tipoperson"
            placeholder="Escribe aquí"
            formControlName="apellido"
            [(ngModel)]="apellido"
          />
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl.apellido.touched || submitted) &&
              registerFormControl.apellido.errors?.required
            "
          >
            Apellido es requerido.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Dirección</p>
          <input
            class="input"
            type="text"
            name="direccion"
            placeholder="Escribe aquí"
            formControlName="direccion"
            [(ngModel)]="direccion"
          />
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl.direccion.touched || submitted) &&
              registerFormControl.direccion.errors?.required
            "
          >
            La dirección es requerida.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Ciudad</p>
          <select
            class="popRegular size16 input form-control"
            style="padding-top: 0; padding-bottom: 0"
            formControlName="ciudad"
            name="ciudad"
            id="ciudad"
            placeholder="Selecciona"
            [(ngModel)]="ciudad"
          >
            <option value="">Ciudad</option>
            <option *ngFor="let ciudad of citiesList" value="{{ ciudad }}">
              {{ ciudad }}
            </option>
          </select>
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl.ciudad.touched || submitted) &&
              registerFormControl.ciudad.errors?.required
            "
          >
            Ciudad es requerido.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Tipo de documento</p>
          <select
            class="popRegular size16 input form-control"
            style="padding-top: 0; padding-bottom: 0"
            id="tipoDocumento"
            name="tipoDocumento"
            formControlName="tipoDocumento"
          >
            <option value="">Tipo de documento</option>
            <option value="CC">CC</option>
            <option value="CE">CE</option>
            <option value="NIT">NIT</option>
            <option value="PASAPORTE">PASAPORTE</option>
          </select>
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl.tipoDocumento.touched || submitted) &&
              registerFormControl.tipoDocumento.errors?.required
            "
          >
            Tipo documento es requerido.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Número de documento *</p>
          <input
            class="input"
            type="number"
            name="numeroDocumento"
            placeholder="Escribe aquí"
            formControlName="numeroDocumento"
          />
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl.numeroDocumento.touched || submitted) &&
              registerFormControl.numeroDocumento.errors?.required
            "
          >
            Número de documento es requerido.
          </span>
        </div>

        <div
          class="col-md-6 col-margin"
          *ngIf="
            numeroDocumentorLists.selectedIndex ==
            numeroDocumentorLists.options.length - 2
          "
        >
          <p class="text-input">Número de NIT</p>
          <input
            class="input-nit"
            type="number"
            name="numeroDocumento"
            placeholder="Escribe aquí"
            formControlName="numeroDocumento"
            required
            minlength="9"
            maxlength="9"
          />
          <input
            class="input-nVerificacion"
            type="tel"
            name="codigoVerificacion"
            placeholder="Código"
            formControlName="codigoVerificacion"
            required
            minlength="0"
            maxlength="1"
          />
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl.numeroDocumento.touched || submitted) &&
              registerFormControl.numeroDocumento.errors?.required
            "
          >
            Número de NIT es requerido.
          </span>
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl.codigoVerificacion.touched || submitted) &&
              registerFormControl.codigoVerificacion.errors?.required
            "
          >
            Código de NIT es requerido.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Email</p>
          <input
            class="input"
            type="email"
            name="razon"
            placeholder="Escribe aquí"
            formControlName="email"
            [(ngModel)]="email"
          />
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl.email.touched || submitted) &&
              registerFormControl.email.errors?.required
            "
          >
            Email es requerido.
          </span>
          <span
            class="text-danger"
            *ngIf="
              registerFormControl.email.touched &&
              registerFormControl.email.errors?.email
            "
          >
            Ingresa un email válido.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Contraseña</p>
          <img
            class="contraseña"
            alt="icono ver contraseña"
            src="assets/recursos/logos-iconos/ver.png"
            (click)="togglePass()"
          />
          <input
            class="input"
            [type]="muestra"
            name="tipod"
            placeholder="Escribe aquí"
            formControlName="password"
          />
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl.password.touched || submitted) &&
              registerFormControl.password.errors?.required
            "
          >
            Contraseña es requerida.
          </span>
          <span
            class="text-danger"
            *ngIf="
              registerFormControl.password.touched &&
              registerFormControl.password.errors?.invalidPassword
            "
          >
            La contraseña debe tener un mínimo de 8 caracteres con números y
            letras.
          </span>
        </div>

        <div class="col-md-6">
          <div class="padding-div">
            <input
              type="checkbox"
              id="scales"
              name="scales"
              [(ngModel)]="acepta"
              checked
              [ngModelOptions]="{ standalone: true }"
            />
            <label for="vehicle1"
              >&nbsp; Acepto
              <label class="label-link" (click)="onMostrarModal(content)"
                >Términos y Condiciones</label
              ></label
            ><br />
            <input
              type="checkbox"
              id="scales"
              name="scales"
              [(ngModel)]="terminos"
              checked
              [ngModelOptions]="{ standalone: true }"
            />
            <label for="vehicle1"
              >&nbsp; Acepto
              <label class="label-link" (click)="onMostrarModal(content2)"
                >Tratamiento de mis datos</label
              > </label
            ><br />
          </div>

          <div class="col-md-6 center-vertical">
            <button class="btn" type="submit" (click)="onSubmit()">
              Registrar
            </button>
          </div>
        </div>
      </div>

      <div class="row card-inicio">
        <div class="col-md-10">
          <p
            *ngIf="tipoUser === 'Usuario'; else elseTipoUser"
            class="crear-cuenta"
          >
            Crea tu cuenta como comprador
          </p>
          <ng-template #elseTipoUser>
            <p class="crear-cuenta">Crea tu cuenta como intermediario</p>
          </ng-template>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Nombres *</p>
          <input
            class="input"
            type="text"
            name="nombre"
            placeholder="Escribe aquí"
            formControlName="nombre"
            [(ngModel)]="nombre"
          />
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl['nombre'].touched || submitted) &&
              registerFormControl['nombre'].errors?.required
            "
          >
            Nombre es requerido.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Apellidos *</p>
          <input
            class="input"
            type="text"
            name="tipoperson"
            placeholder="Escribe aquí"
            formControlName="apellido"
            [(ngModel)]="apellido"
          />
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl['apellido'].touched || submitted) &&
              registerFormControl['apellido'].errors?.required
            "
          >
            Apellido es requerido.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Dirección</p>
          <input
            class="input"
            type="text"
            name="direccion"
            placeholder="Escribe aquí"
            formControlName="direccion"
            [(ngModel)]="direccion"
          />

          <span
            class="text-danger"
            *ngIf="
              (registerFormControl['direccion'].touched || submitted) &&
              registerFormControl['direccion'].errors?.required
            "
          >
            La dirección es requerida.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Ciudad</p>
          <select
            class="popRegular size16 input form-control"
            style="padding-top: 0; padding-bottom: 0"
            formControlName="ciudad"
            name="ciudad"
            id="ciudad"
            placeholder="Selecciona"
            [(ngModel)]="ciudad"
          >
            <option value="">Ciudad</option>
            <option *ngFor="let ciudad of citiesList" value="{{ ciudad }}">
              {{ ciudad }}
            </option>
          </select>
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl['ciudad'].touched || submitted) &&
              registerFormControl['ciudad'].errors?.required
            "
          >
            Ciudad es requerido.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Celular</p>

          <input
            class="input"
            type="text"
            name="telefono"
            placeholder="Escribe aquí"
            formControlName="telefono"
            (input)="formatterInput('justNumbers', 'telefono')"
          />
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl['telefono'].touched || submitted) &&
              registerFormControl['telefono'].errors?.required
            "
          >
            Teléfono es requerido.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Tipo de persona</p>
          <select
            class="popRegular size16 input form-control"
            style="padding-top: 0; padding-bottom: 0"
            name="tipoPersona"
            placeholder="Escribe aquí"
            formControlName="tipoPersona"
          >
            <option value="">Tipo de persona</option>
            <option value="Natural">Natural</option>
            <option value="Jurídica">Jurídica</option>
          </select>
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl['tipoPersona'].touched || submitted) &&
              registerFormControl['tipoPersona'].errors?.required
            "
          >
            Tipo persona es requerido.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Razón social (si aplica)</p>
          <input
            class="input"
            type="text"
            name="razonSocial"
            placeholder="Escribe aquí"
            formControlName="razonSocial"
          />
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Tipo de documento</p>
          <select
            #numeroDocumentorLists
            class="popRegular size16 input form-control"
            style="padding-top: 0; padding-bottom: 0"
            id=""
            name="tipoDocumento"
            formControlName="tipoDocumento"
          >
            <option *ngFor="let tipoDocumento of listsDocumento">
              {{ tipoDocumento }}
            </option>
          </select>
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl['tipoDocumento'].touched || submitted) &&
              registerFormControl['tipoDocumento'].errors?.required
            "
          >
            Tipo documento es requerido.
          </span>
        </div>

        <div
          class="col-md-6 col-margin"
          *ngIf="
            numeroDocumentorLists.selectedIndex !==
            numeroDocumentorLists.options.length - 2
          "
        >
          <p class="text-input">Número de documento *</p>
          <input
            class="input"
            type="text"
            name="numeroDocumento"
            placeholder="Escribe aquí"
            formControlName="numeroDocumento"
            (input)="formatterInput('justNumbers', 'numeroDocumento')"
          />
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl.numeroDocumento.touched || submitted) &&
              registerFormControl.numeroDocumento.errors?.required
            "
          >
            Número de documento es requerido.
          </span>
          <span
            class="text-danger"
            *ngIf="
              registerFormControl.numeroDocumento.touched &&
              !validatedData.document.length
            "
          >
            Tiene que ser mayor a 7.
          </span>
        </div>

        <div
          class="col-md-6 col-margin"
          *ngIf="
            numeroDocumentorLists.selectedIndex ==
            numeroDocumentorLists.options.length - 2
          "
        >
          <p class="text-input">Número de NIT</p>
          <input
            class="input-nit"
            type="text"
            name="numeroDocumento"
            placeholder="Escribe aquí"
            formControlName="numeroDocumento"
            (input)="formatterInput('justNumbers', 'numeroDocumento')"
          />
          <input
            class="input-nVerificacion"
            type="text"
            name="codigoVerificacion"
            placeholder="Código"
            formControlName="codigoVerificacion"
            (input)="formatterInput('justNumbers', 'codigoVerificacion')"
          />
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl.numeroDocumento.touched || submitted) &&
              registerFormControl.numeroDocumento.errors?.required
            "
          >
            Número de NIT es requerido.
          </span>
          <span
            class="text-danger"
            *ngIf="
              registerFormControl.numeroDocumento.touched &&
              !validatedData.document.length
            "
          >
            Tiene que ser mayor a 7.
          </span>
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl.codigoVerificacion.touched || submitted) &&
              registerFormControl.codigoVerificacion.errors?.required
            "
          >
            Código de NIT es requerido.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Correo electrónico *</p>
          <input
            class="input"
            type="text"
            name="razon"
            autocomplete="off"
            placeholder="Escribe aquí"
            formControlName="email"
            [(ngModel)]="email"
            [readOnly]="bloqueaEmail"
          />
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl['email'].touched || submitted) &&
              registerFormControl['email'].errors?.required
            "
          >
            Correo electrónico requerido.
          </span>
          <span
            class="text-danger"
            *ngIf="
              registerFormControl['email'].touched &&
              registerFormControl['email'].errors?.email
            "
          >
            Ingresa un correo electrónico válido.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Contraseña</p>
          <img
            class="icon"
            alt="icono ver contraseña"
            src="assets/recursos/logos-iconos/ver.png"
            (click)="togglePass()"
          />
          <input
            class="input"
            [type]="muestra"
            (input)="samePassword()"
            autocomplete="new-password"
            name="tipod"
            placeholder="Escribe aquí"
            formControlName="password"
          />
          <span
            class="text-danger"
            *ngIf="
              (registerFormControl['password'].touched || submitted) &&
              registerFormControl['password'].errors?.required
            "
          >
            Contraseña es requerida.
          </span>
          <span
            class="text-danger"
            *ngIf="
              registerFormControl['password'].touched &&
              registerFormControl['password'].errors?.invalidPassword
            "
          >
            La contraseña debe tener un mínimo de 8 caracteres con números y
            letras.
          </span>
        </div>

        <div class="col-md-6 col-margin">
          <p class="text-input">Confirmar Contraseña</p>
          <img
            class="icon"
            alt="icono ver contraseña"
            src="assets/recursos/logos-iconos/ver.png"
            (click)="togglePass('muestraCo')"
          />
          <input
            class="input"
            (input)="samePassword()"
            [type]="muestraCo"
            autocomplete="off"
            name="tipod"
            placeholder="Escribe aquí"
            formControlName="confirmPassword"
          />
          <span
            class="text-danger"
            *ngIf="
              registerFormControl['confirmPassword'].touched && !samePasswords
            "
          >
            Las contraseñas no son iguales
          </span>
        </div>

        <div class="col-sm-12 col-lg-6">
          <div class="padding-div">
            <div class="container-conditions">
              <input
                type="checkbox"
                id="scales"
                name="scales"
                [(ngModel)]="acepta"
                checked
                [ngModelOptions]="{ standalone: true }"
              />
              <label for="vehicle1"
                >&nbsp; Acepto
                <span
                  class="label-link morado"
                  (click)="onMostrarModal(content)"
                  >Términos y Condiciones</span
                >
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="scales"
                name="scales"
                [(ngModel)]="terminos"
                checked
                [ngModelOptions]="{ standalone: true }"
              />
              <label for="vehicle1"
                >&nbsp; Acepto
                <span
                  class="label-link morado"
                  (click)="onMostrarModal(content2)"
                  >Tratamiento de mis datos</span
                >
              </label>
            </div>
          </div>
        </div>

        <div class="col-md-6 center-vertical">
          <button
            class="btn backgroundMorado"
            type="submit"
            (click)="onSubmit()"
          >
            Registrar
          </button>
          <p class="tienes-cuenta" hidden>
            ¿Ya tienes cuenta?
            <label class="ingresa morado" routerLink="/iniciar-sesion"
              >Ingresa
            </label>
          </p>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #content let-modal>
  <div id="groupModal"></div>
  <div class="d-flex flex-column align-items-center" style="padding: 20px 40px">
    <button class="btn close-modal" (click)="onCloseModal()">Cerrar</button>
    <p align="center">
      <strong>
        TÉRMINOS Y CONDICIONES DEL COMPRADOR DE LA PÓLIZA DE SEGURO
      </strong>
    </p>
    <p>
      <strong>FINSU </strong> es la plataforma tecnológica dispuesta por FINSU
      S.A.S., sociedad identificada con
      <a name="_Hlk23867209">NIT 901307632 – 2</a>, domiciliada en la ciudad de
      Bogotá D.C., en la dirección Calle 63 # 28A-62, que permite a quien(es)
      acepte(n) los términos y condiciones establecidos en el presente
      documento, obtener a través de financiación, los recursos necesarios para
      el pago de pólizas de seguro expedidas por aseguradoras aliadas, a través
      de un proceso completamente digital. El servicio se regirá por las
      siguientes condiciones:
    </p>
    <ol start="1" type="1">
      <li>
        <strong>DEFINICIONES PREVIAS</strong>
      </li>
    </ol>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>1.1. </strong>
      <strong>ASEGURADORA ALIADA: </strong> Compañía especializada y autorizada
      por la Superintendencia Financiera de Colombia para la expedición de
      pólizas de seguro en el territorio de la República de Colombia, que
      mantiene acuerdos de alianza o colaboración con FINSU. FINSU informará en
      la plataforma las ASEGURADORAS ALIADAS para conocimiento de COMPRADORES e
      INVERSIONISTAS.<strong></strong>
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>1.2. </strong>
      <strong>COMPRADOR</strong> : Persona natural o jurídica que adquiere su
      póliza de seguro utilizando
      <a name="_Hlk35441109">
        para el efecto la financiación otorgada por FINSU
      </a>
      a través de la plataforma tecnológica de FINSU.
    </p>
    <p>
      <strong>1.3. </strong>
      <strong>CUOTA INICIAL: </strong> Corresponde al porcentaje del valor de la
      PRIMA DE SEGURO que debe ser cancelado por EL COMPRADOR a FINSU, a fin de
      que el valor restante de la PÓLIZA DE SEGURO pueda ser considerado para
      ser financiado a través de la plataforma FINSU.
    </p>
    <p>
      <strong>1.4. </strong>
      <strong>FINSU: </strong> Es una plataforma tecnológica que se caracteriza
      por: a) Financiar al COMPRADOR con el fin de lograr el pago total de la
      prima de la póliza de seguro, cuya adquisición es financiada con recursos
      propios de FINSU. b) Acercar INVERSIONISTAS que quieran adquirir los
      créditos previamente otorgados por FINSU a favor de EL COMPRADOR.
      <a name="_Hlk34809924">
        c) Servir de espacio en donde EL INVERSIONISTA tiene la oportunidad de
        adquirir uno o varios pagarés suscritos por EL COMPRADOR como
        instrumentalización de la obligación a su cargo, a cambio de una
        comisión a favor de FINSU por la administración y recaudo de la
        obligación, cuya probabilidad de pago es asumida por EL INVERSIONISTA
      </a>
      . d) Generar obligaciones a cargo de EL COMPRADOR y a favor de FINSU o de
      los INVERSIONISTAS que adquieran los pagarés cuyo monto corresponde al
      valor a financiar de la póliza de seguro.
    </p>
    <p>
      <a name="_Hlk35441309">
        <strong>
          FINSU no recibe dinero del público para la realización de sus
          operaciones y no recauda dinero distinto a aquel que constituye el
          pago de los créditos desembolsados a favor de los COMPRADORES y sus
          intereses. En esa medida,
        </strong>
      </a>
      <strong>
        FINSU no realiza ningún tipo de operación que pueda considerarse como
        captación o intermediación de dinero del público en general, las cuales
        están estrictamente restringidas a aquellas entidades sujetas a la
        vigilancia de la Superintendencia Financiera de Colombia.
      </strong>
    </p>
    <p>
      Para efectos del presente documento, se entiende que cada vez que se haga
      mención de FINSU esta se entenderá efectuada a FINSU S.A.S. y viceversa.
    </p>
    <p>
      <strong>1.5. </strong>
      <strong>INVERSIONISTA: </strong> Persona natural o jurídica que invierte
      sus recursos en
      <a name="_Hlk35441384">
        la adquisición de títulos valores que representan la obligación
        adquirida por EL COMPRADOR a favor de FINSU como resultado de la
        financiación otorgada para el pago de la póliza de seguro contratada por
        EL COMPRADOR con alguna ASEGURADORA ALIADA, a cambio de un interés de
        carácter remuneratorio.
      </a>
    </p>
    <p>
      <strong>1.6. </strong>
      <strong>PÓLIZA DE SEGURO: </strong> Contrato por medio del cual una
      ASEGURADORA ALIADA se obliga a indemnizar <a name="_Hlk35441613"> </a>el
      daño producido al verificarse el riesgo previsto en el contrato, a cambio
      del pago de una prima de seguro, conforme a lo establecido en el artículo
      1036 y siguientes del Código de Comercio.
    </p>
    <p>
      <strong>1.7. </strong>
      <strong>PRIMA DEL SEGURO: </strong> Precio del seguro que debe ser asumido
      por EL COMPRADOR y es establecido en favor de la ASEGURADORA ALIADA, en
      virtud de la transferencia del riesgo cubierto en la PÓLIZA DE SEGURO. El
      pago total de la PRIMA DEL SEGURO se logra a través del financiamiento por
      parte de FINSU realizado con recursos propios.
    </p>
    <p>
      <strong>1.8. </strong>
      <strong>PRIMA NO DEVENGADA: </strong> Parte del precio del seguro
      previamente cancelado, correspondiente al período de tiempo en que la
      PÓLIZA DE SEGURO no está vigente por la revocación unilateral efectuada
      por parte de la ASEGURADORA ALIADA.
    </p>
    <p>
      <strong>1.9. </strong>
      <strong>VALOR FINANCIADO: </strong> Corresponde al valor del crédito
      otorgado por FINSU para el pago de la PRIMA DE SEGURO, el cual será pagado
      por EL COMPRADOR en cuotas mensuales de acuerdo al plazo pactado con
      FINSU, junto con el interés pactado, que de ninguna manera podrá ser
      superior a la tasa de interés corriente vigente para los créditos de
      consumo aumentada en una mitad, que se encuentre certificada en el período
      correspondiente para el crédito de consumo en la República de Colombia.
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>2. </strong>
      <strong>
        CONDICIONES PARA
        <a name="_Hlk23003092">
          LA FINANCIACIÓN DE LA PÓLIZA DE SEGURO A TRAVÉS DE FINSU
        </a>
      </strong>
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      EL COMPRADOR podrá solicitar la financiación de la PÓLIZA DEL SEGURO que
      satisface sus necesidades, sin montos mínimos o máximos establecidos, en
      la medida en que sea expedida por una ASEGURADORA ALIADA de FINSU.
    </p>
    <p>
      Para acceder a dicha financiación, una vez EL COMPRADOR ha realizado un
      acercamiento previo con la ASEGURADORA ALIADA o un intermediario de
      seguros, deberá registrarse en FINSU y realizar su solicitud, indicando la
      siguiente información respecto de la PÓLIZA DE SEGURO:
    </p>
    <p>- ASEGURADORA ALIADA</p>
    <p>
      - Valor de la PÓLIZA DE SEGURO a financiar incluido el valor
      correspondiente al IVA
    </p>
    <p>
      - Porcentaje de la cuota inicial que
      <a name="_Hlk23759098">EL COMPRADOR </a>está en capacidad de pagar
      (correspondiente al 10%, 20% o 30%) del valor total de la PÓLIZA DE SEGURO
    </p>
    <p>
      - Número de cuotas en que EL COMPRADOR desea pagar el valor financiado,
      seleccionando entre un rango de 4 a 10 cuotas
    </p>
    <p>
      Para continuar con el proceso EL COMPRADOR debe conocer y aceptar el plan
      de pagos simulado en la plataforma sobre el valor financiado, en el que se
      muestra el valor de la cuota inicial, el número de cuotas mensuales y el
      valor de cada una (con discriminación del abono a capital e intereses),
      así como la tasa de interés remuneratoria en términos efectivo anual, la
      tasa de interés moratorio en términos efectivo anual y la tasa de interés
      máxima legal vigente para el período correspondiente. Adicionalmente EL
      COMPRADOR debe aceptar los presentes términos y condiciones.
    </p>
    <p>
      Una vez aceptada la solicitud de financiación de la PÓLIZA DE SEGURO, pasa
      a estudio por parte de FINSU, a fin de determinar la disponibilidad de la
      entidad para otorgar los recursos para la financiación de la PÓLIZA DE
      SEGURO.
    </p>
    <p>
      En caso de otorgar o no la financiación solicitada, FINSU comunicará a EL
      COMPRADOR su decisión a través de correo electrónico, en un lapso no mayor
      a una (1) hora a partir de la solicitud, en la media en que la misma se
      haga en horarios hábiles, esto es entre las 8 am y 5 pm., en su defecto la
      decisión será informada a más tardar a las diez (10) am del siguiente día
      hábil.
    </p>
    <p>
      En el evento de ser aceptada la solicitud de financiación, para continuar
      con el proceso, EL COMPRADOR deberá cancelar el valor de la CUOTA INICIAL
      a través del botón de pagos dispuesto para el efecto en la plataforma
      FINSU.
    </p>
    <p>
      Realizado el pago de la CUOTA INICIAL por parte del COMPRADOR, FINSU
      transferirá el valor total de la PRIMA DEL SEGURO a la ASEGURADORA ALIADA,
      el cual incluye el valor correspondiente a la CUOTA INICIAL previamente
      cancelado por EL COMPRADOR y el VALOR FINANCIADO por FINSU para completar
      el valor total de la PRIMA DEL SEGURO .
    </p>
    <p>
      Realizado el pago de la PRIMA DEL SEGURO, la ASEGURADORA ALIADA expedirá
      la PÓLIZA DE SEGURO solicitada por EL COMPRADOR, lo cual será informado
      por FINSU al COMPRADOR mediante correo electrónico enviado a la dirección
      registrada en la plataforma, en el cual se anexará la PÓLIZA DE SEGURO
      respectiva, así como el plan de pagos que deberá ser tenido en cuenta por
      parte del COMPRADOR.
    </p>
    <p>
      EL COMPRADOR deberá realizar el pago de las cuotas mensuales pactadas
      directamente a FINSU a través de los medios de pago disponibles en la
      página web www.finsu.co
    </p>
    <p>
      FINSU no cobrará comisión alguna al COMPRADOR por el uso de la plataforma,
      cuyo acceso y utilización es de carácter gratuito para éste.
    </p>
    <p>
      <strong>3. </strong>
      <strong>OTROS TÉRMINOS</strong>
    </p>
    <p>
      <strong>3.1. </strong>
      <strong>Interés remuneratorio: </strong> EL COMPRADOR reconocerá y pagará,
      sobre el valor financiado, la tasa de interés remuneratoria del 22% E.A.
      <a name="_Hlk35960234">
        En todo caso, la tasa de interés nunca podrá exceder de 1.5 veces la
        tasa certificada para el crédito de consumo por la Superintendencia
        Financiera de Colombia para el respectivo período
      </a>
      .
      <a name="_Hlk23760612">
        La tasa de interés cobrada, así como aquella que constituye la máxima
        vigente para el periodo respectivo siempre será informada previamente a
        la solicitud de financiamiento de la PÓLIZA DE SEGURO a través del
        simulador de la plataforma tecnológica
      </a>
      . EL COMPRADOR pagará los intereses remuneratorios calculados sobre el
      valor financiado, de forma mensual y por período vencido.
    </p>
    <p>
      <strong>3.2. </strong>
      <strong>Interés moratorio: </strong> Si llegada la fecha de pago de la
      cuota mensual EL COMPRADOR no realiza el pago, se causará el cobro de
      intereses moratorios desde el día siguiente a la fecha de pago, a la tasa
      del 25% EA, la cual será informada previamente a la solicitud de
      financiamiento de la PÓLIZA DE SEGURO a través del simulador de la
      plataforma tecnológica. En todo caso, la tasa de interés nunca podrá
      exceder de 1.5 veces la tasa certificada para el crédito de consumo por la
      Superintendencia Financiera de Colombia para el respectivo período
    </p>
    <p>
      EL COMPRADOR manifiesta que conoce e igualmente acepta de forma expresa
      que, en caso de mora, el pago realizado será imputado de forma inicial a
      gastos de cobranza que surjan del incumplimiento de la obligación,
      posteriormente a los intereses de mora, posteriormente a los intereses
      remuneratorios pendientes de pago. Si posteriormente a esta imputación
      restan montos de dinero, los mismos serán destinados a la cancelación del
      capital del VALOR FINANCIADO.
    </p>
    <p>
      <strong>3.3. </strong>
      <strong>Interés máximo legal vigente:</strong> Para la fecha de
      suscripción de estos términos y condiciones, la tasa de interés máxima que
      puede ser cobrada para el crédito de consumo tanto remuneratoria como
      moratoria, corresponde a la tasa de interés corriente del ____% EA
      aumentada en una mitad, para el periodo comprendido entre el __ de __ y el
      __ de __del año __, de acuerdo a la Resolución No. ___ del __ de ___ del
      año _____ de la Superintendencia Financiera de Colombia.
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>3.4. </strong>
      <strong>Revocación de la póliza de seguro:</strong> Si EL COMPRADOR no
      realiza el pago de la cuota mensual más sus intereses dentro de los 30
      días calendario siguientes a la fecha en que debería ser realizado, además
      de constituirse en mora, FINSU lo informará a la ASEGURADORA ALIADA para
      que ésta proceda a revocar de manera unilateral la póliza de seguro, con
      la correspondiente retención del valor de la PRIMA DEL SEGURO a favor de
      la ASEGURADORA ALIADA, en proporción al tiempo en que estuvo vigente la
      PÓLIZA DE SEGURO.
    </p>
    <p>
      <strong>3.5. </strong>
      <strong>Autorización revocación de la PÓLIZA DE SEGURO: </strong> Con la
      suscripción de los presentes términos y condiciones, EL COMPRADOR declara
      conocer y aceptar que, en el evento de incurrir en incumplimiento de las
      obligaciones a su cargo contraídas en virtud del presente documento en
      favor de FINSU o su cesionario, FINSU podrá solicitar la devolución de la
      PRIMA NO DEVENGADA ante la ASEGURADORA ALIADA y recibir las sumas
      correspondientes por este concepto, con el fin de cubrir el saldo insoluto
      del VALOR FINANCIADO. Así mismo, en caso de terminación del contrato de
      seguro por cualquier otra causa, EL COMPRADOR autoriza a FINSU S.A.S. para
      solicitar, recibir y destinar las sumas de dinero por concepto de la PRIMA
      NO DEVENGADA, para cubrir el saldo insoluto del VALOR FINANCIADO, si a
      ello hubiere lugar.
    </p>
    <p>
      <strong>3.6. </strong>
      <strong>Autorización en caso de pérdida total: </strong> Con la
      suscripción de los presentes términos y condiciones, EL COMPRADOR declara
      conocer y aceptar que, en el evento de terminación de la PÓLIZA DE SEGURO
      por acaecimiento de una pérdida total o de un siniestro con similares
      consecuencias dependiendo del riesgo asegurado, la ASEGURADORA ALIADA
      podrá descontar de la indemnización ocasionada a favor del COMPRADOR, el
      saldo pendiente por cancelar del VALOR FINANCIADO, para posteriormente
      girarlo a favor de FINSU S.A.S., de acuerdo con las instrucciones que
      FINSU dé a la ASEGURADORA ALIADA para el efecto.
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>3.7. </strong>
      <strong>Garantías: </strong> EL COMPRADOR suscribirá de forma electrónica
      un pagaré en blanco y su carta de instrucciones, con el fin de
      instrumentalizar la obligación y poder realizar la cobranza futura en caso
      de requerirse. El pagaré será suscrito en favor de FINSU S.A.S. sin ningún
      tipo de restricción para su negociabilidad.
    </p>
    <p>
      <strong>3.8. </strong>
      <strong>Gastos de cobranza: </strong> En caso de mora, en adición a los
      intereses de mora, EL COMPRADOR deberá cancelar gastos de cobranza por un
      valor de hasta el treinta por ciento (30%) más IVA, sobre el saldo
      pendiente del VALOR FINANCIADO que no haya alcanzado a ser cubierto con la
      PRIMA NO DEVENGADA devuelta por la ASEGURADORA ALIADA. Este concepto será
      cobrado según las gestiones de cobro realizadas y será liquidado a la
      fecha de pago de la obligación en mora por parte de EL COMPRADOR.
    </p>
    <p>
      <strong>3.9. </strong>
      <strong>Normatividad y jurisdicción competente:</strong> Los presentes
      términos y condiciones se interpretan conforme a la ley vigente en
      Colombia, cualquier desacuerdo sobre los mismos deberá ser resuelto de
      manera directa entre FINSU y EL COMPRADOR, dentro de los veinte (20) días
      calendario siguientes a la puesta en conocimiento de la otra parte. Si no
      se logra acuerdo o de requerirse el pago de las obligaciones asumidas en
      el presente documento, la demanda deberá ser presentada ante las
      autoridades judiciales competentes de la ciudad de Bogota D.C.
    </p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>4. </strong>
      <strong>INFORMACIÓN PERSONAL</strong>
    </p>
    <p>
      EL COMPRADOR autoriza a FINSU S.A.S. y a quien en el futuro asuma su
      posición o sus derechos para:
    </p>
    <p>
      1. Consultar ante los operadores de información (Experian, Transunion y/o
      cualquier otra entidad que llegue a manejar bases de datos con los mismos
      objetivos) su información personal, incluyendo los datos financieros y
      comerciales, y la de sus codeudores o avalistas, con el objeto de: a)
      Obtener información sobre las relaciones comerciales establecidas con
      cualquier entidad financiera o comercial; y b) Confirmar datos para
      iniciar o mantener una relación contractual.
    </p>
    <p>
      2. Realizar gestiones de conocimiento y validación de su información a
      través de bases de datos y/o listas restrictivas que tengan por finalidad
      la prevención, detección, monitoreo y control del lavado de activos y el
      financiamiento del terrorismo.
    </p>
    <p>
      3. Ser contactado por FINSU con fines comerciales y promocionales sobre
      sus productos y servicios, o los de terceros con los que FINSU tenga
      relaciones comerciales o alianzas, vía correo electrónico, llamada, SMS,
      Whatsapp o cualquier otro medio conocido o por conocer, a través del cual
      se le pueda contactar.
    </p>
    <p>
      4. Ser contactado por FINSU o por el tercero encargado para el efecto, con
      el fin de realizar las gestiones de cobranza de las obligaciones a cargo
      de EL COMPRADOR, vía telefónica, correo electrónico, mensajes de texto
      SMS, herramientas de conversación instantánea o cualquier otro medio
      conocido o por conocer, a través del cual se le pueda contactar.
    </p>
    <p>
      5. Compartir e intercambiar con sus entidades filiales, matrices y/o
      aliadas su información personal contenida en las bases de datos de FINSU
      con fines comerciales y la realización de actividades de mercadeo y
      publicidad.
    </p>
    <p>
      6. Contratar con terceros localizados en Colombia o en el exterior,
      servicios relacionados con el procesamiento de sus datos con las
      finalidades autorizadas por este medio. Evento en el cual, tales entidades
      tendrán iguales obligaciones de confidencialidad en el manejo de la
      información.
    </p>
    <p>
      7. Compartir, informar, reportar, rectificar o retirar datos sobre el
      cumplimiento de la obligación ante los operadores de información
      (Experian, Transunion y/o cualquier otra entidad que llegue a manejar
      bases de datos con los mismos objetivos) en virtud de lo establecido en la
      Ley 1266 de 2008.
    </p>
    <p>
      8. Recibir la notificación previa de que trata el artículo 12 de la Ley
      1266 de 2008 por correspondencia física o correo electrónico u cualquier
      otro mensaje de datos, incluyendo mensajes de texto SMS y mensajes
      mediante herramientas de conversación instantánea.
    </p>
    <p>
      9. Actualizar su información personal por cualquier medio y/o fuentes
      públicas, incluyendo publicaciones en internet o directorios telefónicos,
      o la posibilidad de actualización a través de contactos o referencias
      suministradas por EL COMPRADOR, respecto de las cuales, este declara que
      cuenta con la autorización del titular para compartir su información
      personal.
    </p>
    <p>
      EL COMPRADOR declara que ha sido informado sobre sus derechos a: i)
      Conocer, actualizar y rectificar sus datos personales; ii) Solicitar
      prueba de la autorización que ha otorgado para el tratamiento de su
      información; iii) Ser informado sobre el uso que se ha dado a la misma;
      iv) Presentar quejas ante la Superintendencia de Industria y Comercio por
      infracción a la ley; v) Revocar la autorización y/o solicitar la supresión
      de su información cuando sea procedente; y vi) Acceder en forma gratuita a
      su información personal al menos una vez al mes. EL COMPRADOR podrá
      obtener mayor información acerca del tratamiento de sus datos personales y
      sus derechos sobre los mismos, accediendo a la política de privacidad de
      FINSU S.A.S. publicada en la página de internet
      <a href="http://www.finsu.co">www.finsu.co</a>. EL COMPRADOR podrá ejercer
      su derecho de habeas datada y en general cualquier otro derecho ante FINSU
      S.A.S., contactándose a través del correo electrónico finsusas@gmail.com o
      número telefónico (1) 4824899 en Bogotá D.C.
    </p>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div id="groupModal2"></div>
  <div class="d-flex flex-column align-items-center" style="padding: 20px 40px">
    <button class="btn close-modal" (click)="onCloseModal()">Cerrar</button>
    <h1>
      Autorización tratamiento de datos
      <br />
      Registro del cliente
    </h1>
    <table>
      <tbody>
        <tr>
          <td width="589">
            <p>
              Autorizo expresamente a FINSU S.A.S. como Responsable del
              tratamiento de la informaci&oacute;n para utilizar mi nombre,
              tel&eacute;fono de contacto, correo electr&oacute;nico,
              informaci&oacute;n financiera y dem&aacute;s datos personales
              compartidos con la compa&ntilde;&iacute;a por medio de este
              formulario y/o en etapas posteriores del proceso con el fin de: a)
              Realizar estudios pre-scoring con el fin de validar la posibilidad
              de encontrar financiaci&oacute;n para la p&oacute;liza de seguro
              que deseo adquirir con una aseguradora, a trav&eacute;s de la
              plataforma de FINSU; b) Realizar consultas en centrales de
              riesgos, fuentes privadas y p&uacute;blicas y dem&aacute;s listas
              que considere pertinentes con el fin de verificar el cumplimiento
              de obligaciones pecuniarias y contractuales; c) Verificar o
              actualizar mi informaci&oacute;n a trav&eacute;s de correo
              f&iacute;sico o electr&oacute;nico, mensajes de texto MMS/SMS,
              mensajer&iacute;a instant&aacute;nea, telef&oacute;nicamente, o a
              trav&eacute;s de cualquier otro medio de comunicaci&oacute;n por
              conocer; d) Almacenar mi informaci&oacute;n en sus bases de datos
              con el fin de gestionar la relaci&oacute;n contractual que surja
              conmigo como Titular de la informaci&oacute;n, incluyendo
              actividades relacionadas con el financiamiento de la p&oacute;liza
              de seguro y actividades de cobranza; e) Ser contactado por FINSU o
              por el tercero encargado para el efecto, con el fin de realizar
              las gestiones de cobranza de las obligaciones a mi cargo,
              v&iacute;a correo electr&oacute;nico, llamada, mensajes de texto
              MMS/SMS, Whatsapp o cualquier otro medio conocido o por conocer, a
              trav&eacute;s del cual se me pueda contactar; f) Enviarme
              informaci&oacute;n considerada de mi inter&eacute;s por
              correspondencia f&iacute;sica o correo electr&oacute;nico,
              mensajes de texto MMS/SMS, mensajer&iacute;a instant&aacute;nea o
              a trav&eacute;s de cualquier otro medio de comunicaci&oacute;n por
              conocer, en desarrollo de actividades publicitarias, de mercadeo,
              ofrecimiento de productos o servicios actuales o nuevos; g)
              Compartir mi informaci&oacute;n con terceros conforme se haga
              necesario para realizar actividades comprendidas dentro del objeto
              social de la compa&ntilde;&iacute;a y/o para el ejercicio de
              actividades relacionadas con estas mismas finalidades; h) Realizar
              encuestas de satisfacci&oacute;n y percepci&oacute;n sobre los
              productos y servicios de la compa&ntilde;&iacute;a por cualquier
              medio; i) Conservarla para fines hist&oacute;ricos y/o
              estad&iacute;sticos y/o para cumplir con obligaciones legales en
              lo que a conservaci&oacute;n de informaci&oacute;n y documentos se
              refiere; j) Atender mis peticiones, quejas o reclamos o
              requerimientos de autoridades en ejercicio de sus funciones.
            </p>
            <p>
              Declaro conocer que como Titular de la informaci&oacute;n, tengo
              derecho a: i) Conocer, actualizar, rectificar o suprimir mis datos
              que reposen en las bases de datos; ii) Solicitar prueba de la
              autorizaci&oacute;n otorgada al Responsable; iii) Ser informado
              sobre el uso que se le ha dado a mi informaci&oacute;n personal;
              iv) Presentar quejas ante la Superintendencia de Industria y
              Comercio; y que podr&eacute; ejercer tales derechos enviando
              comunicaci&oacute;n escrita al correo electr&oacute;nico
              <a href="mailto:finsusas@gmail.com">finsusas@gmail.com</a>.
            </p>
            <p>
              As&iacute; mismo declaro conocer que podr&eacute; obtener mayor
              informaci&oacute;n sobre el tratamiento de mi informaci&oacute;n
              personal a trav&eacute;s de la Pol&iacute;tica de Tratamiento de
              Datos Personales publicada en el siguiente link ________________.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
