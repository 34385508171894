import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { Options } from 'ng5-slider';
import {
  Validators,
  FormGroup,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { FormatterDataService } from 'src/app/services/formatter-data.service';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PolizaService } from 'src/app/services/poliza.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-simulador-plan',
  templateUrl: './simulador-plan.component.html',
  styleUrls: ['./simulador-plan.component.scss'],
})
export class SimuladorPlanComponent implements OnInit {
  public selecSucursales = [
    'AG.VILLAVICENCIO',
    'AGENCIA BOYACA CASANARE',
    'AGENCIA BUCARAMANGA',
    'AGENCIA EJE CAFETERO',
    'AGENCIA GIRARDOT',
    'AGENCIA IBAGUE',
    'AGENCIA IBAGUE',
    'AVIACION BOGOTA',
    'BARRANQUILLA',
    'BOGOTA',
    'CALI',
    'CARTAGENA',
    'CEN ARMENIA',
    'CEN BOGOTA - CALLE 93',
    'CEN BOGOTA – CHICO',
    'CEN BOGOTA ANDES',
    'CEN BOGOTA CHAPINERO',
    'CEN BOGOTA OCCIDENTE',
    'CEN CALI SUR',
    'CEN CUCUTA',
    'CEN MONTERIA',
    'CEN PASTO',
    'CEN REGION CARIBE',
    'CEN SABANA CENTRO',
    'CEN TULUA',
    'CEN URABA',
    'CEN VALLE DE ABURRA SUR',
    'MEDELLIN',
    'NEIVA',
    'PAV – LLANOCENTRO VILLAVICENCIO',
    'PAV 118',
    'PAV BUCARAMANGA CABECERA',
    'PAV CALI PALMETTO',
    'PAV CALLE 33',
    'PAV CEDRITOS',
    'PAV CHIA',
    'PAV DORADO PLAZA',
    'PAV KENNEDY BOGOTA',
    'PAV VILLA DEL_RIO',
    'PRINCIPAL',
    'PVM ACACIAS TRANSITO -  META',
    'PVM ALSACIA',
    'PVM AVENIDA CHILE',
    'PVM AVENTURA – MEDELLIN',
    'PVM BOSA MICENTRO',
    'PVM BOSA TREBOLIS',
    'PVM BUCARAMANGA FLORIDA',
    'PVM BULEVAR',
    'PVM CALIMA',
    'PVM CALIMA CALI',
    'PVM CENTRO SUBA',
    'PVM CHIA',
    'PVM DUITAMA',
    'PVM FACATATIVA PORTICO',
    'PVM FLORESTA',
    'PVM FONTIBON FIESTA',
    'PVM FUSAGASUGA',
    'PVM GRAN ESTACION',
    'PVM GRAN PLAZA BOSA',
    'PVM GRAN PLAZA ENSUEÑO',
    'PVM HAYUELOS',
    'PVM IBAGUE MULTICENTRO',
    'PVM LA CENTRAL – MEDELLIN',
    'PVM LA ESTACIÓN - IBAGUE',
    'PVM MADRID CASABLANCA',
    'PVM MASTER CENTER',
    'PVM MAYORCA',
    'PVM MILENIO PLAZA – BOGOTA',
    'PVM MOLINOS MEDELLIN',
    'PVM MONTERREY',
    'PVM MOSQUERA',
    'PVM MULTIPLAZA',
    'PVM OVIEDO',
    'PVM PASEO SAN RAFAEL',
    'PVM PIEDECUESTA – SANTANDER',
    'PVM PLAZA CENTRAL',
    'PVM PLAZA DE LAS AMÉRICAS',
    'PVM PORTAL 80',
    'PVM PORTOALEGRE',
    'PVM PREMIUM PLAZA - MEDELLIN',
    'PVM PUERTA DEL NORTE – ANTIOQUIA',
    'PVM SALITRE PLAZA',
    'PVM SAN NICOLAS',
    'PVM SOACHA',
    'PVM SOACHA VENTURA',
    'PVM SOGAMOSO',
    'PVM TINTAL PLAZA',
    'PVM TITAN PLAZA BOGOTA',
    'PVM TUNAL',
    'PVM TUNJA UNICENTRO',
    'PVM UNICENTRO MEDELLIN',
    'PVM VIVA ENVIGADO',
    'PVM VIVA LA CEJA',
    'PVM VIVA TUNJA',
    'PVM VIVA VILLAVICENCIO',
    'PVMS FONTIBON VIVA',
  ];

  diasValidos = false;
  ramoOpciones: any = [];
  sucursalesOpciones: any = [];
  valorSeguro = 500000;
  labelValue: string;

  /** La nueva placa a agregar */
  public nuevaPlaca: string = '';
  /** La lista de placas agregadas */
  public placas: string[] = [];

  readonly minValueAllowed: number = 500_000;
  readonly maxValueAllowed: number = 20_000_000;

  metodovolver: boolean = true;
  // insuranceValue = 2000000;
  insuranceValue: number = 0;

  options: Options = {
    //floor: 500000,
    //TODO: Cambiar esto cuando las pruebas dejen de hacerse
    floor: 500000,
    ceil: 10000000,
    step: 10000,
    // step: 500000,
    enforceStep: false,
    enforceRange: false,
    translate: (value: number): string => {
      if (value == 0) {
        return '$' + value;
      } else if (Number.isInteger(value / 1000000)) {
        return '$' + value / 1000000 + '.000.000';
      } else if (Number.isInteger(value / 100000)) {
        let x = value + '';

        if (x.length == 7) {
          return (
            '$' +
            x.substring(0, 1) +
            '.' +
            x.substring(1, 4) +
            '.' +
            x.substring(4, 7)
          );
        } else if (x.length == 6) {
          return '$' + x.substring(0, 3) + '.' + x.substring(3, 6);
        }
      } else {
        let x = value + '';

        if (x.length == 7) {
          return (
            '$' +
            x.substring(0, 1) +
            '.' +
            x.substring(1, 4) +
            '.' +
            x.substring(4, 7)
          );
        } else if (x.length == 6) {
          return '$' + x.substring(0, 3) + '.' + x.substring(3, 6);
        } else return '$';
      }
    },
    getSelectionBarColor: (value: number): string => {
      return '#FC9A0D';
    },
    getPointerColor: (value: number): string => {
      return '#FC9A0D';
    },
  };
  /**Variable para almacenar incio de vigencia */
  fechaInicioVigencia: NgbDateStruct;
  /**Variable para almacenar cuotas sin beneficiario */
  public cuotasSinBeneficiario: number = 0;
  /**Variable para almacenar cuotas con beneficiario */
  public cuotasConBeneficiario: number = 0;
  /**Variable para almacenar porcentaje de cuota inicial */
  porcentajeCuotaInicialParam: number = 0;
  /**VARIABLE PARA ALMACENAR VALOR DEL SEGURO */
  /**vARIABLE PARA ALMACENAR BENEFICIARIO ONOROSO */
  beneficiarioOneroso: any = '';
  /**Variable para almacenar número de cuotas */
  numeroCuotasParam: number;
  empresa: string;
  ramo: string;
  sucursal: string;
  numeroPoliza: string;
  numeroCertificado: string;
  paso1: boolean = true;
  paso2: boolean = false;

  insuranceChanged = new Subject<number>();
  constructor(
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private polizaService: PolizaService,
    private formatterDataService: FormatterDataService
  ) {}

  ngOnInit(): void {
    this.fechaInicioVigencia = {
      year: parseInt(moment().format('YYYY')),
      month: parseInt(moment().format('MM')),
      day: parseInt(moment().format('DD')),
    };
    this.insuranceChanged.pipe(debounceTime(2000)).subscribe(() => {
      this.cuotasPermitidas();
    });
  }

  /**
   * Método para cambiar el valor en caso de que
   *  se pase de los valores permitidos
   */
  exceededValue() {
    return (
      this.insuranceValue > this.maxValueAllowed ||
      this.insuranceValue < this.minValueAllowed
    );
  }

  /**
   * Método para verificar fecha y formato de fecha
   */
  onBlurMethod() {
    if (this.fechaInicioVigencia !== undefined) {
      let fecha =
        this.fechaInicioVigencia.year +
        '/' +
        this.fechaInicioVigencia.month +
        '/' +
        this.fechaInicioVigencia.day;
      fecha = moment(fecha).format('YYYY/MM/DD');
      if (!moment(fecha).isValid()) {
        this.fechaInicioVigencia = undefined;
        Swal.fire(
          'Formato de fecha no valida, por favor indique un formato válido para poder continuar.'
        );
      }
    }
  }

  /**
   * Agrega una placa a la lista si cumple con los requisitos
   * @param {FormControl} placaValidacion El control que nos ofrece las validaciones
   */
  agregarPlaca(placaValidacion: FormControl): void {
    if (placaValidacion.invalid) {
      Swal.fire({
        title: '¡Error!',
        text: 'La placa no cuenta con el formato adecuado.',
        icon: 'error',
      });
      return;
    }
    if (this.placas.length >= 10) {
      Swal.fire({
        title: '¡Error!',
        text: 'Se ha alcanzado el número límite de placas.',
        icon: 'error',
      });
      return;
    }
    if (
      this.placas.some(
        (placa) =>
          placa.toLowerCase().trim() === this.nuevaPlaca.toLowerCase().trim()
      )
    ) {
      Swal.fire({
        title: '¡Error!',
        text: 'La placa ya ha sido agregada.',
        icon: 'error',
      });
      return;
    }
    this.placas.push(this.nuevaPlaca.toUpperCase());
    this.nuevaPlaca = '';
    Swal.fire({
      title: '¡Éxito!',
      text: 'Placa agregada.',
      icon: 'success',
    });
  }

  /**
   * Elimina la placa que se cliquea de la lista
   * @param {number} index El indice de la lista
   */
  eliminarPlaca(index: number): void {
    this.placas.splice(index, 1);
  }

  /**
   * Método para indicar los porcentajes de cuotas incial permitidas
   */
  public cuotaInicialPermitidas() {
    let cuotasIniciales = [];
    if (this.fechaInicioVigencia) {
      let fecha = '';
      if (typeof this.fechaInicioVigencia == 'object') {
        fecha =
          this.fechaInicioVigencia.year +
          '/' +
          this.fechaInicioVigencia.month +
          '/' +
          this.fechaInicioVigencia.day;
      } else {
        fecha = this.fechaInicioVigencia;
      }
      fecha = moment(fecha).format('YYYY/MM/DD');
      let diasDiferenciaVigencia = moment(fecha).diff(moment(), 'days');
      // Diferencia en días desde el inicio de la vigencia y el día actual. Negativo son días en el pasado         "YYYY-MM-DD"
      if (diasDiferenciaVigencia <= -90) {
        this.diasValidos = true;
        return;
      }
      if (diasDiferenciaVigencia <= -61) {
        cuotasIniciales = [0.4];
        this.cuotasSinBeneficiario = 7;
        this.cuotasConBeneficiario = 6;
        this.diasValidos = false;
        return cuotasIniciales;
      }
      if (diasDiferenciaVigencia <= -30 && diasDiferenciaVigencia >= -60) {
        cuotasIniciales = [0.3, 0.4];
        this.cuotasSinBeneficiario = 7;
        this.cuotasConBeneficiario = 6;
        this.diasValidos = false;
        return cuotasIniciales;
      }
      if (diasDiferenciaVigencia <= -16 && diasDiferenciaVigencia > -30) {
        cuotasIniciales = [0.3, 0.4];
        this.cuotasSinBeneficiario = 8;
        this.cuotasConBeneficiario = 7;
        this.diasValidos = false;
        return cuotasIniciales;
      }
      if (diasDiferenciaVigencia <= -1 && diasDiferenciaVigencia > -16) {
        cuotasIniciales = [0.2, 0.3, 0.4];
        this.cuotasSinBeneficiario = 9;
        this.cuotasConBeneficiario = 8;
        this.diasValidos = false;
        return cuotasIniciales;
      } else if (diasDiferenciaVigencia >= 0) {
        cuotasIniciales = [0.15, 0.2, 0.3, 0.4];
        this.cuotasSinBeneficiario = 9;
        this.cuotasConBeneficiario = 8;
      }
      return cuotasIniciales;
    } else {
      return [''];
    }
  }

  public printer() {
    console.log('Printer');
  }

  /**
   * Método para indicar el número de cuotas permitidas
   */
  public cuotasPermitidas() {
    let cuotas = [];
    if (this.insuranceValue && this.insuranceValue > 0) {
      if (this.insuranceValue >= 500000 && this.insuranceValue < 600000) {
        cuotas = [5];
      } else if (
        this.insuranceValue >= 600000 &&
        this.insuranceValue < 700000
      ) {
        cuotas = [5, 6];
      } else if (
        this.insuranceValue >= 700000 &&
        this.insuranceValue < 800000
      ) {
        cuotas = [5, 6, 7];
      } else if (
        this.insuranceValue >= 800000 &&
        this.insuranceValue < 900000
      ) {
        cuotas = [5, 6, 7, 8];
      } else if (
        this.insuranceValue >= 900000 &&
        this.insuranceValue < 1000000
      ) {
        cuotas = [5, 6, 7, 8, 9];
      } else {
        cuotas = [5, 6, 7, 8, 9];
      }
      let i = cuotas.length;
      while (i--) {
        if (
          this.beneficiarioOneroso &&
          cuotas[i] > this.cuotasConBeneficiario
        ) {
          cuotas.splice(i, 1);
        }
        if (
          !this.beneficiarioOneroso &&
          cuotas[i] > this.cuotasSinBeneficiario
        ) {
          cuotas.splice(i, 1);
        }
      }
      return cuotas;
    } else {
      return [''];
    }
  }

  /**
   * Método para imprimir plan de pagos
   * @param X
   */
  imprSelec(X) {
    var ficha = document.getElementById(X);
    window.document.write(ficha.innerHTML);
    window.print();
  }

  actualizarLabel() {
    if (this.insuranceValue < 500000) {
      this.insuranceValue = 500000;
    }
    this.labelValue = 'Precio $' + this.insuranceValue;
  }

  siguiente() {
    if (this.diasValidos === true) {
      Swal.fire(
        '',
        'La fecha inició de la póliza, no debe superar 90 días de vigencia.',
        'warning'
      );
      return;
    }
    if (this.exceededValue()) {
      Swal.fire(
        '¡Error!',
        'El valor del seguro debe estar entre 500.000 y 20.000.000 (COP).',
        'error'
      );
      return;
    }
    this.polizaService.setFechaInicio(this.fechaInicioVigencia);
    if (this.beneficiarioOneroso) {
      this.polizaService.setBeneficiarioOneroso(this.beneficiarioOneroso);
    }
    this.polizaService.setValorSeguro(this.insuranceValue);
    this.polizaService.setPorcentajeCuotaInicial(
      this.porcentajeCuotaInicialParam
    );
    this.polizaService.setNumeroCuotas(this.numeroCuotasParam);
    this.polizaService.setEmpresaAseguradora(this.empresa);
    this.polizaService.setRamo(this.ramo);
    this.polizaService.setSucursal(this.sucursal);
    this.polizaService.setNumeroPoliza(this.numeroPoliza);
    this.polizaService.setNumeroCertificado(this.numeroCertificado);
    this.polizaService.setCuotaInicial(
      this.porcentajeCuotaInicialParam * this.insuranceValue
    );
    this.polizaService.setPlacas(this.placas);

    if (this.polizaService.verificarPolizaNoNulos()) {
      if (this.insuranceValue < this.minValueAllowed) {
        //cambiado a prueba mientras se genera las pruebas en el link real
        Swal.fire(
          '',
          'El valor del seguro a financiar debe ser mayor o igual a $500.000.',
          'warning'
        );
      } else if (this.insuranceValue > this.maxValueAllowed) {
        Swal.fire(
          '',
          'El valor del seguro a financiar debe ser menor o igual a $20.000.000.',
          'warning'
        );
      } else {
        this.paso2 = !this.paso2;
        this.paso1 = !this.paso1;
      }
    } else {
      Swal.fire('', 'Hay campos vacíos o incompletos.', 'warning');
    }

    this.polizaService.savePolizaLocal()
  }

  // Método para llenar opciones de ramo @param {string} event empresa
  llenarOpcionesRamo(event) {
    switch (event) {
      case 'Mundial Seguros':
        this.ramoOpciones = [
          '3 VEHÍCULOS PESADOS DE CARGA',
          '4 AVIACIÓN',
          '20 CORRIENTE DEBIL',
          //"24 RESPONSABILIDAD CIVIL EXTRACONTRACTUAL",
          '40 INFIDELIDAD Y RIESGOS FINANCIEROS',
          '44 CREDITO A LA EXPORTACIÓN',
          '46 CRÉDITO COMERCIAL PARA EMISORES',
          '52 TODO RIESGO CONSTRUCCIÓN Y MONTAJE',
          '60 ARRENDAMIENTO',
          '64 RESPONSABILIDAD CIVIL EXTRACONTRACTUAL - AUTOMÓVILES',
          '70 GRUPO VIDA',
          '74 RESPONSABILIDAD CIVIL EXTRACONTRACTUAL GENERAL',
          '75 ACCIDENTES PERSONALES',
          '76 RESPONSABILIDAD CIVIL DIRECTORES Y ADMIN',
          '77 RESPONSABILIDAD CIVIL PROFESIONAL',
          '78 RC GARANTÍA EXTENDIDA',
          '79 RC FAMILIAR',
          '90 TODO RIESGO EQUIPO Y MAQUINARIA',
        ];
        break;
      case 'SBS Seguros':
        this.ramoOpciones = [
          'RCE: Responsabilidad Civil Extracontractual',
          'D&O: Directors and officers',
          'IRF: Infidelidad y Riesgos financieros',
          'RCP: Ambiental',
          'FILMACIONES',
          'CYBER',
          'ENSAYOS CLINICOS',
          'Todo Riesgo Construcción',
          'Todo Riesgo Montaje',
          'Responsabilidad  Civil Ambiental',
          'Transporte',
          'RCE Derivada de Cumplimiento',
          'Cumplimiento entre Particulares',
        ];
        break;
      default:
        this.ramoOpciones = [];
        break;
    }
  }

  formatterNumber(event) {
    let valueToUpdate = '';
    const formattedNumber = this.formatterDataService.justNumbers(
      event.target.value
    );

    if (formattedNumber) {
      valueToUpdate = this.formatterDataService
        .shortNumber(formattedNumber)
        .toString();
    }

    event.target.value = valueToUpdate;
    this.numeroCertificado = valueToUpdate;
  }
}
