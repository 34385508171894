import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-olvido-contrasena',
  templateUrl: './olvido-contrasena.component.html',
  styleUrls: ['./olvido-contrasena.component.scss'],
})
export class OlvidoContrasenaComponent implements OnInit {
  email: string;
  contrasenaDisabled: boolean = false;
  constructor(
    private authService: AuthService,
    private mainService: MainService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  manejarEnvioFormulario() {
    if (this.mainService.validarEmail(this.email)) {
      this.contrasenaDisabled = true;
      this.authService.forgot(this.email).subscribe((res: any) => {
        this.contrasenaDisabled = false;
        this.router.navigate(['/iniciar-sesion']);
        if (res.success) {
          Swal.fire(
            '¡Éxito!',
            'El correo ha sido enviado de manera exitosa.',
            'success'
          );
        } else {
          Swal.fire('¡Error!', 'El usuario no fue encontrado.', 'error');
        }
      });
    } else {
      Swal.fire('¡Error!', 'El email es inválido.', 'error');
    }
  }
}
