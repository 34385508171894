import { Component, OnDestroy } from '@angular/core';
// import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { MainService } from '../../services/main.service';
import { AppToastService } from '../../services/toast.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss'],
})
export class ContactoComponent implements OnDestroy {
  email: string = null;
  nombre: string = null;
  solicitud: string = null;
  mostrarToast = true;

  constructor(
    private mainService: MainService,
    private authService: AuthService,
    private toastService: AppToastService
  ) {
    if (sessionStorage.getItem('getContact')) {
      const currentUser = this.authService.obtenerUsuarioActual()
      this.email = currentUser.email
      this.nombre = currentUser.nombre
    }
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('getContact')
  }

  async manejarCierre() {
    if (this.esFormularioValido()) {
      console.log("Entre....");
      let result: any = await this.mainService.post('informacion_contacto', {
        email: this.email,
        nombre: this.nombre,
        solicitud: this.solicitud,
      });

      if (result?.error) {
        this.toastService.mostrar(
          'Error',
          'Hubo un problema al mandar tu solicitud',
          'peligro'
        );
      } else {
        this.toastService.mostrar(
          'Éxito',
          'Pronto nos comunicaremos contigo',
          'exito'
        );

        this.mainService.redirigirA('');
      }
    }
  }

  esFormularioValido() {

    if (!this.esEmailValido(this.email)) {
      this.toastService.mostrar(
        'Cuidado',
        'El email es inválido',
        'advertencia'
      );
      return false
    }else{
      let esValido = true;
      if (this.nombre === null || this.solicitud === null) {
        this.toastService.mostrar(
          'Cuidado',
          'El campo nombre y el campo solicitud no pueden ir en vacios.',
          'advertencia'
        );
        return false
      }else{
        return esValido
      }
    }

  }

  esEmailValido(email) {
    const esValido =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return esValido.test(String(email).toLowerCase());
  }
}
