import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidateDataService {
  constructor() {}

  private validatedData = {
    document: {
      length: false,
    },
    email: false,
  };

  private dataToCompare = {
    regexEmail:
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
  };

  getValidatedData() {
    return this.validatedData;
  }

  documentCC(document: number) {
    const length = document.toString().length;

    if (length < 7) {
      this.validatedData.document.length = false;
      return false;
    }

    this.validatedData.document.length = true;
    return true;
  }

  anyEmail(email: string) {
    return this.dataToCompare.regexEmail.test(email) ? true : false;
  }

  anyPhoneNumber(phoneNumber: number) {
    return (
      /^-?\d+$/.test(phoneNumber.toString()) && String(phoneNumber).length == 10
    );
  }
}
