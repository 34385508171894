import { Injectable } from '@angular/core';

type formatTypes = 'justNumbers';

@Injectable({
  providedIn: 'root',
})
export class FormatterDataService {
  formatterCurrency = new Intl.NumberFormat('es-CO',
    { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }
  )

  constructor() {}

  justNumbers(numberToClean: string | number): string | null {
    numberToClean = numberToClean.toString().replace(/\D/g, '');
    if (!numberToClean) return null;
    return (numberToClean);
  }

  shortNumber(numberToShort: number | string) {
    if (!numberToShort) return null;
    if (numberToShort.toString().length <= 10) return numberToShort;
    numberToShort = parseInt(numberToShort.toString().slice(0, -1));
    return numberToShort;
  }

  numberRound(numberToTransform: number) {
    return Math.floor(Math.round(numberToTransform))
  }

  numberCurrency(numberToFormat) {
    return this.formatterCurrency.format(numberToFormat)
  }
}
