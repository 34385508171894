import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class MainService {
  headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, public router: Router) {}
  // public baseURL: String = 'https://finsu.herokuapp.com';
  // public baseURL: String = 'http://localhost:8081';
  public baseURL: String = "https://finsu.imagineapps.co";
  /**
   * Esta función hace un llamado al back para traer toda la colección de objetos
   * @param route La ruta de la cual se van a traer los objetos
   * @returns la respuesta HTTP
   */
  get(ruta: string, paraApi = true) {
    this.headers = this.setHeaders();

    if (paraApi) {
      return this.http.get(`${this.baseURL}/api/${ruta}`, {
        headers: this.headers,
      });
    } else {
      return this.http.get(`${this.baseURL}/${ruta}`, {
        headers: this.headers,
      });
    }
  }
  getPago(ruta: string) {
    this.headers = this.setHeaders();
    console.log('verificando', `${this.baseURL}/api${ruta}`);
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseURL}/api${ruta}`, {
          headers: this.headers,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  /**
   * Este metodo hace un llamado al back para crear un objeto
   * @param ruta La ruta donde el objeto va a ser creado
   * @param data El objeto que va a ser creado
   * @returns La respuesta HTTP
   */
  post(ruta: string, data: any, paraApi = true) {
    this.headers = this.setHeaders();
    return new Promise((resolve, reject) => {
      if (paraApi) {
        this.http
          .post(`${this.baseURL}/api/${ruta}`, data, {
            headers: this.headers,
          })
          .subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
      } else {
        this.http
          .post(`${this.baseURL}/${ruta}`, data, {
            headers: this.headers,
          })
          .subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
      }
    });
  }

  postPago(ruta: string, data: any) {
    this.headers = this.setHeaders();
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.baseURL}/${ruta}`, data, {
          headers: this.headers,
        })
        .subscribe(
          (response) => {
            console.log('respon', response);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  /**
   * Este metodo hace un llamado al back para actualizar un objeto
   * @param ruta La ruta donde el objeto va a ser creado
   * @param data El objeto que va a ser creado
   * @returns La respuesta HTTP
   */
  put(ruta: string, data: any) {
    this.headers = this.setHeaders();
    return new Promise((resolve, reject) => {
      this.http
        .put(`${this.baseURL}/api/${ruta}`, data, {
          headers: this.headers,
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  verificarPatronContrasena(contrasena) {
    const regex = new RegExp('^(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
    const valid = regex.test(contrasena);
    return valid;
  }

  /**
   * Verifica que una cadena no este vacia
   * @param campo cadena de texto que representa el campo
   * @returns booleano que representa la validación
   */
  esInputNoVacio(campo: string) {
    let valido = true;
    if (!campo || /^\s*$/.test(campo)) {
      valido = false;
    }
    return valido;
  }

  /**
   * Verifica que un string sea una dirección un telefono valido
   * @param telefono string que representa un telefono
   * @returns un booleano si el telefono es  valido o no
   */
  validarTelefono(telefono) {
    let esTelefono =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
        telefono
      );
    return esTelefono;
  }

  /**
   * Verifica que un email sea valido
   * @param email
   * @returns boleando con que representa la verificación
   */
  validarEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   * This function sets the value of the headers needed to authorize the access for other functions.
   * @returns The token as the authorization header.
   */
  setHeaders() {
    this.headers = new HttpHeaders();
    const token = localStorage.getItem('id_token');
    let headers = new HttpHeaders();

    if (token) {
      headers = this.headers.append('Content-Type', 'application/json');
      headers = this.headers.append('Authorization', token);
      return headers;
    }

    return headers;
  }
  /**
   * Redirige a una nueva ruta dentro de la misma pagina
   * @param ruta A donde se va a dirigir, estas son las rutas puestas en app-routing.module.ts
   */
  redirigirA(ruta: string) {
    this.router.navigate([ruta]);
  }
}
