<div class="fondo">
  <!-- <ngb-toast
    *ngIf="mostrarToast"
    (hide)="mostrarToast = false"
    class="toast-contacto"
    animation="true"
    delay="10000"
  >
    <h3>Error</h3>
    <p>Hubo un problema al enviar la solicitud, intentelo más tarde.</p>
  </ngb-toast> -->
  <div class="container">
    <div class="row card-inicio">
      <div class="col-md-12">
        <p class="text-contacto">Contacto</p>
      </div>
      <div class="col-md-6">
        <p class="text-input">Nombre</p>
        <input
          [(ngModel)]="nombre"
          type="text"
          name="nombre"
          placeholder="Escribe aquí"
        />
      </div>
      <div class="col-md-6 margin-col">
        <p class="text-input">Email</p>
        <input
          [(ngModel)]="email"
          type="text"
          name="email"
          placeholder="Escribe aquí"
        />
      </div>
      <div class="col-md-12 margin-col">
        <p class="text-input">Solicitud</p>
        <textarea
          [(ngModel)]="solicitud"
          name="solicitud"
          id=""
          cols="30"
          rows="10"
          placeholder="Escribe aquí"
        ></textarea>
      </div>
      <div class="col-md-6">
        <button (click)="manejarCierre()" class="btn">Enviar</button>
      </div>
    </div>
  </div>
</div>
