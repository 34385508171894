<main class="main-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-10">
        <div class="crear-header">
          <div class="crear-volver">
            <em routerLink="/iniciar-sesion" class="fa fa-angle-left icono"></em>
          </div>
          <div class="crear-titulo">
            Bienvenido a Finsu, selecciona el perfil que deseas crear
          </div>
          <br>
          <br>
        </div>
      </div>


      <div class="col-md-5">
        <div
          class="card crear-cliente"
          routerLink="/crear-cuenta-cliente/cliente"
        >
          <img
            class="perfil"
            src="assets/recursos/financiar/cliente.png"
            alt="cliente"
          />

          <div class="cliente-contenido card-contenido">
            <h3 class="contenido-titulo">Cliente</h3>
            <p class="contenido-descripcion">
              Paga tu seguro a cuotas desde tu computador o celular y podrás
              empezar a disfrutar de tu seguro y de sus respectivos beneficios
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-5">
        <div
          class="card crear-intermediaro"
          routerLink="/crear-cuenta-cliente/intermediario"
        >
          <img
            class="perfil"
            src="assets/recursos/financiar/intermediario.png"
            alt="intermediario"
          />
          <div class="intermediario-contenido card-contenido">
            <h3 class="contenido-titulo">Intermediario</h3>
            <p class="contenido-descripcion">
              Estás vinculado a una aseguradora y podrás crear todas las pólizas
              de tus clientes, hacer seguimiento y estar actualizado de todos
              los movimientos
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
