<main role="main" class="masthead">
    <div class="container">
        <ng-template #customLoadingMultiUso>
            <div class="load">
            </div>
        </ng-template>

        <ngx-loading [show]="cargandoMulti" [config]="{ backdropBorderRadius: '3px' }"
            [template]="customLoadingMultiUso">
        </ngx-loading>
    </div>

</main>
