import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppToastService {
  toasts: any[] = [];

  /**
   * Muestra un nuevo modal
   * @param titulo Titulo que llevara el modal
   * @param cuerpo El cuerpo del modal
   * @param style Si es de advertencia, peligro o éxito
   */
  mostrar(titulo: string, cuerpo: string, style: string) {
    let tipo = {};
    switch (style) {
      case 'exito':
        tipo = 'exito';
        break;
      case 'peligro':
        tipo = 'peligro';
        break;
      case 'advertencia':
        tipo = 'advertencia';
        break;

      default:
        break;
    }

    this.toasts.push({ titulo, cuerpo, tipo });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t != toast);
  }
}
