<div class="fondo-purpura">
    <div class="container">
        <app-header></app-header>
    </div>
</div>
<div class="toast-container">
    <app-toasts></app-toasts>
</div>
<div class="content-app">
    <router-outlet> </router-outlet>
</div>