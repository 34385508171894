import { Component } from '@angular/core';
import { AppToastService } from '../../services/toast.service';
@Component({
  selector: 'app-toasts',
  templateUrl: './apptoast.component.html',
  styleUrls: ['./apptoast.component.scss'],
})
export class AppToastsComponent {
  constructor(public toastService: AppToastService) {}
}
