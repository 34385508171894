import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UtilidadesService } from '../../services/utilidades.service';
import { AuthService } from '../../services/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-iniciar-sesion',
  templateUrl: './iniciar-sesion.component.html',
  styleUrls: ['./iniciar-sesion.component.scss'],
})
export class IniciarSesionComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  /** Boolean que determina si mostrar el campo de correo para olvidé mi contraseña o no */
  public mostrarForgotPass: boolean = false;
  /** Email del usuario para recuperar contraseña */
  public emailRecoverPass: string = '';
  muestra: string = 'password';
  constructor(
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private customValidator: UtilidadesService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.registerForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        //username: ['', [Validators.required], this.customValidator.userNameValidator.bind(this.customValidator)],
        password: ['', Validators.compose([Validators.required])],
        //confirmPassword: ['', [Validators.required]],
        //password: ['', [Validators.required]],
      } /*,
      {
        validator: this.customValidator.MatchPassword('password', 'confirmPassword'),
      }*/
    );
  }
  get registerFormControl() {
    return this.registerForm.controls;
  }
  togglePass() {
    if (this.muestra === 'password') {
      this.muestra = 'text';
      return true;
    } else {
      this.muestra = 'password';
      return true;
    }
  }
  public onRecoverPass() {
    this.spinner.show();

    if (this.emailRecoverPass && this.emailRecoverPass !== '') {
      this.emailRecoverPass = this.emailRecoverPass.toLowerCase().trim();
      this.authService.forgot(this.emailRecoverPass).subscribe(
        (result) => {
          this.spinner.hide();

          this.mostrarForgotPass = false;
          if (result['success']) {
            Swal.fire(
              '¡Éxito!',
              'Hemos enviado un correo con la nueva contraseña a tu correo.',
              'success'
            );
          } else {
            this.mostrarForgotPass = false;

            Swal.fire(
              '¡Error!',
              'Hubo un error al recuperar la contraseña. Por favor intente de nuevo en unos minutos.',
              'warning'
            );
          }
        },
        (err) => {
          Swal.fire(
            '¡Error!',
            'Hubo un error al recuperar la contraseña. Por favor intente de nuevo en unos minutos.',
            'warning'
          );
        }
      );
    } else {
      this.spinner.hide();

      Swal.fire(
        '¡Error!',
        'Por favor ingresar un correo para recuperar contraseña.',
        'warning'
      );
    }
  }
  public onOlvideContrasena() {
    this.mostrarForgotPass = true;
  }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.valid) {
      this.spinner.show();

      //alert('Form Submitted succesfully!!!\n Check the values in browser console.');
      console.table(this.registerForm.value);
      let obj = this;
      this.authService.authenticateUser(this.registerForm.value).subscribe(
        (data) => {
          this.spinner.hide();

          if (data['success']) {
            data['user']._id = data['success'].id;
            ['success'];
            this.authService.storeUserData(data['token'], data['user']);
            this.authService.settings.userType =
              data['user'].tipo.toLowerCase();
            if (data['user'].passwordTemp) {
              Swal.fire(
                '¡Éxito!',
                'Por favor, cambiar contraseña temporal.',
                'success'
              );
              return obj.router.navigate(['cambiar-contrasena']);
            }

            if (data['user'].tipo == 'Inversionista') {
              Swal.fire(
                '¡Éxito!',
                'Se ha iniciado sesión con éxito.',
                'success'
              );
              obj.router.navigate(['usuario']);
            } else if (data['user'].tipo == 'Usuario') {
              Swal.fire(
                '¡Éxito!',
                'Se ha iniciado sesión con éxito.',
                'success'
              );
              obj.router.navigate(['usuario']);
            } else if (data['user'].tipo == 'Intermediario') {
              Swal.fire(
                '¡Éxito!',
                'Se ha iniciado sesión con éxito.',
                'success'
              );
              obj.router.navigate(['intermediario']);
            } else {
              Swal.fire(
                '¡Error!',
                'El usuario no es de tipo comprador ni inversionista.',
                'warning'
              );
            }
            // })
          } else {
            Swal.fire('¡Error!', 'Usuario no encontrado.', 'warning');
          }
        },
        (err) => {
          this.spinner.hide();

          Swal.fire(
            '¡Error!',
            'No se ha podido iniciar sesión. Por favor intente de nuevo.',
            'warning'
          );
        }
      );
    }
  }
}
