<div class="fondo" *ngIf="paso1">
  <div class="container">
    <div class="padding-simulador">
      <p class="text-left title" routerLink="/usuario">
        <em class="fa fa-angle-left"></em> &nbsp;Ingresa los datos de tu seguro
      </p>
      <div class="row card-simula">
        <div class="col-md-6">
          <p class="text-input">Fecha de inicio de vigencia *</p>
          <input
            (click)="d.toggle()"
            (blur)="onBlurMethod()"
            [(ngModel)]="fechaInicioVigencia"
            class="input"
            placeholder="AAAA/MM/DD"
            name="dp"
            ngbDatepicker
            #d="ngbDatepicker"
          />
        </div>

        <div class="col-md-6">
          <p class="text-input">Beneficiario oneroso (opcional)</p>
          <input
            [(ngModel)]="beneficiarioOneroso"
            type="text"
            class="input"
            placeholder="Escribe aquí"
          />
        </div>

        <div class="col-md-6">
          <div class="slider-input">
            <p class="text-input">
              Ingresa el valor exacto de tu póliza (incluido IVA)
            </p>
            <input
              class="input"
              type="string"
              inputmode="numeric"
              (keyup)="insuranceChanged.next($event)"
              (userChange)="actualizarLabel()"
              [(ngModel)]="insuranceValue"
              currencyMask
              [options]="{
                suffix: ' COP ',
                thousands: '.',
                decimal: ',',
                precision: 0,
                align: 'center'
              }"
            />
          </div>
        </div>

        <div class="col-md-6">
          <p class="text-input">Porcentaje de la cuota inicial</p>
          <select
            class="input"
            name="porcentaje"
            placeholder="Escribe aquí"
            [(ngModel)]="porcentajeCuotaInicialParam"
          >
            <option value="0" selected disabled>Seleccione una opción</option>
            <option
              value="{{ cuota }}"
              *ngFor="let cuota of cuotaInicialPermitidas()"
            >
              {{ cuota * 100 }}% del valor total ({{
                insuranceValue * cuota | currency : "COP " : "symbol" : "1.0-0"
              }})
            </option>
          </select>
        </div>

        <div class="col-md-6">
          <p class="text-input">Número de cuotas de tu seguro</p>
          <select
            class="input"
            name="cuotas"
            placeholder="Escribe aquí"
            [(ngModel)]="numeroCuotasParam"
          >
            <option value="">Seleccione una opción</option>
            <option
              value="{{ cuotas }}"
              *ngFor="let cuotas of cuotasPermitidas()"
            >
              {{ cuotas }} cuotas
            </option>
          </select>
        </div>

        <div class="col-md-6">
          <p class="text-input">Compañía de seguros</p>
          <select
            class="input"
            (change)="llenarOpcionesRamo(empresa)"
            [(ngModel)]="empresa"
          >
            <option value="">Seleccione una opción</option>
            <option value="Mundial Seguros">Mundial Seguros</option>
            <option value="SBS Seguros">SBS Seguros</option>
          </select>
        </div>

        <div class="col-md-6">
          <p class="text-input">Sucursal</p>
          <select class="input" [(ngModel)]="sucursal">
            <option value="">Seleccione una opción</option>
            <option *ngFor="let s of selecSucursales" [value]="s">
              {{ s }}
            </option>
          </select>
        </div>

        <div class="col-md-6">
          <p class="text-input">Ramo</p>
          <select class="input" [(ngModel)]="ramo">
            <option value="">Seleccione una opción</option>
            <option *ngFor="let r of ramoOpciones" value="{{ r }}">
              {{ r }}
            </option>
          </select>
        </div>

        <ng-container
          *ngIf="
            ramo === '3 VEHÍCULOS PESADOS DE CARGA' ||
            ramo === '64 RESPONSABILIDAD CIVIL EXTRACONTRACTUAL - AUTOMÓVILES'
          "
        >
          <div class="col-md-6">
            <div>
              <label class="text-input" for="nuevaPlaca">Placas*</label>
              <input
                class="input"
                placeholder="ABC123"
                type="text"
                name="nuevaPlaca"
                [(ngModel)]="nuevaPlaca"
                #nuevaPlacaU="ngModel"
                pattern="^[a-zA-Z]{3}[0-9]{3}$"
                maxlength="6"
              />

              <button
                type="button"
                (click)="agregarPlaca(nuevaPlacaU)"
                class="crear input"
                [class.margin-bottom24]="
                  nuevaPlacaU.invalid &&
                  (nuevaPlacaU.dirty || nuevaPlacaU.touched)
                "
              >
                Agregar placa
              </button>

              <div
                *ngIf="
                  nuevaPlacaU.invalid &&
                  (nuevaPlacaU.dirty || nuevaPlacaU.touched)
                "
                class="invalid"
              >
                El formato de la placa es incorrecto.
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div
              *ngIf="placas.length > 0 && placas.length <= 10; else noPlacas"
            >
              <p class="text-input">
                Se han agregado las siguientes placas:
                <span
                  *ngFor="let placa of placas; index as i"
                  (click)="eliminarPlaca(i)"
                  class="placa-texto"
                  >{{ placa }}{{ i === placas.length - 1 ? "" : ", " }}</span
                >
              </p>
            </div>
            <ng-template #noPlacas>
              <p class="text-input">No se han agregado placas</p>
            </ng-template>
          </div>
        </ng-container>

        <div class="col-md-6">
          <p class="text-input">Número de la póliza</p>
          <input
            type="text"
            [(ngModel)]="numeroPoliza"
            class="input selectPorcentaje"
            placeholder="Escribe aquí"
          />
        </div>

        <div class="col-md-6">
          <p class="text-input">Número de certificado</p>
          <input
            type="text"
            [(ngModel)]="numeroCertificado"
            class="input selectPorcentaje"
            placeholder="Escribe aquí"
          />
        </div>
      </div>
      <div class="row">
        <button class="btn btn-cotizar" (click)="siguiente()">Cotizar</button>
      </div>
    </div>
  </div>
</div>
<div class="fondo" *ngIf="paso2">
  <div class="container" id="imprime">
    <app-plan-de-pagos
      [(numeroCuotas)]="numeroCuotasParam"
      [(metodovolver)]="metodovolver"
      [(porcentajeCuotaInicial)]="porcentajeCuotaInicialParam"
      [(valorTotal)]="insuranceValue"
    ></app-plan-de-pagos>
    <div class="row margin-bot">
      <button class="btn btn-cotizar" routerLink="/documentos">Empezar</button>
    </div>
  </div>
</div>
