<div id="grid1-modal" class="celda">
  <ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px' }"
    [template]="customLoadingTemplate"
  >
  </ngx-loading>
  <div class="row">
    <div class="col-12 text-center banks">
      <label for="" class="labelFinsu">Seleccione el banco</label>
      <div class="selectContainer relative selectPorcentaje">
        <select class="inputFinsu inputModal" [(ngModel)]="banco">
          <option *ngFor="let banco of bancos" value="{{ banco.bankCode }}">
            {{ banco.bankName }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12">
      <button
        id="botonPagar"
        class="btnFinsuBlue"
        *ngIf="
          poliza !== true && poliza.estado !== 'Cuota inicial pago rechazado'
        "
        (click)="crearTransaccion()"
      >
        Pagar
      </button>
      <button
        id="botonPagar"
        class="btnFinsuBlue"
        *ngIf="poliza == true && !poliza.estado"
        (click)="crearPoliza()"
      >
        Pagar
      </button>
      <button
        id="botonPagar"
        class="btnFinsuBlue"
        *ngIf="poliza.estado === 'Cuota inicial pago rechazado'"
        (click)="pagoDeCuotaIncialPendiente()"
      >
        Pagar Cutota inical
      </button>
    </div>
  </div>
</div>
