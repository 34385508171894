<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Obteniendo datos</p>
</ngx-spinner>

<ng-container *ngIf="mostrar">
  <div (click)="cerrarModal()" class="modal-background"></div>

  <div class="modal container card-perfil">
    <h2 class="w-100 text-center">Cambiar contraseña</h2>
    <div class="row m-4">
      <div
        class="col-12 d-flex flex-column justify-content-center align-items-center"
      >
        <label class="label-perfil w-100" for="contrasena-antigua"
          >Contraseña anterior</label
        >
        <img
          (click)="cambiarInputAntigua()"
          class="ojo-input"
          src="assets/recursos/logos-iconos/ojo-off.png"
          alt="ojo"
        />
        <input
          [(ngModel)]="contrasenaAntigua"
          class="input-perfil"
          id="nombre"
          [type]="mostrarAntigua"
          placeholder="Escribe aquí"
        />

        <p *ngIf="errorAct" class="w-100 text-center text-danger error">
          {{ errorAct }}
        </p>
      </div>

      <div
        class="input-contrasena col-12 d-flex flex-column justify-content-center align-items-center mt-4"
      >
        <label class="label-perfil w-100" for="contrasena-antigua"
          >Nueva contraseña</label
        >
        <img
          (click)="cambiarInputNueva()"
          class="ojo-input"
          src="assets/recursos/logos-iconos/ojo-off.png"
          alt="ojo"
        />
        <input
          [(ngModel)]="contrasenaNueva"
          class="input-perfil"
          id="nombre"
          [type]="mostrarNueva"
          placeholder="Crea una nueva"
        />
      </div>

      <p *ngIf="errorNueva" class="w-100 text-center text-danger error">
        {{ errorNueva }}
      </p>

      <div
        class="col-12 d-flex flex-column justify-content-center align-items-center mt-4"
      >
        <button
          (click)="manejarCambioContrasena()"
          class="boton-contrasena boton"
        >
          Cambiar
        </button>
      </div>
    </div>
  </div>
</ng-container>

<div class="wrapper-perfil">
  <div class="container card-perfil">
    <div class="row w-100">
      <h2 class="col-12 text-left">Mis datos</h2>
    </div>

    <div class="row mt-2 w-100 section-data">
      <div class="col-6 d-flex flex-column">
        <label class="label-perfil" for="nombre">Nombre</label>
        <input
          class="input-perfil"
          id="nombre"
          type="text"
          [(ngModel)]="datos.nombre"
          disabled
        />
      </div>
      <div class="col-6 d-flex flex-column section-data">
        <label class="label-perfil" for="nombre">Apellido</label>
        <input
          class="input-perfil"
          id="nombre"
          type="text"
          [(ngModel)]="datos.apellido"
          disabled
        />
      </div>
    </div>
    <div class="row mt-2 w-100 section-data">
      <div class="col-6 d-flex flex-column">
        <label class="label-perfil" for="nombre">Email</label>
        <input
          class="input-perfil"
          id="nombre"
          type="text"
          [(ngModel)]="datos.email"
          disabled
        />
      </div>
      <div class="col-6 d-flex flex-column section-data">
        <label class="label-perfil" for="nombre">Perfil</label>
        <input
          class="input-perfil"
          id="nombre"
          type="text"
          [(ngModel)]="datos.tipo"
          disabled
        />
      </div>
    </div>
    <div class="row mt-2 w-100 section-data">
      <div class="col-6 d-flex flex-column">
        <label class="label-perfil" for="nombre">Dirección</label>
        <input
          class="input-perfil"
          id="nombre"
          type="text"
          [(ngModel)]="datos.direccion"
          disabled
        />
      </div>
      <div class="col-6 d-flex flex-column">
        <label class="label-perfil" for="nombre">Ciudad</label>
        <input
          class="input-perfil"
          id="nombre"
          type="text"
          [(ngModel)]="datos.ciudad"
          disabled
        />
      </div>
    </div>

    <div class="row mt-2 w-100 section-data">
      <div class="col-6 d-flex flex-column">
        <label class="label-perfil" for="nombre">Tipo de documento</label>
        <input
          class="input-perfil"
          id="nombre"
          type="text"
          [(ngModel)]="documento"
          disabled
        />
      </div>
      <div class="col-6 d-flex flex-column">
        <label class="label-perfil" for="nombre">Número de documento</label>
        <input
          class="input-perfil"
          id="nombre"
          type="text"
          [(ngModel)]="datos.numeroDocumento"
          disabled
        />
      </div>
    </div>
    <div class="row mt-2 w-100 section-data">
      <div class="col-6 d-flex flex-column">
        <label class="label-perfil" for="nombre">Celular</label>
        <input
          class="input-perfil"
          id="nombre"
          type="text"
          [(ngModel)]="datos.telefono"
          disabled
        />
      </div>
      <div class="col-6 d-flex flex-column justify-content-center">
        <p (click)="mostrarModal()" class="cambio-contrasena">
          Cambiar contraseña
        </p>
      </div>
    </div>

    <div class="mt-2 w-100 section-data">
      <p class="mb-0 text-center">
        Si desea cambiar su información deberá contactarse con la
        <a
          class="cambio-contrasena"
          (click)="getInContact()"
          routerLink="/contacto"
          >empresa aquí.</a
        >
      </p>
    </div>
    <!-- <div class="row mt-4 w-100">
      <div class="col-6 d-flex flex-column">
        <button (click)="manejarFormulario()" class="boton">
          Actualizar datos
        </button>
      </div>
    </div> -->
  </div>
</div>
