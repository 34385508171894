<div class="row padding-row">
  <div class="col-md-6 d-none d-md-block">
    <img
      src="assets/recursos/financiar/ilustracion-financia.png"
      class="banner"
      alt="Imagen banner"
    />
  </div>
  <div class="col-md-6">
    <h1 class="banner-titulo">Financia tu seguro</h1>
    <p class="banner-descripcion">
      Sabemos que no es fácil pagar de contado una póliza de seguros. Por eso
      <strong>Finsu</strong> te ofrece una financiación para que pagues a cuotas con una
      baja tasa de interés y fácilmente a través de internet. Tendrás que pagar
      una cuota inicial y el resto será asumido por un inversionista al que
      luego le pagarás cuotas mensuales.
    </p>
    <button class="btn banner-btn fondo-morado" routerLink="/financiar">
      Quiero financiar
    </button>
  </div>
  <div class="col-md-6 d-block d-md-none">
    <img
      src="assets/recursos/financiar/ilustracion-financia.png"
      class="banner"
      alt="Imagen banner"
    />
  </div>
</div>
