<section class="fondo">
  <div class="atras mt-5">
    <em routerLink="/iniciar-sesion" class="fa fa-angle-left icono-atras"></em>
  </div>

  <div class="formulario">
    <h2 class="font-weight-bold">Olvidaste tu contraseña</h2>

    <p class="formulario-texto">
      Enviaremos un mensaje a tu correo para que <br />
      puedas recuperar tu contraseña pronto.
    </p>

    <div class="d-flex flex-column mb-3">
      <label class="label" for="email">Correo electrónico</label>
      <input
        [(ngModel)]="email"
        class="input"
        type="text"
        name="email"
        placeholder="Escribe aquí"
      />
    </div>

    <button [disabled]="contrasenaDisabled" (click)="manejarEnvioFormulario()" class="boton-enviar">
      Enviar
    </button>
  </div>
</section>
