<div id="groupModal"></div>
<div class="contenedor-politica">
  <h1 class="politica-titulo">
    <strong>POLITICA DE TRATAMIENTO DE DATOS PERSONALES DE FINSU S.A.S.</strong>
  </h1>
  <br />
  <ol>
    <li>
      <strong
        >Objetivo &ndash; Responsable del Tratamiento de los Datos
        Personales</strong
      >
    </li>
  </ol>
  <p>
    La presente Pol&iacute;tica documenta la forma en que FINSU S.A.S.
    identificada con NIT 901307632 - 2 (en adelante &ldquo;Finsu&rdquo;),
    actuando como Responsable de la informaci&oacute;n, realiza el Tratamiento
    de los datos personales de sus clientes, inversionistas, funcionarios,
    proveedores y terceros en general.
  </p>
  <p>
    Con el fin de garantizar la protecci&oacute;n de los datos personales,
    incluyendo el derecho que tienen los Titulares de conocer, actualizar y
    rectificar la informaci&oacute;n que repose en las Bases de Datos, Finsu
    informa que se encuentra domiciliada en la Calle 63 # 28A - 62 de la ciudad
    de Bogot&aacute; D.C., disponiendo como canales de contacto el correo
    electr&oacute;nico
    <a href="mailto:finsusas@gmail.com">finsusas@gmail.com</a> y/o el
    n&uacute;mero telef&oacute;nico (1) 4824899 en Bogot&aacute; D.C.
  </p>
  <ol start="2">
    <li><strong>Alcance </strong></li>
  </ol>
  <p>
    Este documento tiene como objeto informar en general a todas las personas
    que hayan entregado, o que en el futuro entreguen sus datos personales a
    Finsu sobre la Pol&iacute;tica de Tratamiento de sus datos personales, la
    cual es aplicable a todas las Bases de Datos y datos personales en ellas
    contenidos, de cuyo Tratamiento Finsu ostente la calidad de Responsable,
    conforme a la Ley 1581 de 2012 sobre protecci&oacute;n de datos personales y
    dem&aacute;s normatividad que la adicione, modifique, complemente o
    reglamente.
  </p>

  <p>
    Para efectos de la presente Pol&iacute;tica, los t&eacute;rminos que se
    se&ntilde;alan a continuaci&oacute;n tienen los siguientes significados, de
    acuerdo a lo establecido en la Ley 1581 de 2012 y el Decreto 1074 de 2015,
    Decreto &Uacute;nico Reglamentario del Sector Comercio, Industria y Turismo:
  </p>

  <ol>
    <li>
      Autorizaci&oacute;n: Consentimiento previo, expreso e informado del
      Titular para llevar a cabo el Tratamiento de datos personales;
    </li>
  </ol>

  <ol>
    <li>
      Aviso de privacidad: Comunicaci&oacute;n verbal o escrita por medio la
      cual el Responsable informa al Titular acerca de la existencia de la
      Pol&iacute;tica de Tratamiento de datos personales que le es aplicable, la
      forma de acceder a las mismas y las finalidades del Tratamiento que se
      pretende dar a los datos personales. El aviso de privacidad es utilizado
      &uacute;nicamente en caso de no poder poner a disposici&oacute;n del
      p&uacute;blico la Pol&iacute;tica de Tratamiento de Datos Personales;
    </li>
  </ol>

  <ol>
    <li>
      Base de Datos: Conjunto organizado de datos personales que es objeto de
      Tratamiento;
    </li>
  </ol>

  <ol>
    <li>
      Dato personal: Cualquier informaci&oacute;n vinculada o que pueda
      asociarse a una o varias personas naturales determinadas o determinables;
    </li>
  </ol>

  <ol>
    <li>Dato privado: Aquel dato que es solo relevante para su Titular;</li>
  </ol>

  <ol>
    <li>
      Dato semiprivado: Dato que no tiene naturaleza &iacute;ntima, reservada,
      ni p&uacute;blica y cuyo conocimiento o divulgaci&oacute;n puede interesar
      a su titular y a cierto sector o grupo de personas o a la sociedad en
      general.&nbsp;
    </li>
  </ol>

  <ol>
    <li>
      Dato p&uacute;blico: Dato que no es ni semi-privado, ni privado, ni
      sensible, y que por su naturaleza puede estar contenido en registros
      p&uacute;blicos, documentos p&uacute;blicos, gacetas, boletines oficiales,
      y sentencias judiciales debidamente ejecutoriadas, que no est&eacute;n
      sometidos a reserva alguna, por lo cual pueden ser adquiridos por
      cualquier persona sin necesidad de autorizaci&oacute;n para el efecto.
    </li>
  </ol>

  <ol>
    <li>
      Dato sensible: Aquel que afecta la intimidad del Titular o cuyo uso
      indebido puede generar su discriminaci&oacute;n, tal como el que revele el
      origen racial o &eacute;tnico, la orientaci&oacute;n pol&iacute;tica, las
      convicciones religiosas o filos&oacute;ficas, la pertenencia a sindicatos,
      organizaciones sociales, de derechos humanos o que promueva intereses de
      cualquier partido pol&iacute;tico o que garantice los derechos y
      garant&iacute;as de partidos pol&iacute;ticos de oposici&oacute;n,
      as&iacute; como los datos relativos a la salud, a la vida sexual y los
      datos biom&eacute;tricos;
    </li>
  </ol>

  <ol>
    <li>
      Responsable del Tratamiento: Persona natural o jur&iacute;dica,
      p&uacute;blica o privada, que por s&iacute; misma o en asocio con otros,
      decide sobre la base de datos y/o el Tratamiento de los datos;
    </li>
  </ol>

  <ol>
    <li>
      Encargado del Tratamiento: Persona natural o jur&iacute;dica,
      p&uacute;blica o privada, que por s&iacute; misma o en asocio con otros,
      realiza el Tratamiento de datos personales por cuenta del Responsable del
      Tratamiento y siguiendo sus instrucciones;
    </li>
  </ol>

  <ol>
    <li>
      Titular: Persona natural cuyos datos personales son objeto de Tratamiento;
    </li>
  </ol>

  <ol>
    <li>
      Tratamiento: Cualquier operaci&oacute;n o conjunto de operaciones sobre
      datos personales, tales como la recolecci&oacute;n, almacenamiento, uso,
      circulaci&oacute;n o supresi&oacute;n;
    </li>
  </ol>

  <ol>
    <li>
      Transferencia: La transferencia de datos tiene lugar, cuando el
      Responsable y/o Encargado del Tratamiento de datos personales, ubicado en
      Colombia, env&iacute;a la informaci&oacute;n o los datos personales a un
      receptor, que a su vez es Responsable del Tratamiento y se encuentra
      dentro o fuera del pa&iacute;s.
    </li>
  </ol>

  <ol>
    <li>
      Transmisi&oacute;n: Tratamiento de datos personales que implica la
      comunicaci&oacute;n de los mismos dentro o fuera del territorio de la
      Rep&uacute;blica de Colombia, con el fin de que el Encargado realice el
      Tratamiento por cuenta del Responsable.
    </li>
  </ol>
  <p></p>
  <p></p>
  <ol start="3">
    <li><strong>Tratamiento de la Informaci&oacute;n Personal </strong></li>
  </ol>

  <p>
    Los datos personales que Finsu recolecta, almacena, y de cualquier otra
    forma trata solo son utilizados de acuerdo con los lineamientos trazados por
    la Constituci&oacute;n Pol&iacute;tica de Colombia y la Ley que rige sus
    actividades, as&iacute; como de acuerdo con lo establecido en los siguientes
    aspectos sobre el Tratamiento de la informaci&oacute;n personal.
  </p>

  <p>
    Esta Pol&iacute;tica es aplicable respecto de los datos personales
    entregados por los Titulares a Finsu por cualquier medio (verbal,
    p&aacute;gina web o f&iacute;sico) y para aquellos que, a trav&eacute;s de
    cualquier medio l&iacute;cito sean recaudados por Finsu. &nbsp;
  </p>

  <ul>
    <li><strong>Tratamiento de datos p&uacute;blicos</strong></li>
  </ul>

  <p>
    Es posible que Finsu realice el Tratamiento de datos personales de
    naturaleza p&uacute;blica sin previa autorizaci&oacute;n del Titular,
    recopilados &eacute;stos de registros p&uacute;blicos, documentos
    p&uacute;blicos, gacetas, boletines oficiales, y sentencias judiciales
    debidamente ejecutoriadas.
  </p>

  <p>
    Esta situaci&oacute;n no implica que no se adopten las medidas necesarias
    que garanticen el cumplimiento de los principios y obligaciones contempladas
    en la Ley 1581 de 2012 y sus decretos reglamentarios. &nbsp;
  </p>

  <ul>
    <li>
      <strong
        >Tratamiento de informaci&oacute;n personal de clientes e
        inversionistas</strong
      >
    </li>
  </ul>

  <p>
    Mediante los diferentes canales de comunicaci&oacute;n (correspondencia
    f&iacute;sica, p&aacute;gina web, correo electr&oacute;nico y l&iacute;nea
    telef&oacute;nica) se recolectan y almacenan datos personales de los
    clientes e inversionistas que usan nuestros servicios por medio de la
    plataforma de Finsu.
  </p>

  <p>
    Esta informaci&oacute;n es entregada directamente por los Titulares de la
    informaci&oacute;n y/o por los representantes de ellos, y es tratada
    conforme con la presente pol&iacute;tica dentro del marco contextual de la
    Ley 1581 de 2012 y normas reglamentarias, bajo las finalidades consagradas
    en las autorizaciones otorgadas.
  </p>

  <ul>
    <li>
      <strong
        >Tratamiento de informaci&oacute;n personal de funcionarios
      </strong>
    </li>
  </ul>
  <p></p>
  <p>
    A trav&eacute;s de los diferentes canales de comunicaci&oacute;n o de los
    documentos dispuestos para el efecto, por medio los cuales se formaliza la
    vinculaci&oacute;n laboral, se recolectan y almacenan datos personales de
    los funcionarios de Finsu.
  </p>

  <p>
    Esta informaci&oacute;n es entregada directamente por los Titulares de la
    informaci&oacute;n, y es tratada conforme la presente Pol&iacute;tica dentro
    del marco contextual de la Ley 1581 de 2012 y normas reglamentarias, bajo
    las finalidades consagradas en las autorizaciones otorgadas.
  </p>
  <p></p>
  <ul>
    <li>
      <strong>Tratamiento de informaci&oacute;n personal de proveedores</strong>
    </li>
  </ul>

  <p>
    A trav&eacute;s de los diferentes canales de comunicaci&oacute;n o de los
    documentos dispuestos para el efecto, por medio los cuales se formaliza la
    vinculaci&oacute;n de proveedores, se recolectan y almacenan datos
    personales de los proveedores de Finsu.
  </p>

  <p>
    Esta informaci&oacute;n es entregada directamente por los Titulares de la
    informaci&oacute;n, y es tratada conforme la presente Pol&iacute;tica dentro
    del marco contextual de la Ley 1581 de 2012 y normas reglamentarias, bajo
    las finalidades consagradas en las autorizaciones otorgadas.
  </p>

  <ul>
    <li><strong>Tratamiento de Datos Sensibles </strong></li>
  </ul>

  <p>
    Finsu, en el normal desarrollo de sus operaciones, no realiza el Tratamiento
    de datos sensibles; sin embargo, en caso de ser imperativo hacerlo,
    contar&aacute; con las autorizaciones correspondientes, previo a haber
    informado al Titular: i) Que no est&aacute; obligado a autorizar el
    tratamiento de los datos sensibles. ii) Que es facultativo responder a
    preguntas que versen sobre datos sensibles. iii) Los datos sensibles objeto
    de tratamiento. iv) El tratamiento y las finalidades del mismo. &nbsp;
  </p>

  <ul>
    <li>
      <strong
        >Tratamiento de Datos personales de ni&ntilde;os, ni&ntilde;as y
        adolescentes</strong
      >
    </li>
  </ul>
  <p></p>
  <p>
    Finsu, en el normal desarrollo de sus operaciones, no realiza el Tratamiento
    de datos de ni&ntilde;os, ni&ntilde;as y adolescentes; sin embargo, en caso
    de ser imperativo hacerlo, contar&aacute; con las autorizaciones
    correspondientes, las cuales ser&aacute;n emitidas por sus representantes
    legales o acudientes.
  </p>

  <ul>
    <li>
      <strong>Tratamiento por parte de Encargados del Tratamiento</strong>
    </li>
  </ul>

  <p>
    Finsu en ejercicio de su objeto social puede apoyarse en terceros que
    colaboran con el desarrollo de sus actividades, y en esa medida puede
    instruirlos para que la recolecci&oacute;n y tratamiento de la
    informaci&oacute;n personal, cualquiera que esta sea, est&eacute; siempre
    precedida de todas las autorizaciones pertinentes; sin embargo en caso de
    incumplimiento de las obligaciones por parte de esos terceros, &eacute;ste
    no ser&aacute; asumido por Finsu, quien act&uacute;a como un tercero de
    buena fe, de tal manera que su actuaci&oacute;n siempre estar&aacute; basada
    en el convencimiento que esos terceros cuentan con las autorizaciones
    necesarias para tratar la informaci&oacute;n de acuerdo con las finalidades
    para las que le es entregada.
  </p>

  <ol start="4">
    <li>
      <strong>Finalidades del Tratamiento de la Informaci&oacute;n</strong>
    </li>
  </ol>

  <p>
    Finsu realiza el Tratamiento de la informaci&oacute;n personal con el
    principal objetivo de prestar sus servicios a trav&eacute;s de una
    plataforma tecnol&oacute;gica, actuando siempre de acuerdo a las finalidades
    previstas en esta Pol&iacute;tica y a las autorizadas de forma previa y
    expresa por los Titulares de la informaci&oacute;n.
  </p>

  <p>
    Para llevar a cabo dichas finalidades, Finsu puede tratar, recolectar, usar,
    almacenar, proteger, entre otras actividades, la informaci&oacute;n personal
    de los Titulares, entre la que se incluye, la siguiente:
  </p>

  <ol>
    <li>
      Datos de identificaci&oacute;n (por ejemplo, nombre, domicilio,
      n&uacute;mero de tel&eacute;fono fijo o m&oacute;vil, direcci&oacute;n de
      correo electr&oacute;nico, nombre de usuario en la plataforma Finsu).
    </li>
    <li>
      Informaci&oacute;n bancaria y de pagos (por ejemplo, n&uacute;mero de
      cuenta bancaria, entidad bancaria).
    </li>
    <li>Informaci&oacute;n de seguridad social</li>
  </ol>

  <p>
    En todo caso, al momento de recolecci&oacute;n de datos personales por parte
    de Finsu, las finalidades para el Tratamiento espec&iacute;fico ser&aacute;n
    informadas al Titular de forma previa al otorgamiento de su
    autorizaci&oacute;n, las cuales estar&aacute;n enmarcadas dentro de las
    siguientes:
  </p>

  <ol>
    <li>Prestaci&oacute;n de los servicios de Finsu.</li>
    <li>
      Registro y gesti&oacute;n de las solicitudes de financiaci&oacute;n de
      p&oacute;lizas de seguro a trav&eacute;s de la plataforma de Finsu.
    </li>
    <li>
      Registro y gesti&oacute;n de las solicitudes de inversi&oacute;n en
      p&oacute;lizas de seguro a trav&eacute;s de la plataforma de Finsu.
    </li>
    <li>
      Registro y gesti&oacute;n de todas las actividades relativas al manejo de
      la relaci&oacute;n comercial y/o contractual con empleados y/o proveedores
      de Finsu.
    </li>
  </ol>

  <ol>
    <li>
      Realizar gestiones de conocimiento y validaci&oacute;n de
      informaci&oacute;n a trav&eacute;s debases de datos y/o listas
      restricticas que tengan por finalidad la prevenci&oacute;n,
      detecci&oacute;n, monitoreo y control de lavado de activos y el
      financiamiento del terrorismo.
    </li>
    <li>
      Mantener contacto con los Titulares de la informaci&oacute;n a
      trav&eacute;s de correo f&iacute;sico o electr&oacute;nico, mensajes de
      texto MMS/SMS, mensajer&iacute;a instant&aacute;nea,
      telef&oacute;nicamente, o a trav&eacute;s de cualquier otro medio de
      comunicaci&oacute;n por conocer.
    </li>
    <li>Ejecutar y/o dar cumplimiento a obligaciones contractuales.</li>
    <li>
      Atender y dar tr&aacute;mite a las peticiones, quejas y reclamos
      formulados por los Titulares de la informaci&oacute;n.
    </li>
    <li>
      Atender requerimientos de autoridades judiciales y/o administrativas en
      ejercicio de sus funciones.
    </li>
    <li>
      Compartirla con terceros, en su calidad de Encargados del Tratamiento, con
      el fin de realizar las mismas actividades ya mencionadas.
    </li>
  </ol>
  <p></p>
  <p>
    Finsu puede contactar a los Titulares de datos personales v&iacute;a correo
    electr&oacute;nico, correo f&iacute;sico, mensaje de texto,
    mensajer&iacute;a instant&aacute;nea o telef&oacute;nicamente para las
    finalidades previstas en la presente pol&iacute;tica.
  </p>

  <p>
    En ning&uacute;n caso Finsu enajenar&aacute; o entregar&aacute; esta
    informaci&oacute;n a un tercero, diferente a los que act&uacute;en como
    Encargados del tratamiento, a menos que resulte necesario para su adecuado
    cumplimiento, esto con fundamento en contratos o acuerdos de
    transmisi&oacute;n o transferencia de los datos, en los casos que sea
    necesario, ya sea dentro del territorio nacional o el exterior.
  </p>

  <p>
    Finsu podr&aacute; llevar a cabo el Tratamiento de los datos en sus propios
    servidores o en aquellos provistos por un tercero y estos podr&aacute;n
    estar ubicados en Colombia o en otros pa&iacute;ses, caso en el cual se
    velar&aacute; por contar con acuerdos en los que se establezcan las
    obligaciones que deber&aacute;n observar esos terceros, adem&aacute;s de la
    exigencia del cumplimiento de la presente Pol&iacute;tica de Tratamiento de
    datos personales.
  </p>
  <p></p>
  <p></p>
  <ol start="5">
    <li><strong>Obligaciones del Responsable del Tratamiento</strong></li>
  </ol>
  <p></p>
  <p>
    Finsu, como Responsable del Tratamiento cumple con las siguientes
    obligaciones, sin perjuicio de las dem&aacute;s disposiciones previstas en
    la Ley 1581 de 2012 y en otras que rijan su actividad:
  </p>

  <ol>
    <li>
      Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del
      derecho de h&aacute;beas data;
    </li>
  </ol>

  <ol>
    <li>
      Solicitar y conservar, en las condiciones previstas en la ley, copia de la
      respectiva autorizaci&oacute;n otorgada por el Titular en los casos en que
      resulte necesario;
    </li>
  </ol>

  <ol>
    <li>
      Informar debidamente al Titular sobre la finalidad de la
      recolecci&oacute;n y los derechos que le asisten por virtud de la
      autorizaci&oacute;n otorgada en los casos en que resulte necesario;
    </li>
  </ol>

  <ol>
    <li>
      Conservar la informaci&oacute;n bajo las condiciones de seguridad
      necesarias para impedir su adulteraci&oacute;n, p&eacute;rdida, consulta,
      uso o acceso no autorizado o fraudulento de terceros;
    </li>
  </ol>

  <ol>
    <li>
      Garantizar que la informaci&oacute;n que se suministre al Encargado del
      Tratamiento sea veraz, completa, exacta, actualizada, comprobable y
      comprensible;
    </li>
  </ol>

  <ol>
    <li>
      Actualizar la informaci&oacute;n, comunicando de forma oportuna al
      Encargado del Tratamiento, todas las novedades respecto de los datos que
      previamente le haya suministrado y adoptar las dem&aacute;s medidas
      necesarias para que la informaci&oacute;n suministrada a este se mantenga
      actualizada;
    </li>
  </ol>

  <ol>
    <li>
      Rectificar la informaci&oacute;n cuando sea incorrecta y comunicar lo
      pertinente al Encargado del Tratamiento;
    </li>
  </ol>

  <ol>
    <li>
      Exigir al Encargado del Tratamiento en todo momento, el respeto a las
      condiciones de seguridad y privacidad de la informaci&oacute;n del
      Titular;
    </li>
  </ol>

  <ol>
    <li>
      Tramitar las consultas y reclamos formulados en los t&eacute;rminos
      se&ntilde;alados en la ley;
    </li>
  </ol>

  <ol>
    <li>
      Adoptar un manual interno de pol&iacute;ticas y procedimientos para
      garantizar el adecuado cumplimiento de la ley y en especial, para la
      atenci&oacute;n de consultas y reclamos;
    </li>
  </ol>

  <ol>
    <li>
      Informar a la autoridad de protecci&oacute;n de datos cuando se presenten
      violaciones a los c&oacute;digos de seguridad y existan riesgos en la
      administraci&oacute;n de la informaci&oacute;n de los Titulares;
    </li>
  </ol>

  <ol>
    <li>
      Cumplir las instrucciones y requerimientos que imparta la Superintendencia
      de Industria y Comercio.
    </li>
  </ol>
  <p></p>
  <p></p>
  <ol start="6">
    <li><strong>Derechos del Titular de la informaci&oacute;n </strong></li>
  </ol>

  <p>
    El Titular de los datos personales de los cuales Finsu ostenta la calidad de
    Responsable, tiene los siguientes derechos:
  </p>

  <ol>
    <li>
      Conocer, actualizar y rectificar sus datos personales frente a Finsu. Este
      derecho se podr&aacute; ejercer, entre otros frente a datos parciales,
      inexactos, incompletos, fraccionados, que induzcan a error, o aquellos
      cuyo Tratamiento est&eacute; expresamente prohibido o no haya sido
      autorizado;
    </li>
  </ol>

  <ol>
    <li>
      Solicitar prueba de la autorizaci&oacute;n otorgada a Finsu salvo en los
      casos que expresamente se except&uacute;e como requisito para el
      Tratamiento;
    </li>
  </ol>

  <ol>
    <li>
      Ser informado por Finsu, previa solicitud, respecto del uso que se le ha
      dado a sus datos personales;
    </li>
  </ol>

  <ol>
    <li>
      Presentar ante la Superintendencia de Industria y Comercio quejas por
      infracciones a lo dispuesto en la ley de protecci&oacute;n de datos
      personales y las dem&aacute;s normas que la modifiquen, adicionen o
      complementen;
    </li>
  </ol>

  <ol>
    <li>
      Revocar la autorizaci&oacute;n y/o solicitar la supresi&oacute;n del dato
      cuando en el Tratamiento no se respeten los principios, derechos y
      garant&iacute;as constitucionales y legales. La revocatoria y/o
      supresi&oacute;n proceder&aacute; cuando no exista relaci&oacute;n
      contractual vigente entre el Titular y Finsu, o en el evento que la
      Superintendencia de Industria y Comercio haya determinado que en el
      Tratamiento de la informaci&oacute;n Finsu ha incurrido en conductas
      contrarias a ley y a la Constituci&oacute;n;
    </li>
  </ol>

  <ol>
    <li>
      Acceder en forma gratuita a sus datos personales que hayan sido objeto de
      Tratamiento.
    </li>
  </ol>
  <p></p>
  <p></p>
  <ol start="7">
    <li><strong>Autorizaciones y consentimiento</strong></li>
  </ol>

  <p>
    La recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n o
    supresi&oacute;n de datos personales por parte de Finsu se hace, en caso de
    resultar obligatorio, contando con un previo consentimiento libre, expreso e
    informado del Titular de los mismos.
  </p>
  <p></p>
  <ul>
    <li><strong>Medios para otorgar la autorizaci&oacute;n</strong></li>
  </ul>

  <p>
    La autorizaci&oacute;n puede constar en un documento f&iacute;sico o
    electr&oacute;nico, en un mensaje de datos, grabaciones de voz o en
    cualquier otro formato que permita garantizar su posterior consulta, o
    mediante un mecanismo t&eacute;cnico o tecnol&oacute;gico id&oacute;neo que
    permita manifestar u obtener el consentimiento, u otro mediante el cual se
    pueda concluir de manera inequ&iacute;voca que, de no haberse surtido una
    conducta del Titular, los datos nunca hubieren sido capturados y almacenados
    en la Base de Datos.
  </p>
  <p></p>
  <ul>
    <li><strong>Prueba de la autorizaci&oacute;n</strong></li>
  </ul>

  <p>
    Finsu utiliza los mecanismos con que cuenta actualmente para mantener
    registros o mecanismos f&iacute;sicos, t&eacute;cnicos o tecnol&oacute;gicos
    id&oacute;neos para la conservaci&oacute;n y posterior consulta de la
    autorizaci&oacute;n otorgada por parte de los Titulares para el Tratamiento
    de la informaci&oacute;n.
  </p>

  <ol start="8">
    <li>
      <strong>Atenci&oacute;n de peticiones, consultas y reclamos</strong>
    </li>
  </ol>
  <p></p>
  <ul>
    <li><strong>&Aacute;rea responsable</strong></li>
  </ul>

  <p>
    Finsu a trav&eacute;s de su Gerencia atender&aacute; todas las peticiones,
    consultas, quejas y/o reclamos del Titular de la informaci&oacute;n,
    relacionadas con los derechos establecidos en la ley para conocer,
    actualizar, rectificar y suprimir sus datos personales.
  </p>

  <p>
    De conformidad con el art&iacute;culo 14 de la Ley 1581 de 2012 las
    consultas ser&aacute;n atendidas en un t&eacute;rmino m&aacute;ximo de diez
    (10) d&iacute;as h&aacute;biles contados a partir de la fecha de recibo de
    la misma. Cuando no fuere posible atender la consulta dentro de dicho
    t&eacute;rmino, se informar&aacute; al interesado, expresando los motivos de
    la demora y se&ntilde;alando la fecha en que se atender&aacute; la consulta,
    la cual en ning&uacute;n caso podr&aacute; superar los cinco (5) d&iacute;as
    h&aacute;biles siguientes al vencimiento del primer t&eacute;rmino.
  </p>

  <p>
    De conformidad con el art&iacute;culo 15 de la Ley 1581 de 2012 cuando el
    Titular o sus causahabientes consideren que la informaci&oacute;n contenida
    en una base de datos debe ser objeto de correcci&oacute;n,
    actualizaci&oacute;n o supresi&oacute;n, o cuando adviertan el presunto
    incumplimiento de cualquiera de los deberes contenidos en la ley,
    podr&aacute;n presentar un reclamo ante el Responsable o Encargado del
    Tratamiento.
  </p>

  <p>
    El reclamo se formular&aacute; mediante solicitud dirigida al Responsable o
    Encargado del Tratamiento, con la identificaci&oacute;n del Titular, la
    descripci&oacute;n de los hechos que dan lugar al reclamo, la
    direcci&oacute;n, y acompa&ntilde;ando los documentos que se quiera hacer
    valer. Si el reclamo resulta incompleto, se requerir&aacute; al interesado
    dentro de los cinco (5) d&iacute;as h&aacute;biles siguientes a la
    recepci&oacute;n del reclamo para que subsane las fallas.
  </p>

  <p>
    Una vez recibido el reclamo completo, se incluir&aacute; en la base de datos
    una leyenda que diga "reclamo en tr&aacute;mite", en un t&eacute;rmino no
    mayor a dos (2) d&iacute;as h&aacute;biles. Dicha leyenda deber&aacute;
    mantenerse hasta que el reclamo sea decidido.
  </p>

  <p>
    Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el
    solicitante presente la informaci&oacute;n requerida, en caso de reclamos
    incompletos, se entender&aacute; que ha desistido del reclamo.
  </p>

  <p>
    En el evento de que quien reciba el reclamo no sea competente para
    resolverlo, dar&aacute; traslado a quien corresponda en un t&eacute;rmino
    m&aacute;ximo de dos (2) d&iacute;as h&aacute;biles e informar&aacute; de la
    situaci&oacute;n al interesado.
  </p>

  <p>
    En todo caso, el t&eacute;rmino m&aacute;ximo para atender el reclamo
    ser&aacute; de quince (15) d&iacute;as h&aacute;biles contados a partir del
    d&iacute;a siguiente a la fecha de su recibo. Cuando no fuere posible
    atender el reclamo dentro de dicho t&eacute;rmino, se informar&aacute; al
    interesado los motivos de la demora y la fecha en que se atender&aacute; su
    reclamo, la cual en ning&uacute;n caso podr&aacute; superar los ocho (8)
    d&iacute;as h&aacute;biles siguientes al vencimiento del primer
    t&eacute;rmino.
  </p>

  <p>
    Es de anotar que mientras el Titular de la informaci&oacute;n mantenga una
    relaci&oacute;n comercial y/o contractual con Finsu, o sea necesario
    conservar la informaci&oacute;n con el fin de cumplir adecuadamente con el
    objeto de la sociedad, la informaci&oacute;n ser&aacute; mantenida y el
    Titular carecer&aacute; de facultades para solicitar la supresi&oacute;n de
    la informaci&oacute;n de las bases de datos.
  </p>

  <p>
    Si la solicitud de retiro se hace con posterioridad a finalizada la
    relaci&oacute;n legal o contractual y ya no resulta necesario para la
    sociedad mantener la informaci&oacute;n con el fin de cumplir sus
    obligaciones, la eliminaci&oacute;n de los datos significar&aacute; que los
    mismos no podr&aacute;n ser accesibles para el desarrollo de las operaciones
    normales de la sociedad, sin embargo podr&aacute;n mantenerse en sus
    archivos con fines estad&iacute;sticos, hist&oacute;ricos, o atenci&oacute;n
    de requerimiento de autoridades administrativas o judiciales.
  </p>

  <p>
    En caso de que la solicitud que realice el Titular de los datos o sus
    causahabientes est&eacute; relacionada con las autorizaciones para el
    Tratamiento que sean recopiladas por esos terceros, Finsu har&aacute; sus
    mejores esfuerzos por atender la solicitud de manera adecuada y, en caso de
    no poder hacerlo, realizar&aacute; el traslado de la solicitud a los
    terceros Encargados de la recolecci&oacute;n de la informaci&oacute;n, sin
    embargo, en tales casos no se har&aacute; responsable por el contenido de
    las respuestas que se otorguen a las solicitudes.
  </p>
  <p></p>
  <ul>
    <li><strong>Procedimiento para el ejercicio de los derechos</strong></li>
  </ul>

  <p>
    Los Titulares podr&aacute;n realizar peticiones, consultas y/o reclamos
    respecto sus datos personales tratados por Finsu a trav&eacute;s de los
    siguientes mecanismos:
  </p>

  <ul>
    <li>
      Env&iacute;o de correo electr&oacute;nico a
      <a href="mailto:finsusas@gmail.com">finsusas@gmail.com</a>
    </li>
  </ul>
  <p></p>
  <ul>
    <li>
      Directamente en las oficinas ubicadas en la Calle 63 # 28A - 62 de la
      ciudad de Bogot&aacute; D.C.
    </li>
  </ul>

  <ul>
    <li>
      A trav&eacute;s de la l&iacute;nea telef&oacute;nica (1) 4824899 en
      Bogot&aacute; D.C.
    </li>
  </ul>
  <p></p>
  <p></p>
  <ol start="9">
    <li><strong>Normatividad vigente </strong></li>
  </ol>

  <p>
    Esta Pol&iacute;tica de Tratamiento de datos personales, se rige bajo lo
    estipulado en la Ley 1581 de 2012, el Decreto reglamentario 1377 de 2013 y
    dem&aacute;s normas que las modifiquen o complementen.
  </p>
  <p></p>
  <p></p>
  <ol start="10">
    <li><strong>Vigencia</strong></li>
  </ol>

  <p>
    La presente Pol&iacute;tica entra en vigencia a partir del mes de Enero de
    2020.
  </p>
  <p></p>
  <p>
    Los datos personales tratados por Finsu permanecer&aacute;n en sus Bases de
    datos hasta que los mismos cumplan con la finalidad para los cuales fueron
    recolectados.
  </p>

  <p>
    Finsu se reserva la facultad de revisar y/o modificar en cualquier momento
    esta Pol&iacute;tica de Tratamiento de Datos Personales.
  </p>

  <p>
    Cuando se realicen modificaciones sustanciales a esta Pol&iacute;tica, se
    comunicar&aacute; este hecho a los Titulares de la informaci&oacute;n y se
    indicar&aacute; la fecha a partir de la cual regir&aacute; la nueva
    Pol&iacute;tica, al menos, diez (10) d&iacute;as h&aacute;biles antes de la
    entrada en vigencia de la nueva Pol&iacute;tica.
  </p>
</div>
<app-footer></app-footer>
