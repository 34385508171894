import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { LoggedInGuard } from './guards/loged-In';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { PolizasComponent } from './public/intermediario/polizas/polizas.component';
import { AppComponent } from './app.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CrearPolizaIntermediarioComponent } from './public/crear-poliza-intermediario/crear-poliza-intermediario.component';
import { HomeComponent } from './public/home/home.component';
import { PreguntasComponent } from './public/preguntas/preguntas.component';
import { HeaderComponent } from './public/header/header.component';
import { FooterComponent } from './public/footer/footer.component';
import { IntermediarioComponent } from './public/intermediario/intermediario.component';
import { AppToastsComponent } from './public/toast/apptoast.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AliadosComponent } from './public/home/aliados/aliados.component';
import { CaracteristicasComponent } from './public/home/caracteristicas/caracteristicas.component';
import { ComoLoHacemosComponent } from './public/home/como-lo-hacemos/como-lo-hacemos.component';
import { FinanciaTuSeguroComponent } from './public/home/financia-tu-seguro/financia-tu-seguro.component';
import { IniciarSesionComponent } from './public/iniciar-sesion/iniciar-sesion.component';
import { CrearCuentaClienteComponent } from './public/crear-cuenta/cliente/crear-cuenta-cliente.component';
import { CrearCuentaComponent } from './public/crear-cuenta/crear-cuenta.component';
import { ContactoComponent } from './public/contacto/contacto.component';
import { FinanciarComponent } from './public/financiar/financiar.component';
import { PSERespuestaComponent } from './public/pse/pse-respuesta.component';
import { PasoAPasoComponent } from './public/financiar/paso-a-paso/paso-a-paso.component';
import { SimuladorComponent } from './public/financiar/simulador/simulador.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { PlanDePagosComponent } from './public/financiar/plan-de-pagos/plan-de-pagos.component';
import { UsuarioComponent } from './public/usuario/usuario.component';
import { ResumenComponent } from './public/usuario/resumen/resumen.component';
import { PerfilComponent } from './public/usuario/perfil/perfil.component';
import { FinanciacionesComponent } from './public/usuario/financiaciones/financiaciones.component';
import { DetalleFinanciacionesComponent } from './public/usuario/detalle-financiaciones/detalle-financiaciones.component';
import { PqrComponent } from './public/usuario/pqr/pqr.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SimuladorPlanComponent } from './public/home/simulador-plan/simulador-plan.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
//import { ErrorInterceptor } from './interceptor/error.interceptor';
import { DocumentacionUserComponent } from './public/home/simulador-plan/documentacion-user/documentacion-user.component';
import { DocumentacionComponent } from './public/home/simulador-plan/documentacion/documentacion.component';
import { PoliticaDatosComponent } from './public/politica-datos/politica-datos.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import * as Cloudinary from 'cloudinary-core';
import {
  CloudinaryModule,
  CloudinaryConfiguration,
} from '@cloudinary/angular-5.x';
import { CarteraComponent } from './public/intermediario/cartera/cartera.component';
import { DetallePolizaComponent } from './public/intermediario/detalle-poliza/detalle-poliza.component';
import { ClientesIntermediarioComponent } from './public/intermediario/clientes-intermediario/clientes-intermediario.component';
import { PerfilIntermediarioComponent } from './public/intermediario/perfil-intermediario/perfil-intermediario.component';
import { CrearClienteComponent } from './public/intermediario/crear-cliente/crear-cliente.component';
import { OlvidoContrasenaComponent } from './public/olvido-contrasena/olvido-contrasena.component';
import { AuthGuard } from "./guards/auth.guard";
import { PagoPseComponent } from './public/usuario/pago-pse/pago-pse.component';
import { ResumenPolizaIntermediarioComponent  } from './public/crear-poliza-intermediario/resumen-poliza-intermediario/resumen-poliza-intermediario.component';
import { CambiarContrasenaComponent } from './public/iniciar-sesion/cambiar-contrasena/cambiar-contrasena.component';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PoliticaDatosComponent,
    PreguntasComponent,
    AppToastsComponent,
    PSERespuestaComponent,
    HeaderComponent,
    FooterComponent,
    AliadosComponent,
    PolizasComponent,
    IntermediarioComponent,
    CaracteristicasComponent,
    ComoLoHacemosComponent,
    FinanciaTuSeguroComponent,
    IniciarSesionComponent,
    CrearCuentaClienteComponent,
    ContactoComponent,
    CrearPolizaIntermediarioComponent,
    FinanciarComponent,
    CrearCuentaComponent,
    PasoAPasoComponent,
    SimuladorComponent,
    PlanDePagosComponent,
    UsuarioComponent,
    ResumenComponent,
    PerfilComponent,
    FinanciacionesComponent,
    DetalleFinanciacionesComponent,
    PqrComponent,
    SimuladorPlanComponent,
    DocumentacionUserComponent,
    DocumentacionComponent,
    CarteraComponent,
    DetallePolizaComponent,
    ClientesIntermediarioComponent,
    PerfilIntermediarioComponent,
    CrearClienteComponent,
    OlvidoContrasenaComponent,
    PagoPseComponent,
    ResumenPolizaIntermediarioComponent,
    CambiarContrasenaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    NgxSliderModule,
    CurrencyMaskModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    //CloudinaryModule.forRoot(Cloudinary, { cloud_name: "hh6guh7qh" }),
    CloudinaryModule.forRoot(Cloudinary, {
      api_key: '296963824483751',
      cloud_name: 'hh6guh7qh',
    } as CloudinaryConfiguration),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    LoggedInGuard,
    AuthGuard,
    { provide: LOCALE_ID, useValue: 'es' }
    //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
