import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './public/home/home.component';
import { PreguntasComponent } from './public/preguntas/preguntas.component';
import { IniciarSesionComponent } from './public/iniciar-sesion/iniciar-sesion.component';
import { CrearCuentaClienteComponent } from './public/crear-cuenta/cliente/crear-cuenta-cliente.component';
import { CrearCuentaComponent } from './public/crear-cuenta/crear-cuenta.component';
import { ContactoComponent } from './public/contacto/contacto.component';
import { FinanciarComponent } from './public/financiar/financiar.component';
import { UsuarioComponent } from './public/usuario/usuario.component';
import { SimuladorComponent } from './public/financiar/simulador/simulador.component';
import { SimuladorPlanComponent } from './public/home/simulador-plan/simulador-plan.component';
import { DocumentacionComponent } from './public/home/simulador-plan/documentacion/documentacion.component';
import { PSERespuestaComponent } from './public/pse/pse-respuesta.component';
import { PoliticaDatosComponent } from './public/politica-datos/politica-datos.component';
import { IntermediarioComponent } from './public/intermediario/intermediario.component';
import { DetallePolizaComponent } from './public/intermediario/detalle-poliza/detalle-poliza.component';
import { CrearPolizaIntermediarioComponent } from './public/crear-poliza-intermediario/crear-poliza-intermediario.component';
import { CrearClienteComponent } from './public/intermediario/crear-cliente/crear-cliente.component';
import { OlvidoContrasenaComponent } from './public/olvido-contrasena/olvido-contrasena.component';
import { LoggedInGuard } from './guards/loged-In';
import { AuthGuard } from './guards/auth.guard'
import { ResumenPolizaIntermediarioComponent  } from './public/crear-poliza-intermediario/resumen-poliza-intermediario/resumen-poliza-intermediario.component';
import { CambiarContrasenaComponent } from './public/iniciar-sesion/cambiar-contrasena/cambiar-contrasena.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'preguntas',
    component: PreguntasComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'crear-poliza',
    component: CrearPolizaIntermediarioComponent
  },
  {
    path: 'financiar',
    component: FinanciarComponent },
  {
    path: 'iniciar-sesion',
    component: IniciarSesionComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'cambiar-contrasena',
    component: CambiarContrasenaComponent,
    // canActivate: [LoggedInGuard],
  },
  {
    path: 'crear-cuenta-cliente/:tipo',
    component: CrearCuentaClienteComponent,
    canActivate: [LoggedInGuard],
  },

  {
    path: 'crear-cuenta-cliente/:tipo/:email',
    component: CrearCuentaClienteComponent,
  },

  {
    path: 'olvide-mi-contraseña',
    component: OlvidoContrasenaComponent,
  },
  {
    path: 'intermediario/poliza/:idPoliza',
    component: DetallePolizaComponent,
  },
  {
    path: 'polizaUser/:poliza/:usuario',
    component: ResumenPolizaIntermediarioComponent,
  },
  
  {
    path: 'intermediario/invitar-cliente',
    component: CrearClienteComponent,
  },

  {
    path: 'contacto',
    component: ContactoComponent
  },
  {
    path: 'usuario',
    component: UsuarioComponent,
  },
  {
    path: 'intermediario',
    component: IntermediarioComponent,
  },
  {
    path: 'simulador',
    component: SimuladorPlanComponent,
  },
  {
    path: 'documentos',
    component: DocumentacionComponent,
  },
  {
    path: 'respuesta-pse',
    component: PSERespuestaComponent,
  },
  {
    path: 'politica-datos',
    component: PoliticaDatosComponent
  },

  {
    path: 'crear-cuenta',
    component: CrearCuentaComponent,
  },
  {
    path: '**',
    component: HomeComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'ignore',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
