import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
})
export class UsuarioComponent implements OnInit {
  menu: string = 'resumen';
  public user: any;

  constructor(
    private menuService: MenuService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.verifyIfUserLogged();
    this.user = JSON.parse(localStorage.getItem('usuario'));

    if (this.user.passwordTemp) {
      this.router.navigate(['cambiar-contrasena']);
      return;
    }

    this.menuService.menu.subscribe((menu) => {
      if (menu && menu !== '') {
        this.seleccionarMenu(menu);
      }
    });
  }

  /**
   * Método para seleccionar menú
   * @param {string} r
   */
  seleccionarMenu(r) {
    this.menu = r;
  }
  cerrar() {
    this.authService.logout();
    this.router.navigate(['']);
  }
}
