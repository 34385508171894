import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { MainService } from 'src/app/services/main.service';
import { AppToastService } from 'src/app/services/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
  user: any;
  contrasenaAntigua = null;
  contrasenaNueva = null;
  modalActivo: any;
  hayError: string = 'none';
  contrasenaError: String;
  esIncorrecta: string = 'none';
  tipoNueva: string = 'password';
  tipoConfirmacion: string = 'password';
  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private mainService: MainService,
    private toastService: AppToastService
  ) {}

  ngOnInit(): void {
    this.user = this.authService.obtenerUsuarioActual();
  }

  /**
   * Metodo que controla el cierre del modal del cambio de contraseña y la petición
   * para cambiar la  contraseña
   */
  manejarCambioContrasena() {
    this.hayError = 'none';
    this.esIncorrecta = 'none';
    // Se revisa que la contraseña sea valida
    let verificacionContrasena = this.sonContrasenasValidas();
    if (verificacionContrasena.valido) {
      this.authService
        .verificarContraseña(this.user.email, this.contrasenaAntigua)
        .subscribe(
          (esCorrecta: any) => {
            if (esCorrecta.success) {
              this.authService
                .cambiarContrasena(this.user.email, this.contrasenaNueva)
                .subscribe((response: any) => {
                  if (response.success) {
                    this.modalActivo.close();
                    this.toastService.mostrar(
                      'Éxito',
                      'La contraseña se ha cambiado de forma exitosa.',
                      'exito'
                    );
                  } else {
                    Swal.fire(
                      '¡Error!',
                    `${response.msg}.`,
                      'warning'
                    );
                  }
                });
            } else {
              this.esIncorrecta = 'inline';
            }
          },
          (err) => {}
        );
    } else {
      console.log('Hay error');
      this.contrasenaError = verificacionContrasena.cual;
      this.hayError = 'inline';
    }
  }

  /**
   * Metodo que maneja el cambio en el campo de celular, valida el campo y muestra un toast
   * si se actualizo de forma satisfactoria
   */
  async manejarCambiosFormulario() {
    if (this.esTelefonoValido()) {
      let response = await this.mainService.put(`user/${this.user.id}`, {
        telefono: this.user.telefono,
      });
      if (response) {
        this.toastService.mostrar(
          'Éxito',
          'Los datos han sido actualizados de forma exitosa.',
          'exito'
        );
      }
    } else {
      this.toastService.mostrar(
        'Cuidado',
        'El teléfono es inválido.',
        'advertencia'
      );
    }
  }

  /**
   * Verifica el campo de telefono
   * @returns booleano que representa la verificación
   */
  esTelefonoValido() {
    let esValido = false;
    if(this.user.telefono.length > 10){
      this.toastService.mostrar(
        'Cuidado',
        'La cantidad de caracteres en el teléfono no puede ser superior a 10 dígitos.',
        'advertencia'
      );
      return false;
    }else{
      if (
        this.mainService.esInputNoVacio(this.user.telefono) &&
        this.mainService.validarTelefono(this.user.telefono)
      ) {
        esValido = true;
      }
      return esValido;
    }

  }

  /**
   * Maneja si la contraseña se ve o no para el input de nueva contraseña
   */
  cambiarEstadoNueva() {
    if (this.tipoNueva === 'text') {
      this.tipoNueva = 'password';
    } else {
      this.tipoNueva = 'text';
    }
  }
  /**
   * Maneja si la contraseña se ve o no para el input de contraseña confirmación
   */
  cambiarEstadoConfirmacion() {
    if (this.tipoConfirmacion === 'text') {
      this.tipoConfirmacion = 'password';
    } else {
      this.tipoConfirmacion = 'text';
    }
  }

  /**
   * verifica que las dos contraseñas sean iguales
   * @returns booleando que representa la verificación
   */
  sonContrasenasIguales() {
    let sonIguales = false;
    if (this.contrasenaNueva === this.contrasenaAntigua) {
      sonIguales = true;
    }
    return sonIguales;
  }

  /**
   * Verifica que las contraseñás sean validas
   * @returns objeto que tiene como primer key si la verificación es exitosa, de lo contrario
   * la segunda key dice cúal fue la erronea
   */
  sonContrasenasValidas() {
    let valido = true;
    let cual: String;
    if (
      !this.mainService.esInputNoVacio(this.contrasenaAntigua) ||
      !this.mainService.verificarPatronContrasena(this.contrasenaAntigua)
    ) {
      valido = false;
      cual = 'antigua';
    } else if (
      !this.mainService.esInputNoVacio(this.contrasenaNueva) ||
      !this.mainService.verificarPatronContrasena(this.contrasenaNueva)
    ) {
      valido = false;
      cual = 'nueva';
    }
    console.log(cual);
    return { valido, cual };
  }

  openVerticallyCentered(content) {
    this.modalActivo = this.modalService.open(content, { centered: true });
  }
}
