import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { Options } from "ng5-slider";
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from "../../../../services/auth.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-documentacion-user',
  templateUrl: './documentacion-user.component.html',
  styleUrls: ['./documentacion-user.component.scss']
})
export class DocumentacionUserComponent implements OnInit {
  /** Resultado que envía el backend sobre la validación de la cédula */
  public validacionCedulaResultado: boolean = false;
  public user: any;
  /** Boolean que indica si se envió la OTP o no. Cuando es true, es porque hubo un error y se muestra el mensaje de error. */
  public otpEnviada: boolean = false;
    /** El mensaje que llega del backend después de validar la cédula */
    public validacionCedulaMensaje: string;
    preguntasRecorrer: any = [];
    preguntas: any = {};
    public paso: number = 1;
    maxValue: any;
    numeroPoliza: any;
    /*uploaderPoliza: CloudinaryUploader = new CloudinaryUploader(
      new CloudinaryOptions({ cloudName: "hh6guh7qh", uploadPreset: "wncq8grq" })
    );*/
  /** Es el código que ingresa el usuario para verificar */
  public codigoOtp: string = "";
  /** Se obtiene cuando se envía la OTP al celular del usuario. Se usa para verificar el código cuando el usuario lo ingrese */
  public identificadorOtp: string = "";
    /** Celular para enviar la OTP y firmar el documento */
    public celularVerificacion: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.user = JSON.parse(localStorage.getItem("usuario"));

   }

  ngOnInit(): void {
  }
  uploadPoliza() {
    this.spinner.show;
    //this.uploaderPoliza.uploadAll();
  }
public verificarUsuario() {
  this.spinner.show();
    this.authService.post({ api: "api/poliza/verificarIdentidad/"+ this.user.numeroDocumento}).subscribe(
        (result) => {
          this.otpEnviada = true;
          // TODO actualizar el usuario con la cédula que ingrese aquí
          this.spinner.hide();
          if (result["success"]) {
            this.validacionCedulaResultado = result["cedulaValida"];
            this.validacionCedulaMensaje = result["msg"];
            //this.paso = 2;
          } else {
            this.validacionCedulaResultado = false;
            this.validacionCedulaMensaje = ": No se pudo validar la cédula";
          }
        },
        (err) => {
          this.spinner.hide();
          this.otpEnviada = true;
        }
      );
  }

  /**
   * Método para validar que haya respondido todas las respuestas y contruir el json para enivar la info
   */
   chequearRespuestas() {
    let json = {
      IdCuestionario: this.preguntas.preguntas["a:IdCuestionario"],
      RegistroCuestionario: this.preguntas.preguntas["a:RegistroCuestionario"],
      NumeroIdentificacion: this.user.numeroDocumento,
      Respuestas: [],
    };
    for (let p of this.preguntasRecorrer) {
      if (p["b:OpcionesRespuesta"]) {
        for (let r of p["b:OpcionesRespuesta"]["b:OpcionRespuesta"]) {
          if (r.sel && r.sel === true) {
            let enviar = {
              IdPregunta: p["b:IdPregunta"],
              IdRespuesta: r["b:IdRespuesta"],
            };
            json.Respuestas.push(enviar);
          }
        }
      }
    }
    if (this.preguntasRecorrer.length === json.Respuestas.length) {
      this.enviarRespuestas(json);
    } else {
      Swal.fire(
        "¡Error!",
        "Debes responder todas las preguntas para poder continuar.",
        "warning"
      );
    }
  }
    /**
   * Método para enviar respuestas
   * @param {object} enviar
   */
     enviarRespuestas(enviar) {
      this.spinner.show();
        this.authService.post({ api: "api/poliza/psdEnviarRespuestas/"+ enviar}).subscribe(

        (result) => {
          this.spinner.hide();
          if (
            result["a:Resultado"] &&
            result["a:Resultado"] === true &&
            result["a:DescripcionRespuesta"] &&
            result["a:DescripcionRespuesta"] === "Aprobado" &&
            result["a:CodigoRespuesta"] &&
            result["a:CodigoRespuesta"] === "01"
          ) {
            this.validacionCedulaResultado = result["cedulaValida"];
            this.validacionCedulaMensaje = result["msg"];
            this.paso = 2;
          } else {
            Swal.fire(
              "¡Error!",
              "Las respuestas son incorrectas, vuelve a intentarlo.",
              "warning"
            );
          }
        },
        (err) => {
          this.spinner.hide();
          Swal.fire(
            "¡Error!",
            "Ocurrio un error al enviar las respuestas, intentalo de nuevo más tarde.",
            "warning"
          );
        }
      );
    }
    public enviarOtp() {
      this.spinner.show();
      let payload = {
        correo: this.user.email,
        celular: this.celularVerificacion,
      };
      this.authService.post({ api: "api/poliza/enviarOtp/"+ payload}).subscribe(
        (result) => {
          this.spinner.hide();
          if (result && result["success"]) {
            this.identificadorOtp = result["identificador"];
            this.paso = 3;
            Swal.fire(
              "¡Éxito!",
              "Código de verificación enviado a tu celular y correo.",
              "success"
            );
          } else {
            Swal.fire(
              "¡Error!",
              "Error al enviar la OTP. Por favor intenta de nuevo.",
              "warning"
            );
          }
        },
        (err) => {
          this.spinner.hide();
          Swal.fire(
            "¡Error!",
            "Error al enviar la OTP. Por favor intenta de nuevo.",
            "warning"
          );
        }
      );
    }
      /**
   * Método para firmar el documento
   */
  public async firmarDocumento() {
    // Envia el OTP para verificar y firmar el documento
    this.spinner.show();
    let verificarIdentidad: any = await this.firmaElectronica();
    if (verificarIdentidad && verificarIdentidad.success) {
    }
    this.spinner.hide();
  }

  public firmaElectronica() {
    if (!this.identificadorOtp) {
      return;
    }
    let payload = {
      identificador: this.identificadorOtp,
      otp: this.codigoOtp,
      nombreUsuario: `${this.user.nombre} ${this.user.apellido}`,
      correoUsuario: this.user.email,
      valor_capital: this.maxValue,
      interes_corriente: " ",
      interes_mora: " ",
      suscripcion_dia: moment().format("DD"),
      suscripcion_año: moment().format("YY"),
      suscripcion_mes: moment().format("MM"),
      vencimiento_dia: moment().add(1, "year").format("DD"),
      vencimiento_mes: moment().add(1, "year").format("MM"),
      vencimiento_año: moment().add(1, "year").format("YY"),
      lugar_obligacion: "Bogotá",
      pagare_nu: this.numeroPoliza,
      pagare_orden_nu: " ",
      suscripcion_ciudad: "Bogotá",
      suscripcion_dias: moment().format("DD"),
      firma_deudor: `${this.user.nombre} ${this.user.apellido}`,
      firma_aval: " ",
      deudor_nombre: `${this.user.nombre} ${this.user.apellido}`,
      deudor_cc_nit: this.user.numeroDocumento,
      deudor_tipo_persona: this.user.tipoDocumento,
      deudor_direccion: " ",
      deudor_ciudad: "Bogotá",
      deudor_telefono: this.celularVerificacion,
      aval_nombre: " ",
      aval_cc_nit: " ",
      deudor_representante: " ",
      aval_tipo_persona: " ",
      aval_direccion: " ",
      aval_ciudad: " ",
      aval_telefono: " ",
      paguese_a: " ",
      paguese_a_cc_nit: " ",
      firma_endoso: " ",
      endoso_nombre: " ",
    };
    return new Promise((success, reject) => {
      this.authService.post({ api: "api/poliza/firmarDocumentoNew/"+ payload}).subscribe(
        (result) => {
          if (result && result["success"]) {
            Swal.fire("¡Éxito!", result["msg"], "success");
            // this.onGoToPago();
            this.paso = 4;
          } else {
            Swal.fire("¡Error!", result["msg"], "warning");
          }
          success({ success: true });
        },
        (err) => {
          Swal.fire(
            "¡Error!",
            "Hubo un error firmando el pagaré por favor intenta de nuevo.",
            "warning"
          );
          success({ success: false });
        }
      );
    });
  }
}
