<div class="wrapper-crear">
    <div class="container card-crear">
        <h2 class="row w-100 text-left font-weight-bold">Invitación cliente</h2>

        <div class="row w-100 justify-content-start">
            <div class="col-6 d-flex flex-column pl-0">
                <label class="label-poliza" for="nombre">Nombre*</label>
                <input class="input-poliza" id="nombre" type="text" placeholder="Escribe aquí" [(ngModel)]="usuario.nombre" />
            </div>
            <div class="col-6 d-flex flex-column">
                <label class="label-poliza" for="nombre">Apellidos*</label>
                <input class="input-poliza" id="nombre" type="text" placeholder="Escribe aquí" [(ngModel)]="usuario.apellido" />
            </div>
        </div>

        <div class="row w-100 mt-4 justify-content-start">
            <div class="col-6 d-flex flex-column pl-0">
                <label class="label-poliza" for="nombre">Correo*</label>
                <input class="input-poliza" id="nombre" type="text" placeholder="Escribe aquí" [(ngModel)]="usuario.email" />
            </div>
        </div>

        <div class="mt-5 botones row justify-content-center">
            <button routerLink="/intermediario" class="volver">Volver</button>

            <button class="crear" (click)="manejarFormulario()">Invitar</button>
        </div>
    </div>
</div>