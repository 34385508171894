import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { PolizaService } from '../../../services/poliza.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Poliza, Usuario } from 'src/app/interfaces/finsu.interface';

interface PolizaModificada extends Poliza {
  proximaCuota?: string;
}
@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.scss'],
})
export class ResumenComponent implements OnInit {
  public user: Usuario;
  public isCollapsed: any;
  public polizas: Poliza[] = [];
  public polizaMostrar: PolizaModificada;
  ultimoPago: any = {};
  capitalPorPagar: number;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private polizaService: PolizaService
  ) {
    this.user = JSON.parse(localStorage.getItem('usuario'));
    window.scroll(0, 0);
    document.body.scrollTop = 0;
  }

  ngOnInit(): void {
    this.traerPolizas();
  }

  public traerPolizas() {
    this.spinner.show();
    this.authService
      .get({ api: 'api/poliza/usuario/' + this.user.id })
      .subscribe(
        (result: Poliza[]) => {
          if (result['length'] !== 0) {
            this.polizas = result;
            let polizasNoPagoMasReciente: {
              poliza: Poliza;
              fecha: Date | string;
            }[] = [];
            this.polizas.forEach((poliza) => {
              for (let i = 0; i < poliza.planDePagos.length; i++) {
                let planAct = poliza.planDePagos[i];
                if (planAct.pagado === 'no') {
                  polizasNoPagoMasReciente.push({
                    poliza: poliza,
                    fecha: planAct.fecha,
                  });
                  break;
                }
              }
            });

            let masReciente = polizasNoPagoMasReciente[0];
            if (masReciente) {
              console.log('masReciente', masReciente);
              polizasNoPagoMasReciente.forEach((poliza) => {
                let masRecienteFecha = moment(masReciente.fecha);
                let actFecha = moment(poliza.fecha);
                if (masRecienteFecha.diff(actFecha) > 0) {
                  masReciente = poliza;
                }
              });
              this.polizaMostrar = masReciente.poliza;
              console.log(this.polizaMostrar);
              this.capitalPorPagar = 0;
              for(let pago of this.polizaMostrar.planDePagos){
                if(this.capitalPorPagar === 0 && pago.pagado === 'no'){
                  this.capitalPorPagar = pago.saldoCapital;
                }
              }
              if (this.polizaMostrar._id) {
                this.polizaMostrar.proximaCuota = moment(
                  this.polizaMostrar.createdAt
                )
                  .add(1, 'month')
                  .format();
                this.traerPagosDeLaPoliza(this.polizaMostrar._id);
              } else {
                this.spinner.hide();
              }
            } else {
              this.spinner.hide();
            }
          } else {
            this.spinner.hide();
          }
        },
        (err) => {}
      );
  }

  /**
   * Busca los pagos realizados a una poliza y muestra el último
   * @param idPoliza El id de la poliza
   */
  traerPagosDeLaPoliza(idPoliza: string): void {
    this.authService
      .get({ api: 'api/transaccion/poliza/' + idPoliza })
      .subscribe(
        (result) => {
          this.spinner.hide();
          this.ultimoPago = result[result['length'] - 1];
        },
        (err) => {}
      );
  }

  /**
   * Añade 5 días calendario a la fecha del pago cuota inicial
   * @param fecha La fecha a modificar
   * @returns La fecha + 5 días calendario
   */
  getMargenFechaLimite(fecha: Date): string {
    return moment(fecha).add(6, 'days').format('MMM DD, YYYY');
  }

  goToInitialPayment(){
    this.polizaService.savePolizaRemote(this.polizaMostrar)
    sessionStorage.setItem('pasoDocumentos', '5')
    this.router.navigate(['documentos']);
  }
}
