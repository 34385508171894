<div>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Cargando ...</p>
  </ngx-spinner>
  <div class="fondo">
    <div class="contenedor-formulario">
      <h1 class="formulario-titulo">Cambiar Contraseña</h1>
      <form class="formulario" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="formulario-informacion">

          <label class="label" for="contraseña">Contraseña</label>
          <div class="contrasena">
            <input id="contraseña" class="input" [type]="muestra" placeholder="Escribe aquí"
              formControlName="password" />
            <span (click)="togglePass()" class="visualizar">
              <img [style.display]="muestra == 'password' ? 'inline-block' : 'none'" class="ojo"
                src="assets/recursos/logos-iconos/ojo-off.png" alt="ojo-off contraseña" />
              <img [style.display]="muestra == 'password' ? 'none' : 'inline-block'" class="ojo"
                src="assets/recursos/logos-iconos/ojo-on.png" alt="ojo-off contraseña" />
            </span>
          </div>
          <span class="texto-error"
            *ngIf=" registerFormControl.password.touched && registerFormControl.password.errors?.required">
            Contraseña es requerida
          </span>
          <span class="texto-error"
            *ngIf="registerFormControl.password.touched && registerFormControl.password.errors?.invalidPassword">
            La contraseña debe tener un mínimo de 8 caracteres con números y letras.
          </span>

          <label class="label" for="confirmar">Confirmar contraseña</label>
          <div class="contrasena">
            <input id="confirmar" class="input" [type]="muestraCo" placeholder="Escribe aquí"
              formControlName="coPassword" />
            <span (click)="togglePass('muestraCo')" class="visualizar">
              <img [style.display]="muestraCo == 'password' ? 'inline-block' : 'none'" class="ojo"
                src="assets/recursos/logos-iconos/ojo-off.png" alt="ojo-off contraseña" />
              <img [style.display]="muestraCo == 'password' ? 'none' : 'inline-block'" class="ojo"
                src="assets/recursos/logos-iconos/ojo-on.png" alt="ojo-off contraseña" />
            </span>
          </div>
          <span class="texto-error"
            *ngIf="registerFormControl.coPassword.touched && this.registerForm.value.password !== this.registerForm.value.coPassword">
            Las contraseñas deben ser iguales
          </span>
        </div>
        <button class="boton" type="submit">Cambiar Contraseña</button>
      </form>
    </div>
  </div>
</div>
