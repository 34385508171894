<div class="contenedortable">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h5 class="titulotabla popSemibold size30">
                    <em *ngIf="metodovolver" class="fa fa-angle-left" style="float: left" routerLink="/usuario"></em>
                    <span style="text-align: center"> Plan de pagos</span>
                    <br>
                    <small>
                        Tasa: {{ tasaMensualPorcentaje }}% M.V.
                    </small>
                </h5>
            </div>


            <div class="col-12 tablecontenidodos">
                <div class="headTableComp">
                    <div class="" id="h5texto">
                        <h5 class="subtitulo popRegular size16">Cuota inicial</h5>
                    </div>
                    <div class="" id="h5texto">
                        <h5 class="subtitulo popRegular size16">No. De cuotas</h5>
                    </div>
                    <div class="valortable">
                        <label class="popSemibold size35 valor">
                          {{ valorTotal * porcentajeCuotaInicial
                            | currency:'COP':'symbol':'1.0-0'}}</label>
                    </div>
                    <div class="valortable">
                        <label class="popSemibold size35 valor">{{numeroCuotas || 0}}</label>
                    </div>
                </div>
                <div class="col-md-12 tableposicion">
                    <table class="table tableprimera">
                      <caption></caption>
                        <thead>
                            <tr class="popRegular size16">
                                <th class="col1 cuota" scope="col">Cuota</th>
                                <th class="col2 saldocapital" scope="col">Saldo Capital</th>
                                <th class="col1 cuotafija" scope="col">Cuota Fija</th>
                                <th class="col2 abonocap" scope="col">Abono Cap</th>
                                <th class="col1 interes" scope="col">Intereses</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sim of simulacion; let i = index">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>
                                    {{ sim.saldoCapital | currency:'COP':'symbol':'1.0-0' }}
                                </td>
                                <td>{{ sim.valor | currency:'COP':'symbol':'1.0-0' }}</td>
                                <td>
                                    {{ sim.abonoCapital | currency:'COP':'symbol':'1.0-0' }}
                                </td>
                                <td>{{ sim.intereses | currency:'COP':'symbol':'1.0-0' }}</td>
                                <!-- <td>{{sim.IVA | currency : 'COP': 'symbol': '1.0-0'}}</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>
