import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  // public baseUrl: String = 'https://finsu.herokuapp.com/';
  // public baseURL: String = 'http://localhost:8081';
  public baseURL: String = "https://finsu.imagineapps.co/";

  public menu = new BehaviorSubject('');
  public headers: HttpHeaders;
  public user: any;
  constructor(public http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers.set('Content-Type', 'application/json');
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
  }

  /**
   * Observable para actualizar atributo menu
   * @param {string} menu menu
   */
  setMenu(menu: string) {
    this.menu.next(menu);
  }
}
