import { Component, OnInit, Input } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { AuthService } from '../../../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-detalle-financiaciones',
  templateUrl: './detalle-financiaciones.component.html',
  styleUrls: ['./detalle-financiaciones.component.scss'],
})
export class DetalleFinanciacionesComponent implements OnInit {
  stepWizard = 2;
  @Input() polizaSeleccionada: any;
  pos_cuotas_pagas: number = 0;
  monto_pagado: number = 0;
  transacciones: any;
  ultimoPago: any;
  cuotasPagadas: number;
  totalPagadoFull: number;
  saldo: number;
  total_pagado: number;
  monto_por_pagar: number;

  constructor(
    public authService: AuthService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    console.log(this.polizaSeleccionada);
    this.getTransacciones();

    let pos = 0;
    let pagago = 0;
    let por_pagar = 0;

    for (let item of this.polizaSeleccionada.planDePagos) {
      if (item.pagado === 'si') {
        pos = pos + 1;
        pagago = pagago + item.valor + item.valorMora;
      } else {
        if (por_pagar === 0) {
          por_pagar = item.saldoCapital;
        }
      }
    }
    this.pos_cuotas_pagas = pos - 1;
    this.monto_pagado = pagago;
    this.monto_por_pagar = por_pagar;
  }

  public getTransacciones() {
    this.spinner.show();
    this.authService
      .get({ api: 'api/transaccion/poliza/' + this.polizaSeleccionada._id })
      .subscribe(
        (result) => {
          this.spinner.hide();
          this.transacciones = result;
          this.ultimoPago = this.transacciones[this.transacciones.length - 1];
          let total_pagado = 0;
          for (let item of this.transacciones) {
            if (item.estadoEpayco == 'Aceptada') {
              total_pagado = total_pagado + item.total;
            }
          }
          this.total_pagado = total_pagado;
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  /**
   * Le aumenta 5 días calendario a la fecha
   * @param fecha La fecha a modificar
   * @returns La fecha en formato texto
   */
  getMargenFechaLimite(fecha: Date) {
    return moment(fecha).add(6, 'days').format('MMM DD, YYYY');
  }

  /**
   * Verifica las transacciones realizadas sobre la póliza contra el plan de pagos
   */
  public verificarPagos() {
    let totalPagado = 0;
    let totalPagadoFull = 0;
    this.transacciones.forEach((item) => {
      if (
        item.concepto &&
        item.estadoEpayco == 'Aceptada' &&
        !(item.concepto.indexOf('inversión') > 0) &&
        !(item.concepto.indexOf('inicial') > 0)
      ) {
        totalPagado += item.total;
        totalPagadoFull += item.total;
      }
    });
    //totalPagado = 117472.24882919445; //235454.497658;
    let cuotasPagadas = 0;
    let termino = false;
    this.polizaSeleccionada.planDePagos.forEach((item) => {
      totalPagado = totalPagado - item.valor;
      if (totalPagado >= 0 && !termino) {
        cuotasPagadas++;
        item.pagado = 'si';
        item.estado = 'step';
        if (totalPagado - item.valor < 0) {
          item.estado = 'last';
          termino = true;
        }
      }
    });
    //this.sacarMora();
    this.cuotasPagadas = cuotasPagadas;
    this.totalPagadoFull = totalPagadoFull;
    this.saldo = this.transacciones.valorSeguro - totalPagadoFull;
  }
}
